import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';

export const Box = styled(MuiBox)`
  margin-left: ${(props) => props.theme.spacing(2)};
  gap: ${(props) => props.theme.spacing(1)};
  padding-bottom: ${(props) => props.theme.spacing(1)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
