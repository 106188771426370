import React from 'react';

// Components
import Details from './Details';
import Notes from './Notes';
import Competitors from './Competitors';

// Styles
import { Grid } from './styled';

/**
 * @name AppHeader
 * @description Component displays app Details and Notes in a responsive grid
 */
function AppHeader() {
  return (
    <Grid data-testid="app-header" container alignItems="stretch" spacing={6}>
      <Grid item>
        <Details />
      </Grid>
      <Grid item>
        <Notes />
      </Grid>
      <Grid item>
        <Competitors />
      </Grid>
    </Grid>
  );
}

export default AppHeader;
