import styled from '@emotion/styled';
import MuiInputLabel from '@mui/material/InputLabel';
import MuiTypography from '@mui/material/Typography';

export const InputLabel = styled(MuiInputLabel)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Typography = styled(MuiTypography)`
  font-size: 10px;
`;
