/* eslint-disable max-classes-per-file */

export class ApiGenericError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ApiGenericError';
  }
}

export class ApiConnectionAborted extends ApiGenericError {
  constructor(message) {
    super(message);
    this.name = 'ApiConnectionAborted';
  }
}

export class ApiConnectionRefused extends ApiGenericError {
  constructor(message) {
    super(message);
    this.name = 'ApiConnectionRefused';
  }
}

export class ApiConnectionTimedOut extends ApiGenericError {
  constructor(message) {
    super(message);
    this.name = 'ApiConnectionRefused';
  }
}
export class ApiResponseBadRequest extends ApiGenericError {
  constructor(message, errorData) {
    super(message);
    this.name = 'ApiResponseBadRequest';
    this._errorData = errorData;
  }

  get errorData() {
    return this._errorData;
  }
}

export class ApiResponseUnauthorized extends ApiGenericError {
  constructor(message, errorData) {
    super(message);
    this.name = 'ApiResponseUnauthorized';
    this._errorData = errorData;
  }

  get errorData() {
    return this._errorData;
  }
}

export class ApiResponseForbidden extends ApiGenericError {
  constructor(message, errorData) {
    super(message);
    this.name = 'ApiResponseForbidden';
    this._errorData = errorData;
  }

  get errorData() {
    return this._errorData;
  }
}

export class ApiResponseNotFound extends ApiGenericError {
  constructor(message, errorData) {
    super(message);
    this.name = 'ApiResponseNotFound';
    this._errorData = errorData;
  }

  get errorData() {
    return this._errorData;
  }
}

export class ApiResponseMethodNotAllowed extends ApiGenericError {
  constructor(message) {
    super(message);
    this.name = 'ApiResponseMethodNotAllowed';
  }
}

export class ApiResponseRequestTimeout extends ApiGenericError {
  constructor(message) {
    super(message);
    this.name = 'ApiResponseRequestTimeout';
  }
}

export class ApiResponseInternalServerError extends ApiGenericError {
  constructor(message) {
    super(message);
    this.name = 'ApiResponseInternalServerError';
  }
}

export class ApiInvalidPayload extends ApiGenericError {
  constructor(message, errorData) {
    super(message);
    this.name = 'ApiInvalidPayload';
  }
}
