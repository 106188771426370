import { ACTIONS } from 'constants/OKS';

function setRecommendedKeyword(current, next) {
  if (current?.name === next?.name) return null;
  return next;
}

/**
 * Reducer for the OKS Context
 */
export const OKSReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_FILTER:
      return { ...state, filterByGroup: action.payload };

    case ACTIONS.SET_FILTER_KEYWORD:
      return { ...state, filterKeyword: action.payload };

    case ACTIONS.SET_COMPETITORS:
      return { ...state, competitors: action.payload };

    case ACTIONS.SET_RECOMMENDED_KEYWORD:
      return {
        ...state,
        recommendedKeyword: setRecommendedKeyword(state.recommendedKeyword, action.payload)
      };

    default:
      return state;
  }
};
