import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// Components
import SharingCenter from 'components/SharingCenterWidget/SharingCenter';
import ProjectSettingsForm from './Form';

// Utils
import { capitalizeFirstLetter } from 'utils/utils';

// Hooks
import useProject from 'hooks/useProject';

// Constants
import { URLS } from 'constants/URL';

// Styles
import { Breadcrumbs, Divider } from './styled';

/**
 * @name ProjectSettings
 * @description Displays information regarding the current project as well as the sharing center
 */
function ProjectSettings() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ProjectSettings'
  });
  const { project } = useProject();
  const { name: projectName } = project;

  return (
    <React.Fragment>
      <Helmet title={t('project_settings')} />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={URLS.DASHBOARD}>
              {t('dashboard_link')}
            </Link>
            <Typography>{t('project_settings')}</Typography>
          </Breadcrumbs>
          <Typography variant="h1" gutterBottom>
            {t('project_settings_with_name', { projectName: capitalizeFirstLetter(projectName) })}
          </Typography>
          <Typography variant="subtitle1">{t('setup_your_project')}</Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <ProjectSettingsForm />
        </Grid>
        <Grid item xs={12} md={8}>
          <SharingCenter isComponent noOverflow />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ProjectSettings;
