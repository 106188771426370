import React from 'react';
import { Navigate } from 'react-router-dom';

// Hooks
import useAuth from 'hooks/useAuth';

// Constants
import { URLS } from 'constants/URL';

/**
 * @name LandingGuard
 * @description redirects to sign in page when user is not logged in, and to dashboard when is
 * */
function LandingGuard() {
  const { isAuthenticated, isInitialized } = useAuth();
  // If user is initialized but not authenticated - redirect user to the sign in page.
  if (isInitialized && !isAuthenticated) {
    return <Navigate to={URLS.SIGN_IN} />;
  }
  return <Navigate to={URLS.DASHBOARD} />;
}

export default LandingGuard;
