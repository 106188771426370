import styled from '@emotion/styled';
import MuiGrid from '@mui/material/Grid';
export const Grid = styled(MuiGrid)`
  height: 56px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
`;
