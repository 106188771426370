import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export const RangeInputBox = styled(MuiBox)`
  width: ${(props) => props.width}px;
`;

export const RangeFilterWrapper = styled(MuiBox)`
  display: flex;
  width: 100%;
  gap: ${(props) => props.theme.spacing(0.5)};
`;

export const RangeFilterInput = styled(TextField)`
  width: 100%;
  .MuiInputBase-input {
    padding: 8.5px 8px;
  }
`;
