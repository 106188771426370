import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Components
import OKSMetadata from './Metadata';
import OKSTable from './Table';
import OKSHeader from './Header';

// Context
import { OKSProvider } from 'contexts/OKS';

// Types
import { PLATFORMS } from 'types/Platform';

/**
 * Renders the Optimal Keyword Set Page.
 */
function OKSPage() {
  const { platform } = useParams();
  const { t } = useTranslation('pages', {
    keyPrefix: 'OKSPage'
  });

  if (platform === PLATFORMS.ANDROID) {
    return (
      <Grid display="flex" justifyContent="center">
        <Typography variant="h6">{t('only_for_ios')}</Typography>
      </Grid>
    );
  }

  return (
    <OKSProvider>
      <OKSMetadata />
      <Divider />
      <OKSHeader />
      <OKSTable />
    </OKSProvider>
  );
}

export default OKSPage;
