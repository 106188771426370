import { Item, Title } from 'components/Sidebar/NavListItem/styled';
import React from 'react';

interface Props {
  title: string;
  icon?: React.FC;
  href: string;
}
function ReportBugItem({ title, icon: Icon, href }: Props) {
  return (
    <Item href={href} target="_blank" activeclassname="active" component="a">
      <Icon />
      <Title>{title}</Title>
    </Item>
  );
}

export default ReportBugItem;
