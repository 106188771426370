import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import MuiCardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import MuiBox from '@mui/material/Box';
import MuiCircularProgress from '@mui/material/CircularProgress';

// Components
import TextInput from 'components/InputText';
import Select from 'components/Select';

// Styles for the textarea notes component
const notesStyles = css`
  height: 100%;
  > .MuiBox-root {
    height: 100%;
    > .MuiFormControl-root {
      height: calc(100% - 35px);
      > .MuiInputBase-root {
        padding: 8.5px 8px;
        height: 100%;
        > .MuiInputBase-inputMultiline {
          height: 100% !important;
        }
      }
    }
  }
`;

export const SelectGrid = styled(Grid)`
  & .MuiFormControl-root {
    margin-right: 12px;
  }
`;

export const InputGrid = styled(Grid)`
  height: fit-content;
  display: grid;
  width: fit-content;
  //grid-template-rows: 71px 71px 71px 71px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  grid-template-areas:
    'title subtitle'
    'current current'
    'freeKeywords negatives'
    'notes .';
`;

export const InputText = styled(TextInput)`
  grid-area: ${({ name }) => name};
  min-width: 224px;
  width: 100%;

  & .MuiInputBase-input,
  .MuiFormControl-root {
    width: 100%;
    font-size: 10px;
  }

  & .MuiInputBase-inputMultiline {
    overflow: auto !important;
    padding: 0;
  }

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiInputBase-multiline {
    padding: 8.5px 8px;
  }

  textarea {
    height: 100% !important;
    box-sizing: border-box;
  }
`;

export const CardContent = styled(MuiCardContent)`
  height: 100%;

  &:last-child {
    padding-bottom: 16px;
  }
`;

export const Area = styled(Grid)`
  grid-area: ${({ name }) => name};
  height: fit-content;
`;

export const KeywordArea = styled(Area)`
  max-height: 242px;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 6px;
  margin-top: ${({ theme }) => theme.spacing(8)};
`;

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-left: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Card = styled(Grid)`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding-bottom: ${({ theme }) => theme.spacing(6)};
`;

export const Button = styled(MuiButton)`
  height: 24px;
  font-size: 10px;
`;

export const SelectTextField = styled(Select)`
  & .MuiInputBase-root {
    height: 24px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  }
`;

export const Box = styled(MuiBox)`
  height: 12px;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const CircularProgress = styled(MuiCircularProgress)`
  margin-left: ${({ theme }) => theme.spacing(4)};
`;
