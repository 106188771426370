import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';

// Components
import Loader from 'components/Loader';
import Modal from 'components/Modal';

// Constants
import { URLS } from 'constants/URL';

// Fetchers
import { API, CACHE_KEYS } from 'api';

// Hooks
import useAuth from 'hooks/useAuth';
import useAlert from 'hooks/useAlert';

// Styles
import { TextField, ExpandMoreIcon, ExpandLessIcon } from './styled';

// Utils
import { buildUrl } from 'utils/utils';

// Assets
import SearchIcon from 'assets/svg/search.svg';

/**
 * @name SearchProject
 * @description An input to fetch all the Phiture's projects and add them to the user account.
 * This is a feature which will be made only available to the users with
 * `@phiture.com` email
 */
function SearchProject() {
  const { t } = useTranslation('components', {
    keyPrefix: 'Menu.SearchProject'
  });
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [projectAccess, setProjectAccess] = useState(null);
  const { updateProjectsList } = useAuth();
  const { setAlert } = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const isLoading = isOpen && options.length === 0;
  const { projects } = user;
  const { owner_projects: ownerProjects, share_projects: sharedProjects } = projects;
  const allProjects = [...ownerProjects, ...sharedProjects];
  const allProjectsIndexes = allProjects.map((project) => project.project_id);
  const [shrink, setShrink] = useState(false);

  useQuery([CACHE_KEYS.PROJECTS_ACCESS], () => API.getProjectsAccess(), {
    enabled: isLoading,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (responseData) => {
      const { data } = responseData;
      setOptions([...data]);
    }
  });

  /**
   * handles adding user to the project through the API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const {
    isLoading: isLoadingJoinProject,
    isSuccess,
    mutateAsync: joinProjectsAccessMutateAsync
  } = useMutation(
    ({ id, name }) => {
      return API.joinProjectsAccess({ projectId: id });
    },
    {
      onSuccess: async (responseData, mutationData) => {
        const { name: projectName } = mutationData;
        const { data } = responseData;
        const newProject = data.user_project_share;
        const { project_id: projectId } = newProject;
        updateProjectsList(projectId, projectName, true);
        setAlert(t('project_added_successfully'));
        setTimeout(() => {
          navigate(buildUrl(URLS.PROJECT, { projectId }));
        }, 2000);
      },
      onError: (error) => {
        setAlert(t('error_when_adding_user_to_the_project'));
      }
    }
  );

  const handleProjectChange = async (value) => {
    if (value === null) {
      return;
    }

    setProjectAccess(value);
    setIsOpenModal(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  const handleExit = () => {
    setProjectAccess(null);
  };

  const handleJoinProject = async () => {
    await joinProjectsAccessMutateAsync(projectAccess);
  };

  const id = projectAccess?.id;
  const name = projectAccess?.name;

  return (
    <React.Fragment>
      <Autocomplete
        id="searchProjects"
        sx={{ width: '100%', paddingLeft: '32px', paddingRight: '28px' }}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        value={projectAccess}
        getOptionDisabled={(value) => allProjectsIndexes.indexOf(value.id.toString()) > 0}
        onChange={(_e, value) => handleProjectChange(value)}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={() => setShrink(true)}
            onBlur={(e) => {
              !e.target.value && setShrink(false);
            }}
            label={t('search_projects')}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <Loader size={20} /> : null}
                  {shrink ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </React.Fragment>
              ),
              startAdornment: (
                <SearchIcon
                  height={13}
                  width={13}
                  style={{ marginLeft: '16px', marginBottom: '2px', color: 'white' }}
                />
              )
            }}
            InputLabelProps={{
              shrink
            }}
          />
        )}
      />
      <Modal
        isLoading={isLoadingJoinProject}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        handleExit={handleExit}
        actionButton={
          <LoadingButton
            loading={isLoadingJoinProject}
            role="button"
            disabled={isLoadingJoinProject || isSuccess}
            variant="contained"
            color="primary"
            onClick={() => handleJoinProject()}
            autoFocus
          >
            {t('add_project')}
          </LoadingButton>
        }
        body={
          <Trans i18nKey="add_project_modal_body">
            You will add the project <b>{{ name }}</b> with ID <b>{{ id }}</b>
          </Trans>
        }
        title={t('are_you_sure')}
      />
    </React.Fragment>
  );
}

export default SearchProject;
