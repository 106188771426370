import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// Components
import BrandIcon from 'components/BrandIcon';
import SignInForm from './Form';
import SignInSuccess from 'components/SignInSuccess';

// Constants
import { STATUS } from 'constants/Auth';

// Hooks
import useAuth from 'hooks/useAuth';

// Fetchers
import { ERROR } from 'api/index';

// Styles
import {
  Wrapper,
  Typography,
  WrapperInner,
  Title,
  Subtitle,
  TitleBrandContainer,
  SignInContainer,
  TextContent
} from './styled';

/**
 * @name Landing
 * @description Static sign-in/landing page for Toaster, when user is not sign-in
 * */

function Landing() {
  const { t } = useTranslation();

  const [signInStatus, setSignInStatus] = useState(STATUS.IDLE);
  const { signIn } = useAuth();
  const handleOnSubmit = async (values, { errors, setErrors, setStatus, setSubmitting }) => {
    try {
      await signIn('LOCAL', {
        username: values.username,
        password: values.password,
        remember: values.remember
      });
      setSignInStatus(STATUS.LOCAL_SIGN_IN);
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (
        error instanceof ERROR.ApiResponseBadRequest ||
        error instanceof ERROR.ApiResponseUnauthorized
      ) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('pages:SignIn.something_went_wrong');
        tempErrors = { submit: [message] };
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          {/*app title and description*/}
          <Grid item xs={12} md={8}>
            <TextContent>
              <TitleBrandContainer>
                <Title variant="h1" gutterBottom>
                  {t('common:toaster')}
                </Title>
                <BrandIcon />
              </TitleBrandContainer>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">{t('common:toaster_subtitle')}</Subtitle>
                </Grid>
              </Grid>
            </TextContent>
          </Grid>
          {/*sign-in form*/}
          <Grid item xs={12} md={4}>
            <SignInContainer>
              <BrandIcon isPhiture />
              <WrapperInner>
                <Helmet title={t('pages:SignIn.sign_in')} />
                {(signInStatus === STATUS.LOCAL_SIGN_IN ||
                  signInStatus === STATUS.GOOGLE_SIGN_IN) && <SignInSuccess />}
                {signInStatus === STATUS.IDLE && (
                  <React.Fragment>
                    <Typography component="h1" variant="h4" align="center" gutterBottom>
                      {t('pages:SignIn.sign_in')}
                    </Typography>
                    <Typography component="h2" variant="body1" align="center">
                      {t('pages:SignIn.sign_in_to_continue')}
                    </Typography>
                    <SignInForm setSignInStatus={setSignInStatus} handleOnSubmit={handleOnSubmit} />
                  </React.Fragment>
                )}
              </WrapperInner>
            </SignInContainer>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Landing;
