import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

// Constants
import { ACTIONS } from 'constants/App';

// Reducers
import { AppReducer } from './reducer';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';

// Utils
import { isEmpty } from 'utils/utils';

export const initialState = {
  app: {},
  noteStatus: FETCH_STATE.IDLE,
  translateKeywords: true,
  allTrackedLocales: {}
};

const AppContext = createContext(initialState);

export const getLocales = (markets) =>
  markets.reduce((acc, { id, locale, country_code }) => {
    return {
      ...acc,
      [country_code]: [...(acc[country_code] || []), { value: id, label: locale }]
    };
  }, {});

/**
 * @name AppProvider
 * @description Context for app.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 * @param  {Object} props.mockState Custom state value. Useful for tests and storybook. Defaults to `null`.
 */
function AppProvider({ mockState = null, children }) {
  // If mockState was passed, set it as a initial state. Otherwise set initial state to empty object.
  const isMockState = !isEmpty(mockState);
  const [state, dispatch] = useReducer(AppReducer, isMockState ? mockState : initialState);

  // Check for projectId, appId and platform in the params
  const { projectId, appId, platform } = useParams();

  const { status: queryStatus, isFetching } = useQuery(
    [CACHE_KEYS.APPS, projectId, appId, platform],
    () => API.getProjectApp({ projectId, appId, platform }),
    {
      enabled: !isMockState,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (responseData) => {
        // create object with country as key and array of locales as value
        const otherLocales = getLocales(responseData.data.other_app_market);

        dispatch({
          type: ACTIONS.INITIALIZE,
          payload: {
            app: responseData.data,
            allTrackedLocales: otherLocales
          }
        });
      }
    }
  );

  // If the queryStatus is `idle`, consider it as a `success`
  // If state is mocked, use provided status.
  let status;
  if (isMockState) {
    status = state.status;
  } else {
    status = queryStatus === FETCH_STATE.IDLE ? FETCH_STATE.SUCCESS : queryStatus;
  }

  /**
   * handles the note creation through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const appCreateNoteMutation = useMutation(
    (mutationData) => {
      return API.createProjectAppNote(mutationData);
    },
    {
      onMutate: () => {
        dispatch({
          type: ACTIONS.SET_NOTE_LOADING
        });
      },
      onSuccess: async (responseData, mutationData) => {
        dispatch({
          type: ACTIONS.SET_NOTE_SUCCESS,
          payload: {
            updatedNote: {
              id: '15',
              note: mutationData.note
            }
          }
        });
      },
      onError: () => {
        dispatch({
          type: ACTIONS.SET_NOTE_ERROR
        });
      }
    }
  );

  /**
   * handles the note update through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const appUpdateNoteMutation = useMutation(
    (mutationData) => {
      return API.updateProjectAppNote(mutationData);
    },
    {
      onMutate: () => {
        dispatch({
          type: ACTIONS.SET_NOTE_LOADING
        });
      },
      onSuccess: async (responseData, mutationData) => {
        dispatch({
          type: ACTIONS.SET_NOTE_SUCCESS,
          payload: {
            updatedNote: {
              id: mutationData.noteId,
              note: mutationData.note
            }
          }
        });
      },
      onError: () => {
        dispatch({
          type: ACTIONS.SET_NOTE_ERROR
        });
      }
    }
  );

  /**
   * @name setNote
   * @description Handle setting app note for specific app within specific project. Check if App has
   * any note - if yes, then update the note. Otherwise create a new note. This also uses optimistic
   * updates instead of waiting for the POST / PUT method to finish.
   * @param  {{id: string, note: string}} note
   */
  const setNote = (updatedNote) => {
    let { id: noteId, note } = updatedNote;
    const foundNote = state.app.notes.find((el) => el.id === noteId);

    if (foundNote) {
      appUpdateNoteMutation.mutate({
        noteId,
        note,
        appId,
        projectId,
        platform
      });
    } else {
      appCreateNoteMutation.mutate({
        note,
        appId,
        projectId,
        platform
      });
    }
  };

  /**
   * @name resetNoteStatus
   * @description Set noteStatus to its default state. This removes displayed errors.
   */
  const resetNoteStatus = () => {
    dispatch({
      type: ACTIONS.SET_NOTE_RESET
    });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        status,
        setNote,
        resetNoteStatus,
        dispatch,
        isFetching
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { AppContext, AppProvider };
