import styled from '@emotion/styled';
import MuiSkeleton from '@mui/material/Skeleton';

export const StyledSkeleton = styled(MuiSkeleton)`
  margin-bottom: ${(props) => props.theme.spacing(props.mb || 0)};
  width: ${(props) => (typeof props.width === 'string' ? props.width : `${props.width}px`)};
  height: ${(props) => (typeof props.height === 'string' ? props.height : `${props.height}px`)};
  max-width: ${(props) =>
    typeof props['max-width'] === 'string' ? props['max-width'] : `${props['max-width']}px`};
  border-radius: ${(props) => `${props['border-radius'] * 4}px`};

  .SrOnly {
    display: none;
  }
`;
