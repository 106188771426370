import { FILTER_TYPES, keywordTrackingTableColumns, SELECT_FILTER_OPTIONS } from './columns';
import * as Yup from 'yup';
import i18n from 'i18next';
import { unionBy } from 'lodash-es';
// Create object with filterTypes and prop mane for each column
export const KEYWORD_TRACKING_TABLE_COLUMNS_FILTER_TYPES = keywordTrackingTableColumns()
  .map((column) => ({ name: column.field, filterType: column.filterType }))
  .reduce(function (result, item) {
    // if no filter type is provided it means that column is not filterable
    if (!item.filterType) {
      return result;
    }
    // range filter type comes with min and max, we need to add those to the prop name
    // as our key is coming from the queryFilters object
    if (item.filterType === FILTER_TYPES.RANGE) {
      result[`${item.name}_min`] = { filterType: item.filterType, propName: item.name };
      result[`${item.name}_max`] = { filterType: item.filterType, propName: item.name };
      return result;
    }
    // similar for date ranges
    if (item.filterType === FILTER_TYPES.DATE_RANGE) {
      result[`${item.name}_start`] = { filterType: item.filterType, propName: item.name };
      result[`${item.name}_end`] = { filterType: item.filterType, propName: item.name };
      return result;
    }
    result[item.name] = { filterType: item.filterType, propName: item.name };
    return result;
  }, {});

// Set object of visible columns
export const KEYWORD_TRACKING_TABLE_VISIBLE_COLUMNS = keywordTrackingTableColumns()
  .map((column) => ({ name: column.field, visible: true }))
  .reduce(function (result, item) {
    result[item.name] = item.visible;
    return result;
  }, {});

// Create a filter schema based on the provided columns
const mappedColumnsSchema = keywordTrackingTableColumns()
  .map((column) => {
    if (column.filterType === FILTER_TYPES.RANGE) {
      return [
        {
          name: `${column.field}_min`,
          schema: Yup.number()
            .min(
              0,
              i18n.t('components:KeywordTrackingTable.config.min_range_min_validation', {
                headerName: column.headerName.toLowerCase()
              })
            )
            .nullable()
        },
        {
          name: `${column.field}_max`,
          schema: Yup.number()
            .nullable()
            .when(`${column.field}_min`, (data, schema, options) => {
              if (options.value !== 0) {
                return schema.min(
                  data,
                  i18n.t('components:KeywordTrackingTable.config.max_range_max_validation', {
                    headerName: column.headerName.toLowerCase()
                  })
                );
              }
            })
        }
      ];
    }
    if (column.filterType === FILTER_TYPES.SELECT) {
      return {
        name: column.field,
        schema: Yup.string()
          //If there are no `options` for current column filter, default to empty array.
          .oneOf(SELECT_FILTER_OPTIONS[column.field] || '')
          .nullable()
      };
    }
    if (column.filterType === FILTER_TYPES.MULTISELECT) {
      return {
        name: column.field,
        //If there are no `options` for current column filter, default to empty array.
        schema: Yup.array(Yup.string().oneOf(SELECT_FILTER_OPTIONS[column.field])).nullable()
      };
    }
    if (column.filterType === FILTER_TYPES.TEXT) {
      return {
        name: column.field,
        schema: Yup.string().nullable()
      };
    }
    return null;
  })
  .filter((el) => el !== null)
  .flat();

// Push `date_range` schemas
mappedColumnsSchema.push([
  {
    name: 'date_range_start',
    schema: Yup.date()
  },
  {
    name: 'date_range_end',
    schema: Yup.date()
  }
]);

const mappedColumns = keywordTrackingTableColumns()
  .map((column) => {
    if (column.filterType === FILTER_TYPES.RANGE) {
      return [
        {
          name: `${column.field}_min`,
          value: '0'
        },
        {
          name: `${column.field}_max`,
          value: '0'
        }
      ];
    }
    if (column.filterType === FILTER_TYPES.SELECT) {
      return {
        name: column.field,
        value: ''
      };
    }
    if (column.filterType === FILTER_TYPES.MULTISELECT) {
      return {
        name: column.field,
        value: []
      };
    }
    if (column.filterType === FILTER_TYPES.TEXT) {
      return {
        name: column.field,
        value: ''
      };
    }
    return null;
  })
  .filter((el) => el !== null)
  .flat();

// TODO add date range filter when needed
/*mappedColumns.push(
  {
    name: 'date_range_start',
    value: null
  },
  {
    name: 'date_range_end',
    value: null
  }
);*/

export const KEYWORD_TRACKING_TABLE_FILTERS = mappedColumns.reduce(function (result, item) {
  result[item.name] = item.value;
  return result;
}, {});

export const KEYWORD_TRACKING_TABLE_FILTERS_SCHEMA = Yup.object().shape(
  mappedColumnsSchema.reduce(function (result, item) {
    result[item.name] = item.schema;
    return result;
  }, {})
);

// create a function to update the keywords array in the state when new props are fetched
export const updateKeywords = (state, payload) => {
  let updatedKeywords;
  if (state.keywords.length === 0) {
    // if state.keywords is empty, just use the keywords from the payload
    updatedKeywords = payload.keywords;
  } else {
    // otherwise, merge the keywords from the payload into the existing keywords array by id
    // (we need to do this because the state.keywords won't have the newly added keywords)
    const allKeywords = unionBy(state.keywords, payload.keywords, 'keyword_id');
    // then merge the missing fields from the payload into the existing keywords array
    updatedKeywords = allKeywords.map((keyword) => {
      const matchingKeyword = payload.keywords.find(
        (item) => item.keyword_id === keyword.keyword_id
      );
      if (matchingKeyword) {
        return {
          ...keyword,
          ...matchingKeyword
        };
      } else {
        return keyword;
      }
    });
  }

  return {
    ...state,
    keywords: updatedKeywords
  };
};
