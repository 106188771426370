import { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import { Grid, Keyword, Wrapper, Typography, Container, KPI, Translation } from './styled';

// Assets
import WarningIcon from 'assets/warning.svg';

/**
 * Renders a keyword, keyword ranking data and translation of the keyword
 *
 * @param id                      Keyword id
 * @param onClick                 Click handler
 * @param isActive                Active state
 * @param name                    Keyword name
 * @param popularityScore         Keyword popularity score
 * @param rank                    Keyword ranking
 * @param competitorRelevancy     Keyword competitor relevancy
 * @param competitorCount         Count of competitors
 * @param translation             Keyword translation
 * @param showTranslation         Whether to show the translation
 *
 */
export function RecommendedKeyword({
  id,
  onClick,
  isActive,
  name,
  popularityScore,
  rank,
  competitorRelevancy,
  competitorCount,
  translation,
  showTranslation,
  className
}) {
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowDetails(!showDetails);
  };

  return (
    <Grid container direction="column" onClick={onClick} className={className}>
      <Keyword
        aria-label="keyword"
        isActive={isActive}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Wrapper>
          <Typography>{name}</Typography>
        </Wrapper>
        <Container onClick={handleClick}>
          {showDetails ? (
            <>
              <KPI>SP {popularityScore}</KPI>
              <KPI># {rank}</KPI>
              <KPI>
                C {competitorRelevancy}/{competitorCount}
              </KPI>
            </>
          ) : (
            <WarningIcon
              style={{ marginLeft: '8px' }}
              height={10}
              width={10}
              onClick={handleClick}
            />
          )}
        </Container>
      </Keyword>
      <Translation
        aria-label="translation"
        isActive={isActive}
        isMissing={!showTranslation || !translation}
        container
        alignItems="center"
      >
        {showTranslation && <Typography>{translation}</Typography>}
      </Translation>
    </Grid>
  );
}

RecommendedKeyword.propTypes = {
  id: PropTypes.number,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  name: PropTypes.string,
  popularityScore: PropTypes.number,
  rank: PropTypes.number,
  competitorRelevancy: PropTypes.number,
  competitorCount: PropTypes.number,
  translation: PropTypes.string,
  showTranslation: PropTypes.bool
};

export default RecommendedKeyword;
