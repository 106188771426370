import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

// Utils
import StorageService from 'utils/storage';
import { isEmpty } from 'utils/utils';

// Components
import GlobalLoader from 'components/GlobalLoader';

// Constants
import { ACTIONS } from 'constants/Project';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';

const initialState = {
  project: {}
};

const ProjectReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INITIALIZE:
      return {
        project: action.payload.project
      };

    case ACTIONS.ADD_SHARE_USER:
      return {
        project: {
          ...state.project,
          project_users: [...state.project.project_users, action.payload.sharedUser]
        }
      };

    case ACTIONS.UPDATE_PROJECT:
      return {
        project: {
          ...state.project,
          name: action.payload.projectName,
          owner_email: action.payload.projectOwner,
          reporting: action.payload.projectReporting
        }
      };

    case ACTIONS.UPDATE_SHARE_USER: {
      const { payload } = action;
      const user = state.project.project_users.find((u) => u.user_id === payload.userId);

      // If user exits, update the `userRoleId`. Else do nothing.
      if (user) {
        return {
          ...state,
          project: {
            ...state.project,
            project_users: state.project.project_users.map((u) =>
              u.user_id === payload.userId ? { ...u, user_role_id: payload.userRoleId } : u
            )
          }
        };
      }

      return {
        ...state
      };
    }

    case ACTIONS.REMOVE_SHARE_USER: {
      const { payload } = action;
      const user = state.project.project_users.find((u) => u.user_id === payload.userId);

      // If user exits, remove. Else do nothing.
      if (user) {
        return {
          ...state,
          project: {
            ...state.project,
            project_users: state.project.project_users.filter((u) => u.user_id !== payload.userId)
          }
        };
      }

      return {
        ...state
      };
    }

    case ACTIONS.ERROR: {
      return {
        project: {}
      };
    }

    default:
      return state;
  }
};

// Initial context state
const initialProjectContextState = {
  ...initialState
};

const ProjectContext = createContext(initialProjectContextState);

/**
 * @name ProjectProvider
 * @description Context for project.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 * @param  {Object} props.mockState Custom state value. Useful for tests and storybook. Defaults to `null`.
 */
function ProjectProvider({ mockState = null, children }) {
  // If mockState was passed, set it as a initial state. Otherwise set initial state to empty object.
  const isMockState = !isEmpty(mockState);
  const [state, dispatch] = useReducer(ProjectReducer, isMockState ? mockState : initialState);
  // Check for projectId in the params
  const { projectId } = useParams();

  // Get project data. This query reruns when the projectId in the URL changes and
  // is not stalled
  const { status: queryStatus } = useQuery(
    [CACHE_KEYS.PROJECTS, projectId],
    () => API.getProject({ projectId }),
    {
      enabled: !isMockState,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: (responseData) => {
        const { data: project } = responseData;
        const { id: projectId } = project;
        // Set project to localStorage
        StorageService.setLocalStorageLastProjectId(projectId.toString());
        dispatch({
          type: ACTIONS.INITIALIZE,
          payload: {
            project
          }
        });
      }
    }
  );

  // If the queryStatus is `idle`, consider it as a `success`
  // If state is mocked, use provided status.
  let status;
  if (isMockState) {
    status = state.status;
  } else {
    status = queryStatus === FETCH_STATE.IDLE ? FETCH_STATE.SUCCESS : queryStatus;
  }

  /**
   * @name addShareUser
   * @description If the project sharing was successful, this function will run and add the user
   * to the local array of project's shared users.
   * @param  {String} userId
   * @param  {String} userEmail
   * @param  {String} userRoleId
   */
  const addShareUser = (userId, userEmail, userRoleId) => {
    dispatch({
      type: ACTIONS.ADD_SHARE_USER,
      payload: {
        sharedUser: { user_id: userId, user_email: userEmail, user_role_id: userRoleId }
      }
    });
  };

  /**
   * @name updateShareUser
   * @description If the project's shared users update was successful, this function will run and update the user
   * in the local array of project's shared users.
   * @param  {String} userId
   * @param  {String} userRoleId
   */
  const updateShareUser = (userId, userRoleId) => {
    dispatch({
      type: ACTIONS.UPDATE_SHARE_USER,
      payload: {
        userId,
        userRoleId
      }
    });
  };

  /**
   * @name updateShareUser
   * @description If the project's shared users removal was successful, this function will run and remove the user
   * in the local array of project's shared users.
   * @param  {String} userId
   */
  const removeShareUser = (userId) => {
    dispatch({
      type: ACTIONS.REMOVE_SHARE_USER,
      payload: {
        userId
      }
    });
  };

  /**
   * @name updateProject
   * @description If the project update was successful, this function will run and update
   * the project locally
   * @param  {String} projectName
   * @param  {String} projectOwner
   */
  const updateProject = (projectName, projectOwner, projectReporting) => {
    dispatch({
      type: ACTIONS.UPDATE_PROJECT,
      payload: {
        projectName: projectName || state.project.name,
        projectOwner: projectOwner || state.project.owner_email,
        projectReporting: projectReporting || state.project.reporting
      }
    });
  };

  return (
    <ProjectContext.Provider
      value={{
        ...state,
        status,
        addShareUser,
        updateShareUser,
        removeShareUser,
        updateProject
      }}
    >
      {/* If user is being authenticated during app loading, display Loader */}
      {status === FETCH_STATE.LOADING ? (
        <GlobalLoader />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </ProjectContext.Provider>
  );
}

ProjectProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { ProjectContext, ProjectProvider };
