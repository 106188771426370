import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import Chip from '@mui/material/Chip';

// Constants
import { DATE_STRING_FORMAT } from 'contexts/KeywordTrackingTable';

// Styles
import { Box } from './styled';
// `RANGE` filter type elements end with `_max` or `_min`, so this regex
// is looking for one of those two strings.
const RANGE_ELEMENT_REGEX = /_min|_max/g;
const DATE_RANGE_ELEMENT_REGEX = /_start|_end/g;

/**
 * @name prepareChips
 * @description Prepare chips array.
 * @param  {Object} filters
 * @param  {Object[]} columns
 * @returns  {{chipName: String, value: String}[]} prepared chips array
 */
const prepareChips = (filters, columns) => {
  const notEmptyFilters = Object.keys(filters).filter((key) => filters[key]);
  const notNullishFilters = notEmptyFilters
    .filter((key) => filters[key] !== '0')
    .filter((key) => filters[key].length !== 0);
  const pairedFilters = notNullishFilters.map((key) => [key, filters[key]]);
  const preparedChips = pairedFilters.map((el) => {
    const filterName = el[0];
    if (filterName === 'page') {
      return null;
    }
    let chipName;
    let value = el[1];
    // Check if `filterName` is a `RANGE` filter type, based on the fact
    // that `RANGE` filter type elements end with `_max` or `_min`.
    const isRangeFilter = filterName.match(RANGE_ELEMENT_REGEX);
    const isDateRangeFilter = filterName.match(DATE_RANGE_ELEMENT_REGEX);
    if (isRangeFilter) {
      // Remove the `_max` or `_min` from the `filterName`.
      const filterNameFormatted = filterName.replace(isRangeFilter[0], '');
      // Find the correct `headerName` for the `filterNameFormatted`
      const columnHeaderName = columns.find(
        (column) => column.field === filterNameFormatted
      )?.headerName;

      // If columnHeaderName is not found, return null
      // Otherwise the filterChips will crash.
      if (!columnHeaderName) {
        return null;
      }

      // Update chip name to display values correctly.
      chipName =
        (isRangeFilter[0] === '_min'
          ? i18n.t('components:KeywordTrackingTable.FilterChips.min')
          : i18n.t('components:KeywordTrackingTable.FilterChips.max')) + ` ${columnHeaderName}`;
    } else if (isDateRangeFilter) {
      // Remove the `_start` or `_end` from the `filterName`.
      const filterNameFormatted = filterName.replace(isDateRangeFilter[0], '');
      // Find the correct `headerName` for the `filterNameFormatted`
      const columnHeaderName = columns.find(
        (column) => column.field === filterNameFormatted
      )?.headerName;

      // If columnHeaderName is not found, return null
      // Otherwise the filterChips will crash.
      if (!columnHeaderName) {
        return null;
      }

      // Update chip name to display values correctly.
      chipName =
        (isDateRangeFilter[0] === '_start'
          ? i18n.t('components:KeywordTrackingTable.FilterChips.start')
          : i18n.t('components:KeywordTrackingTable.FilterChips.end')) +
        ` ${columnHeaderName.toLowerCase()}`;

      // We need to format the date to something readable.
      value = format(el[1], DATE_STRING_FORMAT);
    } else {
      // For rest of the filter types we don't need to make this too complicated
      const columnHeaderName = columns.find((column) => column.field === el[0])?.headerName;

      // If columnHeaderName is not found, return null
      // Otherwise the filterChips will crash.
      if (!columnHeaderName) {
        return null;
      }

      chipName = columnHeaderName;
    }
    return { chipName, value };
  });

  return preparedChips;
};

/**
 * @name FilterChips
 * @description Display chips for every submitted filter
 * @param  {Object} props
 * @param  {Object} props.filters Submitted filters
 * @param  {Object[]} props.columns Current used columns
 * @param  {String} props.columns.field We are using this to find correct `headerName`
 * @param  {String} props.columns.headerName We are using this to display `chipName` correctly.
 */
function FilterChips({ filters, columns }) {
  const chips = prepareChips(filters, columns);
  // Return early if no filters currently active.
  if (!filters) {
    return null;
  }

  return (
    <Box>
      {chips.map((chip) => {
        if (chip === null) {
          return null;
        }
        if (Array.isArray(chip.value)) {
          return (
            // If chip value is an array, e.g. chip value is a value of `multiselect` filter or `range` filter
            // then we need to map this array to display individual values.
            // It is not necessary, but it improves a visual experience.
            <React.Fragment key={`${chip.chipName}`}>
              {chip.value.map((chipValue) => {
                return (
                  <Chip
                    key={`${chip.chipName}_${chipValue}`}
                    label={`${chip.chipName}: ${chipValue}`}
                  />
                );
              })}
            </React.Fragment>
          );
        }
        return (
          <Chip key={`${chip.chipName}_${chip.value}`} label={`${chip.chipName}: ${chip.value}`} />
        );
      })}
    </Box>
  );
}

FilterChips.propTypes = {
  filters: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired
    })
  ).isRequired
};

export default FilterChips;
