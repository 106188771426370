/**
 * This hook re-implement the now removed usePrompt hook in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 *
 * TODO: After react-router will re-implement usePrompt, switch this hook for the default one.
 */
import { useCallback } from 'react';

// Hooks
import useBlocker from 'hooks/useBlocker';

/**
 * @name usePrompt
 * @description Prompts the user with an Alert before they leave the current screen.
 * @param  {String} message Message to display when trying to leave the current screen.
 * @param  {Boolean} when
 */
const usePrompt = (message, when = true) => {
  const blocker = useCallback(
    (tx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) tx.retry();
    },
    [message]
  );

  useBlocker(blocker, when);
};

export default usePrompt;
