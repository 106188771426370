import PropTypes from 'prop-types';

// Components
import RecommendedKeyword from './RecommendedKeyword';

// Hooks
import useOKSTable from 'hooks/useOKSTable';
import useApp from 'hooks/useApp';

// Constants
import { ACTIONS, FILTER_OPTIONS } from 'constants/OKS';

/**
 * Renders the list of recommended keywords and handles active state
 *
 * @param keywords   List of keywords
 */
function RecommendedKeywords({ keywords }) {
  const {
    state: { recommendedKeyword },
    dispatch
  } = useOKSTable();

  const { translateKeywords } = useApp();
  const handleActive = (keyword) => {
    dispatch({ type: ACTIONS.SET_RECOMMENDED_KEYWORD, payload: keyword });
    dispatch({ type: ACTIONS.SET_FILTER, payload: FILTER_OPTIONS.ALL });
  };

  return (
    <>
      {keywords.map((keyword) => (
        <RecommendedKeyword
          showTranslation={translateKeywords}
          name={keyword.name}
          onClick={() => handleActive(keyword)}
          isActive={keyword.name === recommendedKeyword?.name}
          id={keyword.id}
          translation={keyword.en_translation}
          popularityScore={Number(keyword.search_popularity)}
          competitorRelevancy={keyword.relevancy_competitor}
          competitorCount={keyword.competitors_count}
          rank={keyword.rank}
          key={keyword.id || keyword.name}
        />
      ))}
    </>
  );
}

RecommendedKeywords.propTypes = {
  keywords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      onClick: PropTypes.func,
      isActive: PropTypes.bool,
      name: PropTypes.string,
      popularityScore: PropTypes.number,
      rank: PropTypes.number,
      competitorRelevancy: PropTypes.number,
      competitorCount: PropTypes.number,
      translation: PropTypes.string
    })
  )
};
export default RecommendedKeywords;
