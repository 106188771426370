import { useContext } from 'react';

// Contexts
import { AlertContext } from 'contexts/Alert';

const useAlert = () => {
  const context = useContext(AlertContext);

  if (!context) throw new Error('Alert must be placed within AlertProvider');

  return context;
};

export default useAlert;
