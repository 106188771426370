import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';

// Styles
import { IconButton } from './styled';

/**
 *
 * @param className     Styles
 * @param disabled      Is button disabled
 * @param handleClick   CLick handler for the button
 */
function AddButton({ className, disabled, handleClick }) {
  return (
    <IconButton onClick={handleClick} className={className} disabled={disabled}>
      <AddIcon />
    </IconButton>
  );
}

export default AddButton;

AddButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired
};
