import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
/**
 * @name TooltipWithIcon
 * @description custom tooltip with question mark icon as child
 * @param {string} title
 * */
function TooltipWithIcon({ title }) {
  return (
    <Tooltip title={title} arrow>
      <HelpOutlineIcon color="secondary" cursor="pointer" />
    </Tooltip>
  );
}

export default TooltipWithIcon;

TooltipWithIcon.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};
