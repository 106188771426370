import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components
import GlobalStyle from 'components/GlobalStyle';
import Navbar from 'components/Navbar';
import { appItems } from 'components/Sidebar/Navigation/sidebarUtilsItems';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import Settings from 'components/Settings';

// Styles
import { Root, MainContent, AppContent, DrawerContainer, drawerWidth } from './styled';

/**
 * @name Layout
 * @description Main layout for Toaster app pages
 *
 * @param  {React.ReactNode} props.children
 * @param  {Boolean} props.hideSidebar
 */
const Layout = ({ children, hideSidebar = false }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {!hideSidebar && (
        <DrawerContainer>
          {/* mobile */}
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth, overflow: 'visible' } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={appItems}
            />
          </Box>
          {/* desktop */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth, overflow: 'visible' } }}
              items={appItems}
            />
          </Box>
        </DrawerContainer>
      )}
      <AppContent>
        <MainContent p={6}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      {/* show choose theme icon only on dev mode */}
      {process.env.APP_ENV === 'DEVELOPMENT' && <Settings />}
    </Root>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  hideSidebar: PropTypes.bool
};

export default Layout;
