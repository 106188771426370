import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Constants
import { URLS } from 'constants/URL';
import { PHITURE_GROUP } from 'constants/constants';

// Components
import ProjectsGroup from './ProjectsGroup';
import SearchProject from './SearchProject';
import { ListItem } from 'components/Sidebar/NavListItem';
import SharingCenterItem from 'components/Sidebar/Items/SharingCenter';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import { Divider, MenuWrapper } from './styled';

// Assets
import Sharing from 'assets/svg/sharing_centre.svg';
import Plus from 'assets/svg/add.svg';

/**
 * @name Menu
 * @description Global menu for the entire application. This menu provides
 * easy access to the user projects list, user settings, sign out button and
 * more
 */
function Menu() {
  const { t } = useTranslation();
  const { user } = useAuth();

  const { projectId: currentSelectedProjectId } = useParams();
  const { projects } = user;
  const hasOwnerProjects = projects?.owner_projects.length > 0;
  const hasSharedProjects = projects?.share_projects.length > 0;

  return (
    <MenuWrapper>
      {user.group === PHITURE_GROUP && <SearchProject />}
      <SharingCenterItem icon={Sharing} title={t('common:open_sharing_center')} />
      <ListItem href={URLS.PROJECT_CREATE} title={t('common:create_new_project')} icon={Plus} />
      <Divider />
      {hasOwnerProjects && (
        <ProjectsGroup
          title={t('components:Menu.owner_projects')}
          badge={projects.owner_projects.length || null}
          open={true}
          projects={projects.owner_projects}
          currentSelectedProjectId={currentSelectedProjectId}
        />
      )}
      {hasSharedProjects && (
        <ProjectsGroup
          title={t('components:Menu.share_projects')}
          badge={projects.share_projects.length || null}
          open={true}
          projects={projects.share_projects}
          currentSelectedProjectId={currentSelectedProjectId}
        />
      )}
    </MenuWrapper>
  );
}

export default Menu;
