import { createContext, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

// Utils
import { isEmpty } from 'utils/utils';
import StorageService from 'utils/storage';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api';

export const initialProjectAppsState = {
  projectApps: []
};
const ProjectAppsContext = createContext(initialProjectAppsState);

/**
 * @name ProjectAppsProvider
 * @description Context for project apps. Fetches the apps of a project and makes them available
 * for the whole application
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 * @param  {Object} props.mockState Custom state value. Useful for tests and storybook. Defaults to `null`.
 */

function ProjectAppsProvider({ mockState = null, children }) {
  // If mockState was passed, set it as a initial state. Otherwise set initial state to empty object.
  const isMockState = !isEmpty(mockState);
  // TRACKED APPS (fetched only if there is a project_id ie the user has at least one project)
  const [trackedAppsStatus, setTrackedAppsStatus] = useState(FETCH_STATE.LOADING);
  const projectId = StorageService.getLocalStorageLastProjectId();

  const { data: trackedApps, refetch: getTrackedApps } = useQuery(
    [CACHE_KEYS.APPS],
    () => API.listProjectApps({ projectId }),
    {
      enabled: projectId !== undefined && !isMockState,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      onSuccess: (responseData) => {
        if (responseData.data.length === 0) {
          setTrackedAppsStatus(FETCH_STATE.EMPTY);
        }
      },
      onError: (error) => {
        console.log('error', error);
        setTrackedAppsStatus(FETCH_STATE.ERROR);
      }
    }
  );

  const projectAppsProviderValue = {
    trackedApps,
    getTrackedApps,
    trackedAppsStatus,
    setTrackedAppsStatus
  };

  return (
    <ProjectAppsContext.Provider value={projectAppsProviderValue}>
      {children}
    </ProjectAppsContext.Provider>
  );
}

ProjectAppsProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { ProjectAppsContext, ProjectAppsProvider };
