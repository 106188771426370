import React, { useEffect, useState } from 'react';

// Types
import { FETCH_STATE_ENUM } from 'api/constants';

interface Props {
  status: FETCH_STATE_ENUM;
}

/**
 * Hook for tracking status of an api call for a certain id
 *
 * @param status
 */
function useTrackStatus({ status }: Props) {
  // App that is being added
  const [activeApp, setActiveApp] = useState({});

  useEffect(() => {
    setActiveApp((prev) => ({
      [Object.keys(prev)[0]]: status
    }));
  }, [status]);

  return {
    activeApp,
    setActiveApp
  };
}

export default useTrackStatus;
