import PropTypes from 'prop-types';

// Styles
import { Box, Grid, Translation } from './styled';

/**
 * Renders the cell for the keyword with the translation
 *
 * @param keyword           Keyword
 * @param translation       Keyword translation
 * @param showTranslation   Determines whether to show the translation
 */
function KeywordCell({ keyword, translation, showTranslation }) {
  return (
    <Grid>
      <Box>{keyword}</Box>
      <Translation>{showTranslation && translation}</Translation>
    </Grid>
  );
}

KeywordCell.propTypes = {
  keyword: PropTypes.string.isRequired,
  translation: PropTypes.string,
  showTranslation: PropTypes.bool
};

export default KeywordCell;
