import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from 'components/Modal';

// Styles
import { Button } from './styled';

/**
 * @name ButtonModal
 * @description Shared, reusable component to open and close modals with important information.
 * This component handles also an action button, which is displayed inside the modal.
 * @param  {Object} props
 * @param  {Boolean} props.fullWidth If button which opens the modal should be fullWidth
 * @param  {JSX.Element | String} props.openModalButtonText Defaults to `More`
 * @param  {Function} props.handleExit Function which will be called after the modal unmounts
 * @param  {Boolean} props.isLoading
 * @param  {Boolean} props.disabled
 * @param  {String} props.title
 * @param  {JSX.Element | String} props.body
 * @param  {JSX.Element} props.actionButton
 * @param  {String} props.color
 */
function ButtonModal({
  color = 'primary',
  fullWidth,
  isLoading,
  disabled,
  openModalButtonText = 'More',
  handleExit,
  title,
  body,
  actionButton
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <React.Fragment>
      <Button
        fullWidth={fullWidth}
        role="button"
        type="button"
        onClick={() => handleOpen()}
        variant="contained"
        color={color}
        disabled={disabled}
      >
        {openModalButtonText}
      </Button>
      <Modal
        isLoading={isLoading}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disabled={disabled}
        handleExit={handleExit}
        actionButton={actionButton}
        body={body}
        title={title}
      />
    </React.Fragment>
  );
}

ButtonModal.propTypes = {
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  openModalButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.oneOf([React.Fragment])
  ]),
  color: PropTypes.string,
  handleExit: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.oneOf([React.Fragment])
  ]),
  actionButton: PropTypes.element
};

export default ButtonModal;
