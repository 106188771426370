import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Hooks
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

// Components
import DateRangeFilter from './DateRangeFilter';
import SelectFilter from './SelectFilter';
import TextFieldFilter from './TextFieldFilter';
import RangeFilter from './RangeFilter';
import FilterChips from './FilterChips';

// Styles
import { Box, AccordionSummary, ButtonsBox, KeywordTrackingTableFiltersWrapper } from './styled';

// Constants
import {
  FILTER_TYPES,
  KEYWORD_TRACKING_TABLE_FILTERS,
  KEYWORD_TRACKING_TABLE_FILTERS_SCHEMA
} from 'contexts/KeywordTrackingTable';

const INPUT_WIDTH = 120;

function KeywordTrackingTableFilters({ columns, size = 'small' }) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable'
  });
  const { filters, queryFilters, handleFiltersSubmit, handleFiltersReset } =
    useKeywordTrackingTable();

  const formattedValues = {
    ...filters,
    ...queryFilters
  };

  const {
    handleSubmit,
    isValid,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    errors,
    values,
    setValues
  } = useFormik({
    initialValues: {
      ...formattedValues
    },
    validateOnChange: false,
    validationSchema: KEYWORD_TRACKING_TABLE_FILTERS_SCHEMA,
    onSubmit: (values) => {
      // We don't reset the form, so we need to update the values in the form
      // and in the context
      // We are providing entire values object in order to make sure that Formik
      // won't remove a empty property - otherwise the form will break.

      setValues({ ...filters, ...values });
      // TODO
      // this seems to fix the issue of value undefined but still
      // but after submit > refresh > clear filters, values seem not to reset properly
      handleFiltersSubmit({ ...filters, ...values });
    },
    onReset: () => {
      // Reset filters values
      handleFiltersReset();
      setValues(KEYWORD_TRACKING_TABLE_FILTERS);
    }
  });

  return (
    <Accordion disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('KeywordTrackingTableFilters.filters')}</Typography>
        <FilterChips filters={filters} columns={columns} />
      </AccordionSummary>
      <AccordionDetails>
        <KeywordTrackingTableFiltersWrapper
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          <Box>
            {columns.map((column) => {
              if (column.filterType === FILTER_TYPES.TEXT) {
                return (
                  <TextFieldFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    value={values[column.field]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                  />
                );
              }
              if (column.filterType === FILTER_TYPES.DATE_RANGE) {
                return (
                  <DateRangeFilter
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                    width={INPUT_WIDTH}
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    size={size}
                    valueStart={values['date_range_start']}
                    valueEnd={values['date_range_end']}
                  />
                );
              }
              if (column.filterType === FILTER_TYPES.RANGE) {
                return (
                  <RangeFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    // If filtersFilters are `null`, default array values to `EMPTY_VALUE`
                    value={[values[`${column.field}_min`], values[`${column.field}_max`]]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                    errors={[errors[`${column.field}_min`], errors[`${column.field}_max`]]}
                  />
                );
              }
              if (column.filterType === FILTER_TYPES.MULTISELECT) {
                return (
                  <SelectFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    // If filtersFilters are `null`, default to `EMPTY_ARRAY`
                    value={values[column.field]}
                    options={column.filterOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                    multiple
                  />
                );
              }

              if (column.filterType === FILTER_TYPES.SELECT) {
                return (
                  <SelectFilter
                    key={column.headerName}
                    label={column.headerName}
                    name={column.field}
                    // If filtersFilters are `null`, default to `EMPTY_VALUE`
                    value={values[column.field]}
                    options={column.filterOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={size}
                    width={INPUT_WIDTH}
                  />
                );
              }

              // If column does not have a `filterType`, return nothing.
              return null;
            })}
          </Box>
          <ButtonsBox>
            <Button
              type="submit"
              disabled={!isValid}
              // If any filter is invalid, disable filter submission
              variant="outlined"
            >
              {t('KeywordTrackingTableFilters.filter')}
            </Button>
            <Button color="warning" type="reset" variant="outlined">
              {t('KeywordTrackingTableFilters.clear_filters')}
            </Button>
          </ButtonsBox>
        </KeywordTrackingTableFiltersWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

KeywordTrackingTableFilters.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  columns: PropTypes.array.isRequired
};

export default KeywordTrackingTableFilters;
