export const ACTIONS = {
  INITIALIZE: 'INITIALIZE',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_UP: 'SIGN_UP',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  ERROR: 'ERROR',
  ERROR_GOOGLE: 'ERROR_GOOGLE',
  UPDATE_PROJECTS_LIST: 'UPDATE_PROJECTS_LIST'
};

export const STATUS = {
  IDLE: 'IDLE',
  LOCAL_SIGN_UP: 'LOCAL_SIGN_UP',
  GOOGLE_SIGN_UP: 'GOOGLE_SIGN_UP',
  LOCAL_SIGN_IN: 'LOCAL_SIGN_IN',
  GOOGLE_SIGN_IN: 'GOOGLE_SIGN_IN',
  RESET_PASSWORD: ACTIONS.RESET_PASSWORD
};
