import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';
import TextField from 'components/InputText';
import TooltipWithIcon from 'components/TooltipWithIcon';
//styles
import { BoxColumn, BoxRow } from './styled';
// action type
export const TYPES = {
  ADD_LIST: 'ADD_LIST',
  EDIT_LIST: 'EDIT_LIST',
  DELETE_LIST: 'DELETE_LIST'
};

/**
 * @name ModalDataBody
 * @description ModalDataBody component for edit, create and delete actions
 * @param {string} type - type of action
 * @param {string} payloadListName - name of list to be added, edited or deleted
 * @param {function} setPayloadListName - function to set payloadListName
 * @param {string} text - text to be displayed in modal
 * @param {boolean} inputDisabled - disable input text
 * @param {string} tooltipTitle - title of tooltip
 * */
function ModalDataBody({
  type,
  payloadListName,
  setPayloadListName,
  text,
  inputDisabled,
  tooltipTitle
}) {
  const handleChange = (e) => {
    setPayloadListName(e.target.value);
  };
  // when editing or creating new list, show text input field
  if (type === TYPES.ADD_LIST || type === TYPES.EDIT_LIST) {
    return (
      <BoxColumn>
        <BoxRow>
          <Typography component="div" mb={5} mr={2} variant="subtitle2">
            {text}
          </Typography>
          <TooltipWithIcon title={tooltipTitle} />
        </BoxRow>
        <TextField
          fullWidth
          value={payloadListName}
          handleChange={handleChange}
          disabled={inputDisabled}
        />
      </BoxColumn>
    );
  }
  // when deleting list display only text
  if (type === TYPES.DELETE_LIST) {
    return (
      <Alert icon={<WarningIcon color="error" />} severity="error">
        {text}
      </Alert>
    );
  }
}

ModalDataBody.propTypes = {
  type: PropTypes.string.isRequired,
  payloadListName: PropTypes.string,
  setPayloadListName: PropTypes.func,
  text: PropTypes.string.isRequired,
  inputDisabled: PropTypes.bool,
  tooltipTitle: PropTypes.string
};

export default ModalDataBody;
