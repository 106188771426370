import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiTextField from '@mui/material/TextField';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export const TextField = styled(MuiTextField)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.sidebar.input.background};
  border-radius: 32px;
  outline: none;
  height: ${({ theme }) => theme.spacing(12)};
  .MuiInputBase-root {
    height: ${({ theme }) => theme.spacing(12)};
    border-radius: 32px;
    color: white;
  }

  .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
    color: white;
    margin-left: ${({ theme }) => theme.spacing(8)};
  }
`;

const iconStyles = css`
  position: absolute;
  right: 9px;
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  ${iconStyles};
`;

export const ExpandLessIcon = styled(ExpandLess)`
  ${iconStyles};
  color: ${({ theme }) => theme.palette.primary.main};
`;
