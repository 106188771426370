import React from 'react';
import PropTypes from 'prop-types';

// Styles
import {
  Typography,
  FilterWrapper,
  FilterInput
} from 'pages/app/Application/KeywordTrackingTable/styled';

/**
 * @name TextFieldFilter
 * @description Filter by the provided text.
 * @param  {Object} props
 * @param  {String} props.label Header name of the corresponding column for this filter.
 * @param  {String} props.name Name of the corresponding column for this filter.
 * @param  {Function} props.onChange Function that sets up the filter for this component
 * @param  {Function} props.onBlur Formik onBlur handle
 * @param  {Array} props.value Current filter value
 * @param  {Number} props.width Current input width
 * @param  {'small' | 'medium' | 'large'} props.size Size of the input. Defaults to 'small'
 */
function TextFieldFilter({ label, size, name, width, onChange, onBlur, value }) {
  return (
    <FilterWrapper sx={{ width }}>
      <Typography>{label}</Typography>
      <FilterInput
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        fullWidth
        variant="outlined"
        placeholder={label}
        value={value}
        size={size}
        width={width}
      />
    </FilterWrapper>
  );
}

TextFieldFilter.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  width: PropTypes.number
};

export default TextFieldFilter;
