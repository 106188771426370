import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledSkeleton } from './styled';

/**
 * @name Skeleton
 * @description A complex Skeleton component that expands the default Material UI Skeleton implementation.
 * @param  {Object} props
 * @param  {'rectangular' | 'text' | 'circular'} props.variant Defaults to 'rectangular'.
 * @param  {Number | String} props.maxWidth Defaults to 160.
 * @param  {Number | String} props.width Defaults to '100%'.
 * @param  {Number | String} props.height Defaults to 24.
 * @param  {Number} props.mb In Material UI spacing style (0, 1, 2, 3, etc.).
 * @param  {Number} props.borderRadius In Material UI spacing style (0, 1, 2, 3, etc.).
 */
function Skeleton({
  variant = 'rectangular',
  maxWidth = 160,
  width = '100%',
  height = 24,
  mb = 0,
  borderRadius = 1
}) {
  return (
    <StyledSkeleton
      variant="rectangular"
      max-width={maxWidth}
      width={width}
      height={height}
      mb={mb}
      border-radius={borderRadius}
    >
      <span className="SrOnly">Loading</span>
    </StyledSkeleton>
  );
}

Skeleton.propTypes = {
  variant: PropTypes.oneOf(['rectangular', 'text', 'circular']),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.number,
  borderRadius: PropTypes.number
};

export default Skeleton;
