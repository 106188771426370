import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiSelect from '@mui/material/Select';

// We calculate width of the DateRangeInputBox as two times the normal input
// plus we need to take into consideration the gap width!
export const DateRangeInputBox = styled(MuiBox)`
  width: calc(${(props) => props.width}px * 3 + (${(props) => props.theme.spacing(2)} * 2));
`;

export const DateRangeInputWrapper = styled(MuiBox)`
  display: flex;
  gap: ${(props) => props.theme.spacing(0.5)};

  .react-datepicker-wrapper {
    width: 50%;
  }
  .react-datepicker-wrapper
    > .react-datepicker__input-container
    > .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
`;

export const Select = styled(MuiSelect)`
  width: 50%;
  .MuiInputBase-input {
    padding: 8.5px 8px;
  }
`;
