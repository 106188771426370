import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

// Contants
import { URLS } from 'constants/URL';

// Components
import Loader from 'components/Loader';

const DEFAULT_REDIRECT_TIMEOUT = 3000;

/**
 * @name SignInSuccess
 * @description This component is used in the Sign In page as well as
 * for the Sign Up page (if the user use Google OAuth).
 * When Sign in or Sign up (via Google OAuth) is successful,
 * the user will be redirected to the main page.
 */
function SignInSuccess() {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_to') || URLS.DASHBOARD;
  const navigate = useNavigate();
  const { t } = useTranslation('shared', { keyPrefix: 'SignInSuccess' });

  useEffect(() => {
    // If user is logged in, redirect user to the dashboard of the last selected project
    let timer = setTimeout(() => {
      navigate(redirectUrl);
    }, DEFAULT_REDIRECT_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('success')}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t('will_be_redirected')}
      </Typography>
      <Loader />
    </React.Fragment>
  );
}

export default SignInSuccess;
