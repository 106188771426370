/**
 * OKSReducer actions
 */
export const ACTIONS = {
  SET_FILTER: 'SET_FILTER',
  SET_FILTER_KEYWORD: 'SET_FILTER_KEYWORD',
  SET_COMPETITORS: 'SET_COMPETITORS',
  SET_RECOMMENDED_KEYWORD: 'SET_RECOMMENDED_KEYWORD'
};

/**
 * OKS Header filter options
 */
export const FILTER_OPTIONS = {
  ALL: 'All',
  UNUSED: 'Unused',
  BRAND: 'Brand',
  COMPETITOR: 'Competition',
  SET: 'Set',
  TITLE: 'Title'
};
