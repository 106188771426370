import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { Root } from './styled';

function Loader({ color = 'secondary', size = 40 }) {
  return (
    <Root>
      <CircularProgress size={size} color={color} />
    </Root>
  );
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default Loader;
