import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';

// Fetchers
import { ERROR, API } from 'api';

// Components
import CreateProjectForm from 'pages/app/CreateProject/Form';
import CreateProjectSuccess from 'pages/app/CreateProject/CreateProjectSuccess';
import BrandIcon from 'components/BrandIcon';

// Hooks
import useAuth from 'hooks/useAuth';

// Constants
import { URLS } from 'constants/URL';

// Styles
import { Wrapper, Container } from './styled';

// Utils
import StorageService from 'utils/storage';
import { buildUrl } from 'utils/utils';

/**
 * @name CreateProject
 * @description Create Project page, contains form to create new project
 * */

function CreateProject() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'CreateProject'
  });
  const { user, updateProjectsList } = useAuth();
  const { group } = user;
  // todo add proper permissions when ready
  const canCreateProject = group === 'Standard (phiture)';
  const localStorageProjectId = StorageService.getLocalStorageLastProjectId();

  // create form mutation
  const { mutateAsync: createProject, data: newProject } = useMutation(
    (mutationData) => {
      return API.projectCreate(mutationData);
    },
    {
      onSuccess: async (responseData) => {
        const { data } = responseData;
        const { id: projectId, name: projectName } = data;
        updateProjectsList(projectId, projectName);
      }
    }
  );

  const createProjectSuccess = newProject?.msg;
  const newProjectId = newProject?.data?.id;
  const newProjectName = newProject?.data?.name;

  // handle onSubmit
  const handleOnSubmit = async (
    // data passed in form
    values,
    // form state
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const mutationData = {
        name: values.name,
        reporting: values.reporting
      };
      // call post api, via mutation
      await createProject(mutationData);
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (
        error instanceof ERROR.ApiResponseBadRequest ||
        error instanceof ERROR.ApiResponseUnauthorized ||
        error instanceof ERROR.ApiResponseForbidden
      ) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('something_went_wrong');
        tempErrors = { submit: [message] };
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  if (!canCreateProject) {
    return (
      <Wrapper>
        <HeartBrokenOutlinedIcon fontSize="large" color="warning" />
        <Typography variant="h5">{t('no_permission_ask_to_share_project')}</Typography>
        <Grid item xs={12} my={2}>
          <Button component={Link} to={URLS.PROJECT_EMPTY} fullWidth color="primary">
            {t('go_to_dashboard')}
          </Button>
        </Grid>
      </Wrapper>
    );
  }
  const to = localStorageProjectId
    ? buildUrl(URLS.PROJECT, { projectId: localStorageProjectId })
    : URLS.PROJECT_EMPTY;

  return (
    <Container>
      <BrandIcon isPhiture size="md" />
      <Helmet title={t('create_project')} />
      <Wrapper>
        {/* when the project is created successfully show user button to go to project dashboard*/}
        {createProjectSuccess && newProjectId ? (
          <CreateProjectSuccess
            successMsg={createProjectSuccess}
            newProjectId={newProjectId}
            newProjectName={newProjectName}
          />
        ) : (
          <React.Fragment>
            <Typography component="div" variant="subtitle1" align="center" gutterBottom>
              {t('create_project')}
            </Typography>
            <CreateProjectForm handleOnSubmit={handleOnSubmit} successMsg={createProjectSuccess} />
            <Grid item xs={12} my={2}>
              <Button component={Link} to={to} fullWidth color="primary">
                {t('go_to_dashboard')}
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Wrapper>
    </Container>
  );
}
export default CreateProject;
