import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import MuiBox from '@mui/material/Box';
import Card from '@mui/material/Card';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';

// Components
import Skeleton from 'components/Skeleton';
import AppModal from 'components/AppModal';

// Hooks
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useTrackStatus from 'hooks/useTrackStatus';

// Constants
import { PLATFORM_OPTIONS, PLATFORMS, PRIORITY_OPTIONS } from 'constants/constants';
import { URLS } from 'constants/URL';
import { CACHE_KEYS, FETCH_STATE } from 'api/constants';

// Utils
import { buildUrl } from 'utils/utils';
import { getCountryName, canUserLinkApp } from './utils';
import { ACTIONS } from 'constants/App';

// Styles
import {
  Logo,
  Box,
  Flag,
  Typography,
  CardContent,
  AppDetailsWrapper,
  Title,
  Subtitle,
  Select,
  Switch,
  TranslationBox
} from './styled';

// Fetchers
import { updateAppTier } from 'api/api';
import useLinkAppsMutation from 'api/queries/App/useLinkQuery';

const platformOptions = [
  {
    value: PLATFORMS.IPHONE,
    label: 'iPhone'
  },
  {
    value: PLATFORMS.ANDROID,
    label: 'Android'
  }
];

/**
 * @name Details
 * @description Display app details, enables to change current app platform, language and country
 */
function Details() {
  const { t } = useTranslation('components', {
    keyPrefix: 'AppHeader.Details'
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { status, app, allTrackedLocales, isFetching, translateKeywords, dispatch } = useApp();
  const { projectId, appId, platform } = useParams();
  const { user } = useAuth();

  const {
    projects: { share_projects }
  } = user;

  const mutateTier = useMutation((tier) => updateAppTier({ projectId, appId, platform, tier }), {
    onSuccess: () => {
      queryClient.invalidateQueries([CACHE_KEYS.APPS, projectId, appId, platform]);
    }
  });

  const mutateLinkApps = useLinkAppsMutation({ projectId, appId, platform });
  // state
  // language options dynamically change on country change
  const [langOptions, setLangOptions] = React.useState([]);
  // language current value (needs to be set this way to avoid app.id loading before language options)
  const [langValue, setLangValue] = React.useState('');
  // Modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // App that is being linked
  const { activeApp, setActiveApp } = useTrackStatus({ status: mutateLinkApps.status });
  // Link app error
  const [error, setError] = React.useState(false);

  // Dynamically create country options
  const countryOptions = Object.keys(allTrackedLocales).map((key) => ({
    value: key,
    label: getCountryName(key)
    // TODO: replace country label with flag when country/language code available in allTrackedLocales
    //<><Flag src={`/static/img/flags/${app.country.toLowerCase()}.png`} alt={app.locale} />{key}</>
  }));

  // on country change, navigate to first language market option
  const onCountryClick = (country) => {
    const firstLanguage = allTrackedLocales[country][0].value;
    navigate(
      buildUrl(URLS.TRACKING_TABLE, { projectId, appId: firstLanguage, platform: app.platform })
    );
  };

  // on language change, navigate to new language market
  const onLocaleClick = (id) => {
    setLangValue(id);
    navigate(buildUrl(URLS.TRACKING_TABLE, { projectId, appId: id, platform: app.platform }));
  };

  // on platform change, navigate to new platform market
  const onPlatformClick = () => {
    // todo disable platform switch for ipad
    const newPlatform = app.platform === PLATFORMS.ANDROID ? PLATFORMS.IPHONE : PLATFORMS.ANDROID;

    const otherPlatform = app.other_id.find((app) => app.platform === newPlatform);

    // If we already have a linked app, navigate to it
    if (otherPlatform) {
      navigate(
        buildUrl(URLS.TRACKING_TABLE, {
          projectId,
          appId: otherPlatform.app_detail_id,
          platform: newPlatform
        })
      );
      return;
    }

    // If the user is not an admin, inform the user
    const canLinkApps = canUserLinkApp(share_projects, projectId);

    // If the user is an admin, open the modal to link apps
    canLinkApps ? setIsModalOpen(true) : setError(true);
  };

  useEffect(() => {
    // on app.country change, update language options
    if (!app.country) return;
    setLangOptions(allTrackedLocales[app.country]);
    setLangValue(appId);
  }, [app.id]);

  // Format reviewers to compact format. E.g.: 231242 ratings => 231K ratings
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });

  // Check if app is already linked
  const isLinked = (newApp) => app.other_id.find((app) => app.app_detail_id === newApp.id);

  /**
   * Toggles translations
   */
  const handleShowTranslations = () => {
    dispatch({ type: ACTIONS.TOGGLE_TRANSLATIONS });
  };

  const initialParams = {
    platform: app.platform === PLATFORMS.IPHONE ? PLATFORMS.ANDROID : PLATFORMS.IPHONE,
    country: app.country,
    locale: app.locale,
    searchId: ''
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  /**
   * Link apps from different platforms
   */
  const handleLinkApps = (app) => {
    const newAppId = app.app.id;
    const applePlatform =
      app.platform === PLATFORMS.IPHONE || app.platform === PLATFORMS.IPAD
        ? app.platform
        : undefined;

    mutateLinkApps.mutate(
      { newAppId, applePlatform },
      {
        onSuccess: (response) => {
          navigate(
            buildUrl(URLS.TRACKING_TABLE, {
              projectId,
              appId: response.data,
              platform: app.platform
            })
          );
          handleCloseModal();
        }
      }
    );
    setActiveApp(() => ({ [newAppId]: FETCH_STATE.LOADING }));
  };

  if (status === FETCH_STATE.LOADING || isFetching) {
    return (
      <Card>
        <CardContent>
          <Skeleton width={112} height={112} borderRadius={2} />
          <AppDetailsWrapper>
            <MuiBox mb={4}>
              <Skeleton maxWidth={150} height={26} mb={2} />
              <Skeleton maxWidth={200} height={10} mb={2} />
              <Skeleton maxWidth={100} height={8} mb={2} />
            </MuiBox>
            <Box>
              <Skeleton maxWidth={200} height={35} />
              <Skeleton maxWidth={200} height={35} />
              <Skeleton maxWidth={200} height={35} />
              <Skeleton maxWidth={200} height={35} />
            </Box>
          </AppDetailsWrapper>
        </CardContent>
      </Card>
    );
  }
  return (
    <>
      <Card>
        <CardContent>
          <Logo src={app.icon}></Logo>
          <AppDetailsWrapper>
            <MuiBox mb={4}>
              <Title component="h1" mb={2}>
                {app.name}
              </Title>
              <Subtitle mb={2}>{app.category}</Subtitle>
              {app.ratings && (
                <Typography component="p" variant="subtitle4" mb={2}>
                  {formatter.format(app.ratings)} {t('ratings')}
                </Typography>
              )}
            </MuiBox>
            <Box>
              <Select
                id="app-platform"
                value={app.platform}
                // todo platform switch only supported between android and iphone. remove ipad option when app.platform !== ipad
                options={PLATFORM_OPTIONS}
                onChange={(e) => onPlatformClick(e.target.value)}
                width={82}
              />
              <Select
                id="app-country"
                status={status}
                // TODO country missing in ipad apps response
                value={app.country}
                options={countryOptions}
                onChange={(e) => onCountryClick(e.target.value)}
                width={82}
              />
              <Select
                id="app-language"
                status={status}
                options={langOptions}
                value={langValue}
                onChange={(e) => onLocaleClick(e.target.value)}
                width={82}
              />
              <Select
                id="app-priority-markets"
                value={app.tier}
                options={PRIORITY_OPTIONS.map(({ value, label }) => ({
                  value,
                  label: <Trans key={value} i18nKey={label} count={Number(value)} />
                }))}
                onChange={(e) => mutateTier.mutate(Number(e.target.value))}
                width={82}
              />

              <TranslationBox component="span">
                {t('translate')}
                <Switch checked={translateKeywords} onChange={handleShowTranslations} />
              </TranslationBox>
            </Box>
          </AppDetailsWrapper>
        </CardContent>
      </Card>
      <AppModal
        status={activeApp}
        onSubmit={handleLinkApps}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={t('link_apps')}
        buttonText={t('link')}
        isTracked={isLinked}
        platformOptions={platformOptions}
        initialParams={initialParams}
      />
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={() => setError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error">{t('error_linking_apps')}</Alert>
      </Snackbar>
    </>
  );
}

export default Details;
