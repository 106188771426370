import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Constants
import { FETCH_STATE } from 'api';

// Styles
import { FormControl } from './styled';

/**
 * @name Select
 * @description Custom select for displaying app various options
 * @param  {Object} props
 * @param  {String} props.id
 * @param  options
 * @param  {Number | String} props.value
 * @param  {Function} props.renderValue value to be displayed
 * @param  {Function} props.onChange
 * @param  {React.Node | String} props.label Optional
 * @param  {Boolean} props.isCompact Optional
 * @param  {Number} props.width
 * @param  {Number} props.status Optional
 * @param  {Boolean} props.multiple allows multi selection
 * @param  {Boolean} props.disabled
 * @param  {String} props.className
 */
function Select({
  id,
  options,
  value,
  renderValue,
  onChange,
  isCompact = false,
  width = null,
  label = null,
  disabled = false,
  multiple = false,
  status,
  className
}) {
  // states for when we are fetching the options
  // LOADING
  if (status === FETCH_STATE.LOADING) {
    return (
      <React.Fragment>
        <Skeleton width={width} data-testid="skeleton-select-loading" />
      </React.Fragment>
    );
  }

  // ERROR
  if (status === FETCH_STATE.ERROR) {
    return (
      <FormControl sx={{ width: `${width}px` }} size="small" error disabled>
        <InputLabel color="secondary" id={`${id}select-label`}>
          <ErrorOutline />
        </InputLabel>
        <MuiSelect value="" />
      </FormControl>
    );
  }

  return (
    <FormControl
      className={className}
      size="small"
      width={width}
      compact={!!isCompact}
      disabled={disabled}
    >
      {label && (
        <InputLabel htmlFor={`label-${id}`} id={`label-${id}`}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        labelId={`label-${id}`}
        id={id}
        value={value}
        renderValue={renderValue}
        onChange={onChange}
        label={label}
        multiple={multiple}
      >
        {options?.map((option) => {
          // Check if `options` array is an array of objects
          if (typeof option === 'object' && option !== null) {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          }
          // Else `options` is an array of strings
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  // `options` can be either an array of object with `value` and `label` properties
  // or an array of strings
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
      }).isRequired
    ),
    PropTypes.arrayOf(PropTypes.string).isRequired
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ]),
  renderValue: PropTypes.func,
  onChange: PropTypes.func, //.isRequired,
  isCompact: PropTypes.bool,
  width: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  status: PropTypes.string,
  className: PropTypes.string
};

export default Select;
