import styled from '@emotion/styled';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiBox from '@mui/material/Box';

export const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;
  & .MuiAccordionSummary-content {
    min-height: 36px;
    align-items: center;
  }
`;

export const Box = styled(MuiBox)`
  gap: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(1)};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const ButtonsBox = styled(MuiBox)`
  gap: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(1)};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
`;

export const KeywordTrackingTableFiltersWrapper = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(1)};
  align-items: flex-start;
`;
