import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export const RowContainer = styled(Grid)`
  margin: 20px 0;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

export const AppInfoContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const TextContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 10px;
`;

export const IconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45px;
`;
