export interface Project {
  project_id: string;
  account_name: string;
  account_title: string;
  role: ProjectRole;
  type: string;
}

export enum ProjectRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Owner = 'Owner'
}
