import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MuiTypography from '@mui/material/Typography';
import spacing from '@mui/system/spacing';

export const Typography = styled(MuiTypography)(spacing);

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    align-content: space-around;
  }
`;

export const TextContent = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

export const Title = styled(Typography)`
  opacity: 0.9;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};
  margin-right: 20px;

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: 2rem;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    font-size: 2.5rem;
  }
`;

export const TitleBrandContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

export const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: ${(props) => props.theme.typography.subtitle2.fontSize};
  }
`;

export const SignInContainer = styled.div`
  max-width: 520px;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const WrapperInner = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  // Make everything the same size as the Google OAuth button
  & > * {
    max-width: 327px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
