import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyle from 'components/GlobalStyle';

// Styles
import { Root } from './styled';

/**
 * @name Loader
 * @description Layout for loader
 * @param  {Object} props
 * @param  {React.ReactNode} props.children
 */
const Loader = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

Loader.propTypes = {
  children: PropTypes.node
};

export default Loader;
