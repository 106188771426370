import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';

// Components
import AddKeywordManual from './AddKeywordManual';
import AddKeywordPanel from './AddKeywordPanel';
import FeedbackToast from 'components/FeedbackToast';
import ComingSoonMessage from 'components/ComingSoonMessage';

// Hooks
import useApp from 'hooks/useApp';
import useFilters from 'hooks/useFilters';

// Fetchers
import { API, FETCH_STATE } from 'api';
import { addKeywordsToAppKeywordLists } from 'api/api';

const TABS = [
  {
    index: 0,
    label: 'manual',
    content: <AddKeywordManual />
  },
  {
    index: 1,
    label: 'ranked',
    content: <ComingSoonMessage />
  },
  {
    index: 2,
    label: 'competitor',
    content: <ComingSoonMessage />
  },
  {
    index: 3,
    label: 'android',
    content: <ComingSoonMessage />
  }
];

const tabProps = (index) => {
  return {
    id: `add-keywords-tab-${index}`,
    'aria-controls': `add-keywords-tabpanel-${index}`
  };
};

/**
 * @name AddKeywordsTabs
 * @description tab groups containing the various tables to add keywords to tracked table (only to currently displayed list
 * @param {function} refetchKeywords re-fetches tracked table keywords
 * @param {Number} listSelectedId keyword list id to add keywords to
 * */

function AddKeywordsTabs({ refetchKeywords, listSelectedId }) {
  const { t } = useTranslation('components', { keyPrefix: 'KeywordTrackingTable.AddKeywordTabs' });
  // manage tab change
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (_e, newValue) => {
    setTabIndex(newValue);
  };

  // manage feedback modal state
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const onFeedbackClose = () => {
    setFeedbackOpen(false);
  };

  // project and app details for api
  const { projectId, appId, platform } = useParams();
  const { status: appDetailsStatus, app } = useApp();

  // add keywords payload
  const initialPayload = {
    addIndividualTerms: false,
    keywords: ''
  };

  const [payload, setPayload, resetPayload] = useFilters(initialPayload);

  const { status: addKeywordsStatus, mutateAsync: addKeywords } = useMutation(
    () => {
      return addKeywordsToAppKeywordLists({
        projectId,
        appId,
        platform: app.platform,
        keywordListIds: listSelectedId.toString(),
        ...payload
      });
    },
    {
      onSuccess: async (responseData) => {
        // get the count of keywords added from the resp message
        // (if you try to add a keywords that already existed, that won't be included in the count)
        const count = parseInt(responseData.msg[0]);
        setFeedbackOpen(true);
        setFeedbackMessage(t('keywords_added_successfully'));
        refetchKeywords();
        resetPayload();
      },
      onError: () => {
        setFeedbackOpen(true);
        setFeedbackMessage(t('error_adding_keywords'));
      }
    }
  );

  // disable add keywords button when
  // app is details are still fetching,
  // adding keyword loading
  // there are no keywords in payload
  const isAddButtonDisabled =
    [appDetailsStatus, addKeywordsStatus].some((el) => el === FETCH_STATE.LOADING) ||
    payload.keywords === '';

  const onAddKeywordsClick = async () => {
    await addKeywords();
    // when 'add as csv' ready, add if statement here
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/*tabs Navbar*/}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChange} aria-label="add keywords tabs">
          {TABS.map(({ label, index }) => (
            <Tab label={t(label)} {...tabProps(index)} key={label} />
          ))}
        </Tabs>
      </Box>
      {/*tabs panels content*/}
      {TABS.map(({ label, index, content }) => (
        <AddKeywordPanel value={tabIndex} index={index} key={label}>
          {/* allows to pass props to child component */}
          {React.cloneElement(content, { payload, setPayload, status: addKeywordsStatus })}
        </AddKeywordPanel>
      ))}
      {/*footer*/}
      <Box sx={{ p: 3 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onAddKeywordsClick}
          disabled={isAddButtonDisabled}
          endIcon={
            addKeywordsStatus === FETCH_STATE.LOADING ? <CircularProgress size={20} /> : <AddIcon />
          }
        >
          {t('add_keywords')}
        </Button>
      </Box>
      {/*feedback snackbar*/}
      <FeedbackToast
        open={feedbackOpen}
        onClose={onFeedbackClose}
        message={feedbackMessage}
        status={addKeywordsStatus}
      />
    </Box>
  );
}

AddKeywordsTabs.propTypes = {
  refetchKeywords: PropTypes.func,
  listSelectedId: PropTypes.number
};
export default AddKeywordsTabs;
