import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

/**
 * Alert component which can be collapsed.
 *
 * @param {boolean} isOpen                                            - is the Alert open
 * @param {"success" | "info" | "warning" | "error"} severity         - determines the color of the Alert
 * @param {function} handleClose                                      - handler for closing the Alert
 * @param {string} className                                          - className
 * @param children                                                    - Child component
 */
function CollapsableAlert({ isOpen, severity, handleClose, className, children }) {
  return (
    <Collapse className={className} in={isOpen}>
      <Alert severity={severity} onClose={handleClose}>
        {children}
      </Alert>
    </Collapse>
  );
}

CollapsableAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  handleClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.string.isRequired])
};

export default CollapsableAlert;
