import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';

// Styles
import {
  ExportButton,
  SelectCustom,
  Typography,
  RecommendedKeyword,
  Image,
  InputText,
  Competitor,
  Container,
  Box
} from './styled';

// Hooks
import useOKSTable from 'hooks/useOKSTable';
import useApp from 'hooks/useApp';

// Constants
import { ACTIONS, FILTER_OPTIONS } from 'constants/OKS';

// Components
import Label from './Label';

// Assets
import SearchIcon from 'assets/svg/search.svg';

// Fetchers
import useCompetitorsQuery from 'api/queries/Competitors/useCompetitors';

const TABS = [
  {
    label: 'tabAll',
    value: FILTER_OPTIONS.ALL
  },
  {
    label: 'tabUnused',
    value: FILTER_OPTIONS.UNUSED
  },
  {
    label: 'tabBrand',
    value: FILTER_OPTIONS.BRAND
  },
  {
    label: 'tabCompetitor',
    value: FILTER_OPTIONS.COMPETITOR
  },
  {
    label: 'tabSet',
    value: FILTER_OPTIONS.SET
  },
  {
    label: 'tabTitle',
    value: FILTER_OPTIONS.TITLE
  }
];

/**
 * Renders the OKS header
 */
function OKSHeader() {
  const {
    state: { filterByGroup, tableRows, recommendedKeyword, competitors, filterKeyword },
    dispatch
  } = useOKSTable();
  const { projectId, appId, platform } = useParams();
  const { translateKeywords } = useApp();

  const { t } = useTranslation('components', {
    keyPrefix: 'OptimalKeywordsPage.tableSection.heading'
  });

  const { data: allCompetitors } = useCompetitorsQuery({ appId, platform, projectId });

  /**
   * Sets the active filter for rendering keywords
   */
  const handleChangeFilter = (e) => {
    dispatch({ type: ACTIONS.SET_FILTER, payload: e.target.value });
    dispatch({ type: ACTIONS.SET_RECOMMENDED_KEYWORD, payload: null });
  };

  /**
   * Toggles the competitor
   */
  const handleToggleCompetitor = (e) => {
    const competitorId = e.target.value;
    dispatch({ type: ACTIONS.SET_COMPETITORS, payload: competitorId });
  };

  const selectOptions = allCompetitors?.data.map((comp) => ({
    label: (
      <Label
        icon={comp.icon}
        name={comp.name}
        checked={competitors.indexOf(comp.app_localised_id) > -1}
      />
    ),
    value: comp.app_localised_id
  }));

  /**
   * Renders the selected competitors
   * @param selected Selected elements
   */
  const renderValue = (selected) => {
    const icons = selected.map((s) => {
      const comp = allCompetitors?.data?.find((d) => d.app_localised_id === s);
      return comp ? <Image key={comp.app_localised_id} src={comp.icon} /> : null;
    });

    return (
      <Container>
        <Competitor key={'competitor'}>{t('selectCompetitor')}</Competitor>
        <Container>{icons}</Container>
      </Container>
    );
  };

  /**
   * Handles changing the filtering keyword
   */
  const handleChange = (e) => {
    dispatch({ type: ACTIONS.SET_FILTER_KEYWORD, payload: e.target.value });
  };

  return (
    <Grid
      sx={{ height: '44px' }}
      container
      alignItems="center"
      justifyContent="space-between"
      mb={8}
      mt={8}
    >
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{t('results', { count: tableRows?.length || 0 })}</Typography>
        {recommendedKeyword && (
          <RecommendedKeyword
            showTranslation={translateKeywords}
            name={recommendedKeyword.name}
            isActive={true}
            rank={recommendedKeyword.rank}
            translation={recommendedKeyword.en_translation}
            popularityScore={recommendedKeyword.search_popularity}
            competitorCount={recommendedKeyword.competitors_count}
            competitorRelevancy={recommendedKeyword.relevancy_competitor}
            id={recommendedKeyword.keyword_id}
          />
        )}
      </Grid>
      <Grid display="flex" alignItems="center">
        <SelectCustom
          value={filterByGroup}
          onChange={handleChangeFilter}
          id="filterByGroup"
          options={TABS.map(({ label, value }) => ({
            label: <Box>{t(label)}</Box>,
            value: value
          }))}
          variant={'filled'}
        />
        <InputText
          variant="outlined"
          value={filterKeyword}
          handleChange={handleChange}
          name="tableFilter"
          placeholder={t('tableFilter')}
          InputProps={{
            startAdornment: <SearchIcon height={14} width={14} />
          }}
          sx={{
            '& input::placeholder': {
              color: 'text.primary',
              fontWeight: '500',
              opacity: '1'
            }
          }}
        />
        <SelectCustom
          multiple
          value={competitors}
          onChange={handleToggleCompetitor}
          renderValue={renderValue}
          id="competitor-"
          options={selectOptions}
          width={220}
          placeholder={t('selectCompetitor')}
          displayEmpty={true}
        />
        <ExportButton variant="contained" color="secondary">
          {t('export')}
        </ExportButton>
      </Grid>
    </Grid>
  );
}

export default OKSHeader;
