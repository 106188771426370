import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

// Components
import ButtonModal from 'components/ButtonModal';

// Hooks
import useProject from 'hooks/useProject';
import useAlert from 'hooks/useAlert';
import useAuth from 'hooks/useAuth';

// Constants
import { API, ERROR } from 'api';

// Styles
import { ButtonsWrapper, TextField, Card, Button, LoadingButton } from './styled';

const ProjectSettingsSchema = () =>
  Yup.object().shape({
    projectName: Yup.string(),
    projectOwner: Yup.string().email(),
    reporting: Yup.bool()
  });

/**
 * @name ProjectSettingsForm
 * @description Component which displays current project settings
 * and enables user to change:
 * - project name
 * - project owner
 */
function ProjectSettingsForm() {
  const { t } = useTranslation('components', {
    keyPrefix: 'ProjectsSettingsForm'
  });
  const { setAlert } = useAlert();
  const { project, updateProject } = useProject();
  const { updateProjectsList } = useAuth();
  const {
    id: projectId,
    project_users: projectUsers,
    owner_email: projectOwner,
    name: projectName,
    reporting: projectReporting
  } = project;

  const memoProjectSettingsSchema = useMemo(() => ProjectSettingsSchema(), []);

  /**
   * handles editing the project through the API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const editProjectMutation = useMutation(
    (mutationData) => {
      return API.editProject({ projectId }, mutationData);
    },
    {
      onSuccess: async (responseData, mutationData) => {
        const { projectName, projectOwner, projectReporting } = mutationData;
        // Update project locally in the ProjectContext
        // and in the Auth
        updateProject(projectName, projectOwner, projectReporting);
        updateProjectsList(projectId, projectName);
        setAlert(t('project_updated_successfully'));
      }
    }
  );

  /**
   * @name handleOnSubmit
   * @description manages Formik submit function
   */
  const handleOnSubmit = async (values, { errors, setErrors, setStatus, setSubmitting }) => {
    try {
      const { projectName, projectOwner, projectReporting } = values;
      await editProjectMutation.mutateAsync({
        projectName,
        projectOwner,
        projectReporting
      });
      setStatus({ success: true });
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('something_went_wrong');
        tempErrors = { submit: [message] };
        // If this is a general error, display snackbar.
        setAlert(message);
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const {
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    resetForm,
    isValid,
    dirty,
    isSubmitting,
    initialValues
  } = useFormik({
    initialValues: {
      projectName: projectName,
      projectOwner: projectOwner,
      projectReporting: projectReporting,
      submit: false
    },
    validationSchema: memoProjectSettingsSchema,
    onSubmit: handleOnSubmit
  });

  const { projectOwner: currentProjectOwner } = initialValues;
  const { projectOwner: newProjectOwner } = values;
  const changedProjectOwner = newProjectOwner !== currentProjectOwner;
  /**
   * @name handleExit
   * @description this function is invoked when the Modal or Dialog ends its transition.
   * This is very useful, as it enables to trigger change after Modal is unmounted.
   */
  const handleExit = () => {
    resetForm();
  };

  const projectUsersEmail = projectUsers.map((user) => ({
    userEmail: user.user_email,
    isOwner: false
  }));

  projectUsersEmail.push({
    userEmail: projectOwner,
    isOwner: true
  });

  const emptyProjectName = values.projectName === '';

  return (
    <Card mb={6}>
      <CardContent>
        <Typography component="h1" title={t('title')} variant="appH1" mb={4}>
          {t('title')}
        </Typography>
        <Typography component="p" title={t('general_settings')} variant="subtitle1" mb={2}>
          {t('description')}
        </Typography>

        <form noValidate onSubmit={handleSubmit} id="ProjectSettingsForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} my={2}>
              <TextField
                id="projectName"
                name="projectName"
                label={t('project_name')}
                value={values.projectName}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8} my={2}>
              <FormControl fullWidth>
                <InputLabel id="project-owner-label">{t('project_owner')}</InputLabel>
                <Select
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="project-owner-label"
                  name="projectOwner"
                  id="projectOwner"
                  label={t('project_owner')}
                  value={values.projectOwner}
                  variant="outlined"
                >
                  {projectUsersEmail.map((e) => (
                    <MenuItem key={e.userEmail} value={e.userEmail}>
                      {e.userEmail}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={8} my={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="projectReporting"
                    id="projectReporting"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.projectReporting}
                    color="primary"
                    disabled={isSubmitting}
                    defaultChecked={values.projectReporting}
                  />
                }
                label={t('reporting')}
              />
            </Grid>
            <ButtonsWrapper item xs={12}>
              {changedProjectOwner ? (
                <ButtonModal
                  openModalButtonText={t('share')}
                  handleExit={handleExit}
                  title={t('are_you_sure')}
                  isLoading={isSubmitting}
                  disabled={!(isValid && dirty) || emptyProjectName}
                  actionButton={
                    <LoadingButton
                      loading={isSubmitting}
                      role="button"
                      disabled={isSubmitting}
                      type="submit"
                      form="ProjectSettingsForm"
                      variant="contained"
                      color="primary"
                      autoFocus
                    >
                      {t('save')}
                    </LoadingButton>
                  }
                  body={
                    <React.Fragment>
                      <Trans
                        i18nKey="you_will_change_owner"
                        currentProjectOwner={currentProjectOwner}
                        newProjectOwner={newProjectOwner}
                      >
                        You will change this project owner from <b>{{ currentProjectOwner }}</b> to{' '}
                        <b>{{ newProjectOwner }}</b>.
                      </Trans>
                    </React.Fragment>
                  }
                />
              ) : (
                <LoadingButton
                  loading={isSubmitting}
                  role="button"
                  disabled={!(isValid && dirty) || isSubmitting || emptyProjectName}
                  type="submit"
                  form="ProjectSettingsForm"
                  variant="contained"
                  color="primary"
                  data-testid="default_submit_button"
                >
                  {t('save')}
                </LoadingButton>
              )}

              <Button role="button" onClick={resetForm} type="reset" color="secondary">
                {t('cancel')}
              </Button>
            </ButtonsWrapper>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}

export default ProjectSettingsForm;
