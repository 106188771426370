import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Constants
import { FETCH_STATE } from 'api';

// Styles
import { HelperTextError, HelperTextUpdated } from './styled';

/**
 * @name NotesHelperText
 * @description This is a small component that renders state of the note (updated or errored).
 * @param  {Object} props
 * @param  {'idle' | 'success' | 'error' | 'updated' | 'loading'} props.noteStatus
 */
const NotesHelperText = ({ noteStatus }) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'AppHeader.Notes'
  });

  if (noteStatus === FETCH_STATE.ERROR) {
    return (
      <React.Fragment>
        {' '}
        <HelperTextError>{t('note_error')}</HelperTextError>
      </React.Fragment>
    );
  }

  // Custom note status
  if (noteStatus === 'updated') {
    return (
      <React.Fragment>
        {' '}
        <HelperTextUpdated>{t('note_updated')}</HelperTextUpdated>
      </React.Fragment>
    );
  }

  return null;
};

NotesHelperText.propTypes = {
  noteStatus: PropTypes.string.isRequired
};

export default NotesHelperText;
