import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

// Components
import RecommendedKeywords from './RecommendedKeywords';

import Label from './Label';
import Notes from 'pages/app/Application/OKS/Metadata/Notes';

// Styles
import {
  InputGrid,
  SelectGrid,
  InputText,
  Area,
  KeywordArea,
  Container,
  Title,
  Card,
  Button,
  SelectTextField,
  Box,
  CircularProgress
} from './styled';

// Fetchers
import { useOKSMetadataMutation, useOKSMetadataQuery } from 'api/queries/OKS/Metadata';
import useOKSGenerator from 'api/queries/OKS/Generator';

// Hooks
import useKeywordsLists from 'hooks/useKeywordLists';

// Utils
import { removeLineBreaks } from 'utils/utils';

const SELECT_INPUTS = [
  {
    name: 'list',
    label: 'heading.selectList'
  }
];

const TEXT_INPUTS = [
  {
    name: 'title',
    label: 'form.labelTitle',
    id: 'title',
    max: 30
  },
  {
    name: 'subtitle',
    label: 'form.labelSubtitle',
    id: 'subtitle',
    max: 30
  },
  {
    name: 'current',
    label: 'form.labelTarget',
    id: 'current',
    max: 100,
    multiline: true
  },
  {
    name: 'freeKeywords',
    label: 'form.labelFree',
    id: 'freeKeywords',
    max: 500
  },
  {
    name: 'negatives',
    label: 'form.labelNegative',
    id: 'negatives',
    max: 500
  }
];

/**
 * Renders the Optimal Keyword Set Page.
 */
function OKSMetadata() {
  const { t } = useTranslation('components', {
    keyPrefix: 'OptimalKeywordsPage.metadataSection'
  });

  const { projectId, appId, platform } = useParams();

  const { data } = useOKSMetadataQuery({ projectId, appId, platform });
  const updateMetadata = useOKSMetadataMutation({ projectId, appId, platform });

  const { availableLists, listSelected, setListSelected, status } = useKeywordsLists(
    projectId,
    appId,
    platform,
    false
  );

  const {
    data: oksGenerated,
    isLoading,
    isFetching,
    isSuccess
  } = useOKSGenerator({
    projectId,
    appId,
    platform,
    listId: listSelected?.id
  });

  const [form, setForm] = useState({
    title: data?.data?.title || '',
    subtitle: data?.data?.subtitle || '',
    current: data?.data?.current || '',
    freeKeywords: data?.data?.freeKeywords || '',
    negatives: data?.data?.negatives || '',
    notes: data?.data?.notes || ''
  });

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: removeLineBreaks(e.target.value) }));
  };
  /**
   * Updates the metadata when the user leaves the input field.
   */
  const handleBlur = () => {
    // Only mutate if the form is different from the initial data
    if (!isEqual(form, data.data)) {
      updateMetadata.mutate({ ...form });
    }
  };

  const keywordLists = useMemo(
    () => availableLists.map((list) => ({ value: list.id, label: <Box>{list.name}</Box> })),
    [availableLists]
  );

  const handleChangeActiveList = (e) => {
    const newList = availableLists.find((list) => list.id === e.target.value);
    setListSelected(newList);
  };

  const loadingSkeleton = useMemo(() => {
    return Array.from(Array(100).keys()).map((_, i) => {
      const width = Math.floor(Math.random() * (150 - 40 + 1)) + 40;
      return <Skeleton key={i} width={width} />;
    });
  }, []);

  return (
    <Card>
      <Grid>
        <Title>{t('heading.title')}</Title>
        <Grid display="flex">
          <InputGrid mt={8}>
            <Area name="notes">
              <Notes value={form.notes} handleChange={handleChange} handleBlur={handleBlur} />
            </Area>
            {TEXT_INPUTS.map(({ id, name, label, max, multiline }) => (
              <Area name={name} key={id}>
                <InputText
                  onKeyPress={handleEnter}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  width={2000}
                  name={name}
                  placeholder={t(label)}
                  label={<Label max={max} current={form[name].length} label={t(label)} id={id} />}
                  value={form[name]}
                  id={id}
                  maxLength={max}
                  multiline={multiline}
                  rows={1}
                />
              </Area>
            ))}
          </InputGrid>
        </Grid>
      </Grid>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid sx={{ display: 'flex' }} alignItems="center">
            <Title>{t('heading.oks_suggestions')}</Title>
            {isFetching && isSuccess && <CircularProgress size={16} />}
          </Grid>
          <SelectGrid display="flex">
            {SELECT_INPUTS.map(({ name, label }) => (
              <SelectTextField
                status={status}
                key={name}
                name={name}
                width={89}
                id={name}
                options={keywordLists}
                value={listSelected.id}
                onChange={handleChangeActiveList}
              />
            ))}
            <Button variant="contained" color="secondary">
              {t('heading.saveButton')}
            </Button>
          </SelectGrid>
        </Grid>
        <KeywordArea name="recommended">
          {isLoading ? (
            loadingSkeleton
          ) : (
            <RecommendedKeywords keywords={oksGenerated?.data || []} />
          )}
        </KeywordArea>
      </Container>
    </Card>
  );
}

export default OKSMetadata;
