import * as React from 'react';
import { withTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';

// Components
import Menu from 'components/Navbar/Menu';

// Styles
import { AppBar, IconButton } from './styled';

/**
 * @name Navbar
 * @description Default Navbar component
 * @param  {Object} props
 * @param  {Function} props.onDrawerToggle
 * @param  {Boolean} props.hideSidebarButton - hides Open drawer icon when the layout doens't have a Sidebar
 */
const Navbar = ({ onDrawerToggle, hideSidebarButton }) => {
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            {!hideSidebarButton && (
              <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item xs />
            <Grid item>
              <Menu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  onDrawerToggle: PropTypes.func,
  hideSidebarButton: PropTypes.bool
};

export default withTheme(Navbar);
