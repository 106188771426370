import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams, createSearchParams, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format, isFuture, isValid, parse } from 'date-fns';

// Constants
import { ACTIONS } from 'constants/KeywordTrackingTable';
import { IOS_VERSION } from 'constants/constants';

// Fetchers
import { API, CACHE_KEYS, ERROR, FETCH_STATE, GET_KEYWORDS_REQ_TYPE } from 'api/index';

// Utils
import { isEmpty, removeEmptyValues } from 'utils/utils';
import {
  KEYWORD_TRACKING_TABLE_FILTERS,
  KEYWORD_TRACKING_TABLE_COLUMNS_FILTER_TYPES,
  KEYWORD_TRACKING_TABLE_VISIBLE_COLUMNS
} from './config';
import {
  SELECT_FILTER_OPTIONS,
  DEFAULT_DENSITY,
  DEFAULT_SORT,
  DATE_STRING_FORMAT
} from './columns';
import filterMethods from 'utils/filterMethods';

// Reducers
import { KeywordTrackingTableReducer } from './reducer';
import useKeywordLists from 'hooks/useKeywordLists';

const SORT_BY_SEPARATOR = '_';

/**
 * @name formatSort
 * @description this function is to pass sort order to URL params. It also changes `name` type to `keywords` in order to
 * be more readable to the end user.
 * @param  {{field: String, sort: String}[]} sort
 * @return {String}
 */
const formatSort = (sort) => {
  const sortString = Object.values(sort[0]).join('_');
  const replacedSortString = sortString.replace(/^name_/, 'keywords_');

  return replacedSortString;
};

const initialState = {
  selected: [],
  page: '1',
  filters: KEYWORD_TRACKING_TABLE_FILTERS,
  sort: DEFAULT_SORT,
  keywords: [],
  visibleColumns: KEYWORD_TRACKING_TABLE_VISIBLE_COLUMNS,
  error: '',
  density: DEFAULT_DENSITY
};
const initialQueriesStatuses = {
  KEYWORD: FETCH_STATE.IDLE,
  RANK: FETCH_STATE.IDLE,
  POPULARITY: FETCH_STATE.IDLE,
  DOWNLOADS: FETCH_STATE.IDLE,
  TOTAL_APPS: FETCH_STATE.IDLE
};
const KeywordTrackingTableContext = createContext(initialState);

/**
 * @name KeywordTrackingTableProvider
 * @description Context for the keyword tracking table.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 * @param  {Object} props.mockState Custom state value. Useful for tests and storybook. Defaults to `null`.
 */
function KeywordTrackingTableProvider({ mockState = null, children }) {
  // If mockState was passed, set it as a initial state. Otherwise set initial state to empty object.
  const isMockState = !isEmpty(mockState);
  const [state, dispatch] = useReducer(
    KeywordTrackingTableReducer,
    isMockState ? mockState : initialState
  );

  // Check for query params in the URL.
  // this way users can share the URL with the filters and sort order applied.
  const [searchParams, setSearchParams] = useSearchParams();
  // todo: add listId to the URL params

  // get SORT
  // Set current sort
  let sort = state.sort;
  const sortInParams = searchParams.get('sort_by');
  if (sortInParams) {
    const splittedSort = sortInParams.split(SORT_BY_SEPARATOR);
    if (splittedSort.length >= 3) {
      // Remove last element form the `splittedSort`
      const sortOrder = splittedSort.pop();
      sort = [
        {
          field: splittedSort.join('_'),
          sort: sortOrder
        }
      ];
    } else {
      sort = [
        {
          field: splittedSort[0],
          sort: splittedSort[1]
        }
      ];
    }
  }

  const formattedSort = formatSort(sort);

  // get PAGE
  // Set current page
  const pageInSearchParams = searchParams.get('page');
  const page = pageInSearchParams || state.page;

  // get FILTERS
  // Format query params to filters object
  let queryFilters = {};
  searchParams.forEach((value, key) => {
    // We keep `page` in the separate variable
    if (key === 'page') {
      return null;
    }
    // We keep `sort_by` in the separate variable
    if (key === 'sort_by') {
      return null;
    }
    if (value === '0') {
      return null;
    }
    // If key is a select filter option, we check if the value
    // exists in the options for corresponding key. If the value
    // does not exist, we remove it from the params
    let newValue = value;

    // Let's start date object electric implosion
    if (key === 'date_range_start' || key === 'date_range_end') {
      // We need to parse `string` to date object
      newValue = parse(value, DATE_STRING_FORMAT, new Date());

      // If the `newValue` in the URL is not valid, remove it.
      if (!isValid(newValue)) {
        return null;
      }

      const isFutureDate = isFuture(newValue);

      // If the date is from the future, set it as a current date.
      if (isFutureDate) {
        newValue = new Date();
      }
    }
    // if the key and or value are not in the `SELECT_FILTER_OPTIONS` we ignore it.
    if (Object.keys(SELECT_FILTER_OPTIONS).includes(key)) {
      if (!SELECT_FILTER_OPTIONS[key].includes(value)) {
        return null;
      }
      // If the value is in the `SELECT_FILTER_OPTIONS` we are
      // wrapping it in the array.
      if (SELECT_FILTER_OPTIONS[key].includes(value)) {
        newValue = [value];
      }
    }

    // If the property already exist, it means that this is
    // an array type value (like it is a multiselect value).
    // Otherwise, our two select values would end up as one, the latter.
    if (queryFilters.hasOwnProperty(key) && queryFilters[key] !== '') {
      queryFilters[key] = [...queryFilters[key], value].flat();
      return;
    }
    queryFilters[key] = newValue;
  });

  // Next date checks - those are important to make sure that the `DateRangeFilter` is working properly.
  // If `date_range_start` is present, but there is no `date_range_end`
  // set `date_range_end` as today.
  if (queryFilters['date_range_start'] && !queryFilters['date_range_end']) {
    queryFilters['date_range_end'] = new Date();
  }

  // If `date_range_end` is present, but there is no `date_range_start`
  // remove `date_range_end` from the `queryFilters`.
  if (queryFilters['date_range_end'] && !queryFilters['date_range_start']) {
    delete queryFilters['date_range_end'];
  }

  /**
   * @name handleChangeDensity
   * @description Pass new density to the context, this is useful for
   * making changes to the columns based on the current density
   * @param  {String} density New density
   */
  const handleChangeDensity = (density) => {
    dispatch({
      type: ACTIONS.SET_DENSITY,
      payload: {
        density
      }
    });
  };
  const { projectId, appId, platform } = useParams();

  const {
    status: listsStatus,
    availableLists,
    listSelected,
    setListSelected,
    refetchLists
  } = useKeywordLists(projectId, appId, platform, isMockState);

  useEffect(() => {
    if (isMockState) {
      return;
    }
    dispatch({
      type: ACTIONS.RESET_KEYWORDS
    });
  }, [listSelected.id]);

  // queries statuses for table columns
  const [queriesStatuses, setQueriesStatuses] = useState(initialQueriesStatuses);

  // We are refetching these queries every time the:
  // -> `appId` is changed
  // -> `projectId` is changed
  // -> `listSelected.id` is changed
  // in order to always be in sync with the context state.

  // Query 1: typeRequest === 'keyword' provides columns:
  // name, type_id, type_name, relevancy_label (id), relevancy_name, competitors_count, relevancy_competitor
  const {
    isFetching: isFetchingKw,
    status: queryStatus,
    refetch: refetchKeywordsKw
  } = useQuery(
    [CACHE_KEYS.GET_KEYWORDS_KEYWORD, appId, projectId, listSelected.id],
    () => {
      setQueriesStatuses((prevState) => ({
        ...prevState,
        [GET_KEYWORDS_REQ_TYPE.KEYWORD]: FETCH_STATE.LOADING
      }));
      return API.getAppKeywords({
        projectId,
        appId,
        platform,
        iosVersion: IOS_VERSION,
        listId: listSelected.id
      });
    },
    {
      enabled: !isMockState && listSelected.id !== undefined,
      refetchOnWindowFocus: false,
      retry: true,
      keepPreviousData: true,
      initialData: {
        keywords: []
      },
      onSuccess: (responseData) => {
        dispatch({
          type: ACTIONS.SET_KEYWORDS,
          payload: {
            keywords: responseData.keywords
          }
        });
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.KEYWORD]: FETCH_STATE.SUCCESS
        }));
      },
      onError: (error) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.KEYWORD]: FETCH_STATE.ERROR
        }));
        if (error instanceof ERROR.ApiResponseNotFound) {
          const { errorData } = error;
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: errorData.detail
            }
          });
        }
      }
    }
  );

  // Query 2: typeRequest === 'popularity' provides columns:
  // popularity_score, popularity_change
  const {
    isFetching: isFetchingPop,
    status: queryStatusPop,
    refetch: refetchKeywordsPop
  } = useQuery(
    [CACHE_KEYS.GET_KEYWORDS_POPULARITY, appId, projectId, listSelected.id],
    () => {
      setQueriesStatuses((prevState) => ({
        ...prevState,
        [GET_KEYWORDS_REQ_TYPE.POPULARITY]: FETCH_STATE.LOADING
      }));
      return API.getAppKeywordsPopularity({
        projectId,
        appId,
        platform,
        iosVersion: IOS_VERSION,
        listId: listSelected.id
      });
    },
    {
      enabled: !isMockState && listSelected.id !== undefined,
      refetchOnWindowFocus: false,
      retry: true,
      keepPreviousData: true,
      onSuccess: (responseData) => {
        dispatch({
          type: ACTIONS.SET_KEYWORDS_POPULARITY,
          payload: {
            keywords: responseData.keywords
          }
        });
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.POPULARITY]: FETCH_STATE.SUCCESS
        }));
      },
      onError: (error) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.POPULARITY]: FETCH_STATE.ERROR
        }));
        if (error instanceof ERROR.ApiResponseNotFound) {
          const { errorData } = error;
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: errorData.detail
            }
          });
        }
      }
    }
  );

  // Query 3: typeRequest === 'rank' provides columns:
  // rank, rank_change
  const {
    isFetching: isFetchingRank,
    status: queryStatusRank,
    refetch: refetchKeywordsRank
  } = useQuery(
    [CACHE_KEYS.GET_KEYWORDS_RANK, appId, projectId, listSelected.id],
    () => {
      setQueriesStatuses((prevState) => ({
        ...prevState,
        [GET_KEYWORDS_REQ_TYPE.RANK]: FETCH_STATE.LOADING
      }));
      return API.getAppKeywordsRank({
        projectId,
        appId,
        platform,
        iosVersion: IOS_VERSION,
        listId: listSelected.id
      });
    },
    {
      enabled: !isMockState && listSelected.id !== undefined,
      refetchOnWindowFocus: false,
      retry: true,
      keepPreviousData: true,
      onSuccess: (responseData) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.RANK]: FETCH_STATE.SUCCESS
        }));
        dispatch({
          type: ACTIONS.SET_KEYWORDS_RANK,
          payload: {
            keywords: responseData.keywords
          }
        });
      },
      onError: (error) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.RANK]: FETCH_STATE.ERROR
        }));
        if (error instanceof ERROR.ApiResponseNotFound) {
          const { errorData } = error;
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: errorData.detail
            }
          });
        }
      }
    }
  );

  // Query 4: typeRequest === 'downloads' provides columns:
  // download_score, (download_change)
  const {
    isFetching: isFetchingDownloads,
    status: queryStatusDownloads,
    refetch: refetchKeywordsDownloads
  } = useQuery(
    [CACHE_KEYS.GET_KEYWORDS_DOWNLOADS, appId, projectId, listSelected.id],
    () => {
      setQueriesStatuses((prevState) => ({
        ...prevState,
        [GET_KEYWORDS_REQ_TYPE.DOWNLOADS]: FETCH_STATE.LOADING
      }));
      return API.getAppKeywordsDownloads({
        projectId,
        appId,
        platform,
        iosVersion: IOS_VERSION,
        listId: listSelected.id
      });
    },
    {
      enabled: !isMockState && listSelected.id !== undefined,
      refetchOnWindowFocus: false,
      retry: true,
      keepPreviousData: true,
      onSuccess: (responseData) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.DOWNLOADS]: FETCH_STATE.SUCCESS
        }));
        dispatch({
          type: ACTIONS.SET_KEYWORDS_DOWNLOADS,
          payload: {
            keywords: responseData.keywords
          }
        });
      },
      onError: (error) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.DOWNLOADS]: FETCH_STATE.ERROR
        }));
        if (error instanceof ERROR.ApiResponseNotFound) {
          const { errorData } = error;
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: errorData.detail
            }
          });
        }
      }
    }
  );

  // Query 5: typeRequest === 'total-apps' provides columns:
  // max_results
  const {
    isFetching: isFetchingTotalApps,
    status: queryStatusTotalApps,
    refetch: refetchKeywordsTotalApps
  } = useQuery(
    [CACHE_KEYS.GET_KEYWORDS_TOTAL_APPS, appId, projectId, listSelected.id],
    () => {
      setQueriesStatuses((prevState) => ({
        ...prevState,
        [GET_KEYWORDS_REQ_TYPE.TOTAL_APPS]: FETCH_STATE.LOADING
      }));
      return API.getAppKeywordsTotalApps({
        projectId,
        appId,
        platform,
        iosVersion: IOS_VERSION,
        listId: listSelected.id
      });
    },
    {
      enabled: !isMockState && listSelected.id !== undefined,
      refetchOnWindowFocus: false,
      retry: true,
      keepPreviousData: true,
      onSuccess: (responseData) => {
        dispatch({
          type: ACTIONS.SET_KEYWORDS_TOTAL_APPS,
          payload: {
            keywords: responseData.keywords
          }
        });
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.TOTAL_APPS]: FETCH_STATE.SUCCESS
        }));
      },
      onError: (error) => {
        setQueriesStatuses((prevState) => ({
          ...prevState,
          [GET_KEYWORDS_REQ_TYPE.TOTAL_APPS]: FETCH_STATE.ERROR
        }));
        if (error instanceof ERROR.ApiResponseNotFound) {
          const { errorData } = error;
          dispatch({
            type: ACTIONS.ERROR,
            payload: {
              error: errorData.detail
            }
          });
        }
      }
    }
  );

  // controls the loading state of the table
  const isTableFetching =
    isFetchingKw || isFetchingRank || isFetchingPop || isFetchingDownloads || isFetchingTotalApps;

  /**
   * @name handleDeleteKeywords
   * @description Delete keywords from the state
   * */
  const handleDeleteKeywords = (payload) => {
    dispatch({
      type: ACTIONS.DELETE_KEYWORDS,
      payload: {
        keywordIds: payload.keywordIds
      }
    });
  };

  /**
   * @name handleUpdateKeywords
   * @description Update keywords in the state
   * */
  const handleUpdateKeywords = (payload) => {
    dispatch({
      type: ACTIONS.UPDATE_KEYWORDS,
      payload: {
        keywordIds: payload.keywordIds,
        type: payload.type,
        type_name: payload.type_name,
        relevancy: payload.relevancy,
        relevancy_name: payload.relevancy_name
      }
    });
  };

  /**
   * @name handleSelected
   * @description Keep the selected keywords ids in the context.
   * @param  {Array} selected
   */
  const handleSelected = (selected) => {
    dispatch({
      type: ACTIONS.SET_SELECTED,
      payload: {
        selected
      }
    });
  };

  /**
   * @name handleColumnVisibilityChange
   * @param  {Record<string, Boolean>[]} visibleColumns keys are the column names, and the values are
   * the current visibility state - `true` is visible, `false` is hidden.
   */
  const handleColumnVisibilityChange = (visibleColumns) => {
    dispatch({
      type: ACTIONS.SET_COLUMNS_VISIBILITY,
      payload: {
        visibleColumns
      }
    });
  };

  /**
   * @name handleSortChange
   * @description Update sort in the state. We are also returning to the first page
   * @param  {{field: String, sort: 'asc' | 'desc'}[]} sort Current sort
   */
  const handleSortChange = (newSort) => {
    // We are updating the page in the url
    // but we are not removing the rest of the query params
    if (newSort.length === 0) {
      // If `newSort` is an empty array, that means we need to restore the default sort order.
      const updatedSort = [...sort];
      updatedSort[0].sort = 'asc';
      return handleSortChange(updatedSort);
    }
    setSearchParams(
      createSearchParams({
        ...removeEmptyValues(queryFilters),
        page: 1,
        sort_by: formatSort(newSort)
      })
    );

    dispatch({
      type: ACTIONS.SET_SORT_BY,
      payload: {
        sort: newSort,
        page: 1
      }
    });
  };

  /**
   * @name handlePageChange
   * @description Update page in the state
   * @param  {Number} page Current page
   */
  const handlePageChange = (page) => {
    const selectedPage = page + 1;

    // We are updating the page in the url
    // but we are not removing the rest of the query params
    setSearchParams(
      createSearchParams({
        ...removeEmptyValues(queryFilters),
        sort_by: formattedSort,
        page: selectedPage
      })
    );

    dispatch({
      type: ACTIONS.SET_PAGE,
      payload: {
        page: selectedPage
      }
    });
  };

  /**
   * @name handleFiltersSubmit
   * @description We are creating a copy of the passed filters, remove the empty properties
   * and we set them in the URL and in the state.
   * @param  {Object} submittedFilters
   */
  const handleFiltersSubmit = (submittedFilters) => {
    const filters = { ...removeEmptyValues(submittedFilters) };
    const query = { ...filters };

    dispatch({
      type: ACTIONS.SET_FILTERS,
      payload: {
        filters
      }
    });

    // We need to change default Date object to string. This happens twice
    // - here and in the `useEffect`.
    if (query['date_range_start']) {
      query['date_range_start'] = format(query['date_range_start'], DATE_STRING_FORMAT);
    }

    if (query['date_range_end']) {
      query['date_range_end'] = format(query['date_range_end'], DATE_STRING_FORMAT);
    }

    setSearchParams(
      createSearchParams({
        ...query,
        sort_by: formattedSort,
        page
      })
    );
  };

  /**
   * @name handleFiltersReset
   * @description Remove all queries except of the `page` query param.
   */
  const handleFiltersReset = () => {
    setSearchParams(
      createSearchParams({
        page,
        sort_by: formattedSort
      })
    );

    dispatch({
      type: ACTIONS.CLEAR_FILTERS,
      payload: {
        filters: KEYWORD_TRACKING_TABLE_FILTERS
      }
    });
  };

  // If there is no `page` or `sort_by` in searchParams, set one.
  useEffect(() => {
    const filters = { ...removeEmptyValues(queryFilters) };
    const query = { ...filters };

    dispatch({
      type: ACTIONS.SET_FILTERS,
      payload: {
        filters
      }
    });

    dispatch({
      type: ACTIONS.SET_SORT_BY,
      payload: {
        sort
      }
    });

    // We need to change default Date object to string. This happens twice
    // - here and in the `handleFiltersSubmit` function.
    if (query['date_range_start']) {
      query['date_range_start'] = format(query['date_range_start'], DATE_STRING_FORMAT);
    }

    if (query['date_range_end']) {
      query['date_range_end'] = format(query['date_range_end'], DATE_STRING_FORMAT);
    }

    setSearchParams(
      createSearchParams({
        ...query,
        sort_by: formattedSort,
        page
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create a memoized array of keywords (rows) that filters based on queryFilters
  const filteredRows = useMemo(() => {
    return state.keywords.filter((row) => {
      let match = true;
      // for each filter param contained in the queryFilters object
      for (let key in queryFilters) {
        // date range is for fetching data, not filtering
        if (key === 'date_range_start' || key === 'date_range_end') {
          continue;
        }
        // get filter type from column key
        const filterType = KEYWORD_TRACKING_TABLE_COLUMNS_FILTER_TYPES[key].filterType;
        const columnName = KEYWORD_TRACKING_TABLE_COLUMNS_FILTER_TYPES[key].propName;
        // get filter value from queryFilters
        const filterCondition = queryFilters[key];
        // check if the row matches the filter condition
        if (!filterMethods[filterType](row, columnName, filterCondition, key)) {
          match = false;
          // if a filter condition does not match, break out of the loop
          break;
        }
      }
      return match;
    });
  }, [queryFilters, state.keywords]);

  const refetchKeywords = async () => {
    // refetch all kws attributes
    await refetchKeywordsKw();
    await refetchKeywordsPop();
    await refetchKeywordsRank();
    await refetchKeywordsDownloads();
    await refetchKeywordsTotalApps();
  };

  return (
    <KeywordTrackingTableContext.Provider
      value={{
        ...state,
        filteredRows,
        queryFilters,
        queriesStatuses,
        isTableFetching,
        page,
        refetchKeywords,
        handleDeleteKeywords,
        handleUpdateKeywords,
        handlePageChange,
        handleSortChange,
        handleColumnVisibilityChange,
        handleFiltersReset,
        handleFiltersSubmit,
        handleChangeDensity,
        handleSelected,
        // keyword lists related returns
        listsStatus,
        availableLists,
        listSelected,
        setListSelected,
        refetchLists
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </KeywordTrackingTableContext.Provider>
  );
}

KeywordTrackingTableProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { KeywordTrackingTableContext, KeywordTrackingTableProvider };
