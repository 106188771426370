import React from 'react';
import List from '@mui/material/List';

// Components
import SidebarNavSection from 'components/Sidebar/NavSection';

// Styles
import { Items } from './styled';

function Navigation({ pages }) {
  return (
    <List disablePadding>
      <Items>
        <SidebarNavSection component="div" pages={pages} />
      </Items>
    </List>
  );
}

export default Navigation;
