import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './i18n';

// Theme
import createTheme from './theme';

// Constants
import routes from './routes';

// Hooks
import useTheme from 'hooks/useTheme';

// Utils
import createEmotionCache from 'utils/createEmotionCache';

// Contexts
import { AuthProvider } from 'contexts/Auth';
import AxiosProvider from 'contexts/Axios';
import { AlertProvider } from 'contexts/Alert';

// Components
import ErrorBoundary from 'components/ErrorBoundary';
import GlobalLoader from 'components/GlobalLoader';

const clientSideEmotionCache = createEmotionCache();
const queryClient = new QueryClient();

/**
 * @name App
 * @description Main app endpoint
 * @param  {object} props
 * @param  {object} props.emotionCache defaults to clientSideEmotionCache
 */
function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const { theme } = useTheme();
  const { t } = useTranslation('components', {
    keyPrefix: 'App'
  });

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={emotionCache}>
        <HelmetProvider>
          <Helmet titleTemplate="%s | Toaster" defaultTitle={t('toaster_default_title')} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <AlertProvider>
                <AuthProvider>
                  <AxiosProvider>
                    <Suspense fallback={<GlobalLoader />}>
                      <ErrorBoundary location="app">{content}</ErrorBoundary>
                    </Suspense>
                  </AxiosProvider>
                </AuthProvider>
              </AlertProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </CacheProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

App.propTypes = {
  emotionCache: PropTypes.object
};

export default App;
