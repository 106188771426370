import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';
import MuiTypography from '@mui/material/Typography';

// Colors
import { customGray } from 'theme/colors';

export const Typography = styled(MuiTypography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;
`;

export const Box = styled((props) => <MuiBox component="span" {...props} />)``;

export const KPI = styled(Box)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 10px;
`;

export const Row = styled(MuiGrid)`
  height: 22px;
  padding: 4px;
`;

export const Translation = styled(({ isActive, isMissing, ...props }) => <Row {...props} />)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background-color: #f0f0f0;

  border: ${({ isActive, theme }) =>
    `1px solid ${isActive ? theme.palette.primary.light : '#f0f0f0'}`};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  & .MuiTypography-root {
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.palette.primary.light};
    `};
  ${({ isMissing }) =>
    isMissing &&
    css`
      padding: 0;
    `}
`;

export const Keyword = styled(({ isActive, ...props }) => <Row {...props} />)`
  background-color: ${({ isActive, theme }) => (isActive ? theme.palette.primary.main : 'white')};
  border: ${({ isActive, theme }) =>
    `1px solid ${isActive ? theme.palette.primary.main : '#f0f0f0'}`};
  flex-wrap: nowrap;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  & .MuiTypography-root {
    font-weight: ${({ theme, isActive }) =>
      isActive ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular};
  }
`;
export const Wrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Grid = styled(MuiGrid)`
  width: fit-content;
  height: fit-content;

  :hover {
    cursor: pointer;
  }
`;

export const Container = styled(MuiGrid)`
  white-space: nowrap;
`;
