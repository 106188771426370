import { useContext } from 'react';
// Contexts
import { KeywordTrackingTableContext } from 'contexts/KeywordTrackingTable';

const useKeywordTrackingTable = () => {
  const context = useContext(KeywordTrackingTableContext);

  if (!context)
    throw new Error(
      'KeywordTrackingTableContext must be placed within KeywordTrackingTableProvider'
    );

  return context;
};

export default useKeywordTrackingTable;
