import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import LoaderLayout from 'layouts/Loader';

/**
 * @name GlobalLoader
 * @description Global loader for the application
 */
function GlobalLoader() {
  return (
    <LoaderLayout>
      <CircularProgress color="secondary" />
    </LoaderLayout>
  );
}

export default GlobalLoader;
