const getLocalStorageRefreshToken = () => {
  const item = localStorage.getItem('refresh_token');
  if (item !== 'undefined' && item !== null) {
    const refreshToken = JSON.parse(item);
    return refreshToken;
  }

  return '';
};

const getSessionStorageRefreshToken = () => {
  const item = sessionStorage.getItem('refresh_token');
  if (item !== 'undefined' && item !== null) {
    const refreshToken = JSON.parse(item);
    return refreshToken;
  }

  return '';
};

const getLocalStorageAccessToken = () => {
  const item = localStorage.getItem('access_token');
  if (item !== 'undefined' && item !== null) {
    const refreshToken = JSON.parse(item);
    return refreshToken;
  }

  return '';
};

const getSessionStorageAccessToken = () => {
  const item = sessionStorage.getItem('access_token');
  if (item !== 'undefined' && item !== null) {
    const refreshToken = JSON.parse(item);
    return refreshToken;
  }

  return '';
};

const getLocalStorageUser = () => {
  const item = localStorage.getItem('user');
  if (item !== 'undefined' && item !== null) {
    const refreshToken = JSON.parse(item);
    return refreshToken;
  }

  return {};
};

const getSessionStorageUser = () => {
  const item = sessionStorage.getItem('user');
  if (item !== 'undefined' && item !== null) {
    const refreshToken = JSON.parse(item);
    return refreshToken;
  }

  return {};
};

const setLocalStorageAccessToken = (accessToken) => {
  localStorage.setItem('access_token', JSON.stringify(accessToken));
};

const setSessionStorageAccessToken = (accessToken) => {
  sessionStorage.setItem('access_token', JSON.stringify(accessToken));
};

const setLocalStorageRefreshToken = (refreshToken) => {
  localStorage.setItem('refresh_token', JSON.stringify(refreshToken));
};

const setSessionStorageRefreshToken = (refreshToken) => {
  sessionStorage.setItem('refresh_token', JSON.stringify(refreshToken));
};

const setLocalStorageUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const setSessionStorageUser = (user) => {
  sessionStorage.setItem('user', JSON.stringify(user));
};

/**
 * @name setLocalStorageData
 * @description Sets provided data to the localstorage
 * @param  {object} data
 * @param  {object} data.user
 * @param  {string} data.refreshToken
 * @param  {string} data.accessToken
 */
const setLocalStorageData = ({ user, refreshToken, accessToken }) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('refresh_token', JSON.stringify(refreshToken));
  localStorage.setItem('access_token', JSON.stringify(accessToken));
};

/**
 * @name setSessionStorageData
 * @description Sets provided data to the sessionStorage
 * @param  {object} data
 * @param  {object} data.user
 * @param  {string} data.refreshToken
 * @param  {string} data.accessToken
 */
const setSessionStorageData = ({ user, refreshToken, accessToken }) => {
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('refresh_token', JSON.stringify(refreshToken));
  sessionStorage.setItem('access_token', JSON.stringify(accessToken));
};

/**
 * @name removeLocalStorageData
 * @description Removes `user`, `refresh_token` and `access_token` from the localstorage
 */
const removeLocalStorageData = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
};

/**
 * @name removeSessionStorageData
 * @description Removes `user`, `refresh_token` and `access_token` from the sessionstorage
 */
const removeSessionStorageData = () => {
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('access_token');
};

const setLocalStorageLastProjectId = (project) => {
  localStorage.setItem('lastProjectId', JSON.stringify(project));
};

const getLocalStorageLastProjectId = () => {
  const item = localStorage.getItem('lastProjectId');
  if (item !== 'undefined' && item !== null) {
    return JSON.parse(item);
  }

  return null;
};
/**
 * @name removeLocalStorageLastProjectId
 * @description Removes `lastProjectId` from the localStorage
 */
const removeLocalStorageLastProjectId = () => {
  localStorage.removeItem('lastProjectId');
};

const StorageService = {
  getLocalStorageAccessToken,
  getSessionStorageAccessToken,
  setLocalStorageAccessToken,
  setSessionStorageAccessToken,

  getLocalStorageRefreshToken,
  getSessionStorageRefreshToken,
  setLocalStorageRefreshToken,
  setSessionStorageRefreshToken,

  getLocalStorageUser,
  getSessionStorageUser,
  setLocalStorageUser,
  setSessionStorageUser,

  setLocalStorageData,
  setSessionStorageData,
  removeLocalStorageData,
  removeSessionStorageData,

  getLocalStorageLastProjectId,
  setLocalStorageLastProjectId,
  removeLocalStorageLastProjectId
};

export default StorageService;
