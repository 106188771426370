import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import { Footer, FooterBadge, LogoutButton, Avatar } from './styled';
import { URLS } from 'constants/URL';

const SidebarFooter = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation('components', {
    keyPrefix: 'Menu'
  });

  const handleSignOut = () => {
    signOut();
  };

  const handleClick = () => {
    navigate(URLS.USER_SETTINGS);
  };

  return (
    <Footer>
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <FooterBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          variant="dot"
        >
          {!!user && (
            <Avatar onClick={handleClick} alt={user.username} src={'https://picsum.photos/30/30'} />
          )}
        </FooterBadge>
        <Grid>
          <LogoutButton onClick={() => handleSignOut()}>{t('log_out')}</LogoutButton>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
