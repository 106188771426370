import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// Constants
import { FETCH_STATE } from 'api';

/**
 * @name SelectCustom
 * @description custom select component, reusable
 * has error and loading state
 * @param id {string}
 * @param label {string}
 * @param width {string} default '150px'
 * @param value {[string, number]}
 * @param options {array}
 * @param onChange {function}
 * @param disabled {boolean}
 * @param renderValue {func}
 * @param className {string}
 * @param multiple {boolean}
 * @param status {string} default FETCH_STATE.SUCCESS
 * */

function SelectCustom({
  id,
  label,
  width = '150px',
  value,
  options,
  onChange,
  disabled,
  renderValue,
  className,
  multiple = false,
  status = FETCH_STATE.SUCCESS,
  variant = 'outlined',
  displayEmpty = false
}) {
  // loading state
  if (status === FETCH_STATE.LOADING) {
    return (
      <React.Fragment>
        <Skeleton width={150} />
      </React.Fragment>
    );
  }

  // error state
  if (status === FETCH_STATE.ERROR) {
    return (
      <FormControl sx={{ width: '150px' }} size="small" error disabled>
        <InputLabel color="secondary" id={`${id}select-label`}>
          <ErrorOutline />
        </InputLabel>
        <MuiSelect sx={{ paddingTop: '5px' }} />
      </FormControl>
    );
  }
  return (
    <FormControl className={className} sx={{ width: width }} size="small" key={id}>
      <InputLabel color="secondary" id={`${id}select-label`}>
        {label}
      </InputLabel>
      <MuiSelect
        variant={variant}
        sx={{ paddingTop: '5px' }}
        labelId={`${id}select-label`}
        id={`${id}select`}
        size={'small'}
        color="secondary"
        value={value}
        label={label}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        renderValue={renderValue}
        displayEmpty={displayEmpty}
      >
        {options?.map(({ label, value }) => (
          <MenuItem key={value} value={value} data-testid={`testid-${value}`}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

SelectCustom.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
    })
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  renderValue: PropTypes.func,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  status: PropTypes.string
};

export default SelectCustom;
