import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// Reducers
import { OKSReducer } from 'contexts/OKS/reducer';

// Constants
import { FILTER_OPTIONS } from 'constants/OKS';

const OKSContext = createContext({});

export function getInitialState() {
  const competitors = localStorage.getItem('competitors');
  return {
    page: '1',
    count: 0,
    error: '',
    filterKeyword: '',
    filterByGroup: FILTER_OPTIONS.ALL,
    translateKeywords: true,
    recommendedKeyword: null,
    competitors: competitors ? JSON.parse(competitors) : []
  };
}

/**
 * OKS Context provider
 */
function OKSProvider({ children, mockState = null }) {
  const [state, dispatch] = useReducer(OKSReducer, mockState ? mockState : getInitialState());

  useEffect(() => {
    localStorage.setItem('competitors', JSON.stringify(state.competitors));
  }, [state.competitors]);

  return (
    <OKSContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </OKSContext.Provider>
  );
}

OKSProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { OKSContext, OKSProvider };
