import styled from '@emotion/styled';
import MuiIconButton from '@mui/material/IconButton';

export const IconButton = styled(MuiIconButton)`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  &&:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
