import i18n from 'i18next';

// Components
import KeywordCell from 'pages/app/Application/OKS/Table/KeywordCell';
import CompetitorColumnCell from 'pages/app/Application/OKS/Table/CompetitorColumnCell';
import CompetitorRowCell from 'pages/app/Application/OKS/Table/CompetitorRowCell';

// Constants
import { FILTER_OPTIONS } from 'constants/OKS';

// Utils
import { missingDataFallback, renderKWRankWithFallback } from 'utils/utils';

const translationPath = 'components:OptimalKeywordsPage.tableSection.tableColumns';
/**
 * Returns the list of columns for the OKS table, including currently selected competitors
 *
 * @param competitors        List of selected competitors
 * @param translateKeywords  Show translation
 */
export const getOKSTableColumns = (competitors, translateKeywords) => [
  {
    field: 'oks_position',
    headerName: i18n.t(`${translationPath}.position`),
    valueGetter: ({ row }) => row?.oks_position ?? row?.potential_score
  },
  {
    field: 'name',
    headerName: i18n.t(`${translationPath}.keywords`),
    minWidth: 200,
    maxWidth: 400,
    renderCell: ({ row }) => (
      <KeywordCell
        keyword={row.name}
        translation={row.en_translation}
        showTranslation={translateKeywords}
      />
    )
  },

  {
    // Invented
    field: 'competitor_rank',
    headerName: i18n.t(`${translationPath}.competitors`),
    renderCell: ({ row }) => `${row.relevancy_competitor || 0}/${row.competitors_count || 0}`
  },
  {
    field: 'rank',
    headerName: i18n.t(`${translationPath}.rank`),
    valueGetter: ({ row }) => renderKWRankWithFallback(row.rank ?? null)
  },
  {
    field: 'popularity_score',
    headerName: i18n.t(`${translationPath}.SP`),
    valueGetter: ({ row }) =>
      missingDataFallback(row.popularity_score ?? row.search_popularity ?? null)
  },
  {
    field: 'length',
    align: 'center',
    headerName: i18n.t(`${translationPath}.length`),
    renderCell: ({ row }) => Number(row.name.length)
  },
  ...competitors.map((comp) => ({
    field: String(comp?.app_localised_id),
    description: comp?.name,
    renderHeader: () => <CompetitorColumnCell name={comp?.name} icon={comp?.icon} />,
    renderCell: ({ field, row }) => (
      <CompetitorRowCell value={row[field]} isLoading={row.isLoading} />
    )
  }))
];

/**
 * Filter function to check if a keyword or it's translation is included in the filter query.
 * Accepts translateKeywords prop to disregard the translation.
 *
 * @param keyword             Keyword
 * @param filter              Filter query
 * @param translateKeywords   Determines whether the translation should be checked
 */
export const filterKeywordByNameAndTranslation = (keyword, filter, translateKeywords) =>
  keyword?.name?.toUpperCase().includes(filter?.toUpperCase()) ||
  (translateKeywords && keyword?.en_translation?.toUpperCase().includes(filter?.toUpperCase()));

/**
 * Returns the list of rows for the OKS table, depending on the filterByGroup and filterKeyword.
 * TODO - add detailed competitor data to rows
 *
 * @param competitors         List of competitors
 * @param keywords            Keyword set
 * @param filterKeyword       Filter query string
 * @param filterByGroup       Filtering group
 * @param translateKeywords   Whether to filter by translation
 * @param title               App title
 * @param subtitle            App subtitle
 */
export const getOKSTableRows = (
  competitors,
  keywords,
  filterKeyword,
  filterByGroup,
  translateKeywords,
  title,
  subtitle
) => {
  const keywordGroup = getKeywordGroup(
    keywords,
    filterKeyword,
    filterByGroup,
    translateKeywords,
    title,
    subtitle
  );

  return keywordGroup;
};

/**
 * Returns a list of keywords filtered  by filter group and filter keyword.
 *
 * @param keywords            Keyword set
 * @param filterKeyword       Filter query string
 * @param filterByGroup       Filtering group
 * @param translateKeywords   Whether to filter by translation
 * @param title               App title
 * @param subtitle            App subtitle
 */
export const getKeywordGroup = (
  keywords,
  filterKeyword,
  filterByGroup,
  translateKeywords,
  title,
  subtitle
) => {
  switch (filterByGroup) {
    // Return keywords by type_name, filtered by filter query
    case FILTER_OPTIONS.UNUSED:
    case FILTER_OPTIONS.BRAND:
    case FILTER_OPTIONS.COMPETITOR:
      return keywords?.filter(
        (kw) =>
          kw.type_name === filterByGroup &&
          filterKeywordByNameAndTranslation(kw, filterKeyword, translateKeywords)
      );

    // Return keywords contained in the title or subtitle, filtered by filter query
    case FILTER_OPTIONS.TITLE:
      return keywords?.filter(
        (kw) =>
          (title.toUpperCase().includes(kw.name.toUpperCase()) ||
            subtitle.toUpperCase().includes(kw.name.toUpperCase())) &&
          filterKeywordByNameAndTranslation(kw, filterKeyword, translateKeywords)
      );

    //   Return all keywords, filtered by filter query
    default:
      return keywords?.filter((kw) =>
        filterKeywordByNameAndTranslation(kw, filterKeyword, translateKeywords)
      );
  }
};
