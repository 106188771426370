import styled from '@emotion/styled';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiDivider from '@mui/material/Divider';
import MuiButton from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MuiTextField from '@mui/material/TextField';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import spacing from '@mui/system/spacing';

export const Divider = styled(MuiDivider)(spacing);
export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Card = styled(MuiCard)(spacing);
export const TextField = styled(MuiTextField)(spacing);
export const Button = styled(MuiButton)(spacing);
export const LoadingButton = styled(MuiLoadingButton)(spacing);

export const ButtonsWrapper = styled(Grid)`
  & > button + button {
    margin-left: ${(props) => props.theme.spacing(2)};
  }
`;
