import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Constants
import { PROJECT_ROLES } from 'constants/constants';

// Fetchers
import { API, ERROR, FETCH_STATE } from 'api';

// Components
import ButtonModal from 'components/ButtonModal';
import Loader from 'components/Loader';

// Hooks
import useProject from 'hooks/useProject';
import useAlert from 'hooks/useAlert';

// Styles
import { TextField, Alert, SharedUsersListWrapper, SharedUsersWrapper } from './styled';

/**
 * @name SharedUsersList
 * @description Display list of project's shared users
 * @param  {Object} props
 * @param  {Boolean} props.noOverflow If this is passed, then the `SharedUsersList` will
 * not have a scrollbar displayed. Useful, when trying to display SharingCenter inside
 * smaller container (like modal).
 */
function SharedUsersList({ noOverflow }) {
  const { t } = useTranslation('shared', {
    keyPrefix: 'SharingCenter.SharedUsersList'
  });
  const { project, updateShareUser, removeShareUser } = useProject();
  const { setAlert } = useAlert();
  const [sharedStatus, setSharedUserStatus] = useState({
    userId: null,
    status: 'idle'
  });

  /**
   * handles project's shared user update through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const { status: updateSharedUserStatus, mutateAsync: updateSharedUser } = useMutation(
    ({ params, body }) => {
      return API.updateSharedUser({ projectId: project.id, ...params }, body);
    },
    {
      onSuccess: async (responseData, mutationData) => {
        const { userId } = mutationData.params;
        const { userRoleId } = mutationData.body;
        // Update user locally
        updateShareUser(userId, userRoleId);
        // Display alert
        setAlert(t('user_updated_successfully'));
      }
    }
  );

  /**
   * handles the project's shared user removal through API. It provides `mutate` function
   * which needs `mutationData` to be passed to it. Mutation data is the same as the
   * arguments that API function expects.
   */
  const { status: deleteSharedUserStatus, mutateAsync: deleteSharedUser } = useMutation(
    (mutationData) => {
      return API.deleteSharedUser({ projectId: project.id, ...mutationData });
    },
    {
      onSuccess: async (responseData, mutationData) => {
        const { userId } = mutationData;
        // Remove shared user locally
        removeShareUser(userId);
        // Disable loading indicator
        setSharedUserStatus({ userId, status: FETCH_STATE.SUCCESS });
        // Display alert
        setAlert(t('user_removed_successfully'));
      },
      onError: (error, mutationData) => {
        const { userId } = mutationData;
        setSharedUserStatus({ userId, status: FETCH_STATE.ERROR });
      }
    }
  );

  const disableActionButtons = [deleteSharedUserStatus, updateSharedUserStatus].some(
    (el) => el === FETCH_STATE.LOADING
  );

  /**
   * @name handleRemoveSharedUser
   * @description Calls api to remove project's shared user
   * @param  {String} userId
   */
  const handleRemoveSharedUser = async (userId) => {
    setSharedUserStatus({ userId, status: FETCH_STATE.LOADING });
    await deleteSharedUser({ userId });
  };

  /**
   * @name handleOnSubmit
   * @description manages Formik submit function
   */
  const handleOnSubmit = async (values, { errors, setErrors, setStatus, setSubmitting }) => {
    try {
      await updateSharedUser({
        params: {
          userId: values.userId
        },
        body: {
          userEmail: values.userEmail,
          userRoleId: values.userRoleId
        }
      });
      setStatus({ success: true });
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('user_update_something_went_wrong');
        tempErrors = { submit: [message] };
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  // If there are no project's shared users, only display alert
  if (project?.project_users?.length === 0) {
    return (
      <Alert my={2} severity="info">
        {t('project_not_shared')}
      </Alert>
    );
  }

  return (
    <SharedUsersListWrapper noOverflow={noOverflow}>
      {project?.project_users?.map((person) => {
        const { user_id: userId, user_role_id: userRoleId, user_email: userEmail } = person;
        const isRemovingSharedUser =
          sharedStatus.userId === person.user_id && sharedStatus.status === 'loading';

        return (
          <Formik
            key={person.user_id}
            initialValues={{
              userId,
              userRoleId,
              userEmail
            }}
            onSubmit={handleOnSubmit}
          >
            {({ handleSubmit, handleChange, handleBlur, values, isSubmitting, dirty }) => (
              <SharedUsersWrapper key={userId}>
                <form onSubmit={handleSubmit} id={`form-${userId}`}>
                  <TextField
                    disabled
                    type="email"
                    name="userEmail"
                    value={values.userEmail}
                    variant="standard"
                    aria-readonly="true"
                    size="small"
                    hiddenLabel
                  />
                  <FormControl>
                    <Select
                      labelId="user-role-id-label"
                      name="userRoleId"
                      id="userRoleId"
                      label={t('role')}
                      value={values.userRoleId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      variant="standard"
                    >
                      {PROJECT_ROLES.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    type="submit"
                    disabled={userRoleId === values.userRoleId || disableActionButtons}
                  >
                    {isSubmitting ? <Loader size={22} /> : <SaveIcon />}
                  </Button>
                  <ButtonModal
                    openModalButtonText={<DeleteIcon />}
                    color="warning"
                    title={t('are_you_sure')}
                    isLoading={isSubmitting}
                    disabled={disableActionButtons}
                    actionButton={
                      <LoadingButton
                        loading={isRemovingSharedUser}
                        role="button"
                        disabled={isRemovingSharedUser}
                        onClick={() => handleRemoveSharedUser(userId)}
                        variant="contained"
                        color="primary"
                        autoFocus
                      >
                        {t('remove_user')}
                      </LoadingButton>
                    }
                    body={
                      <Trans i18nKey="you_will_remove_user">
                        You will remove <b>{{ userEmail }}</b> from this project
                      </Trans>
                    }
                  />
                </form>
              </SharedUsersWrapper>
            )}
          </Formik>
        );
      })}
    </SharedUsersListWrapper>
  );
}

SharedUsersList.propTypes = {
  noOverflow: PropTypes.bool
};

export default SharedUsersList;
