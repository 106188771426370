import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/Loader';

/**
 * @name SuspenseWrapper
 * @description Wrapper for the React.Suspense element
 * @param  {React.ReactNode} children
 */
function SuspenseWrapper({ children }) {
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
}

SuspenseWrapper.propTypes = {
  children: PropTypes.node
};

export default SuspenseWrapper;
