import { useQuery } from 'react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getCompetitors } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

/**
 * Query function that returns the competitors
 *
 * @param projectId   Project id
 * @param appId       App id
 * @param platform    App platform
 */
function useCompetitorsQuery({ projectId, appId, platform }: Props) {
  return useQuery({
    queryKey: [CACHE_KEYS.COMPETITORS, { projectId, appId, platform }],
    queryFn: () => getCompetitors({ projectId, appId, platform }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });
}

export default useCompetitorsQuery;
