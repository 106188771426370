import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Renders a collapsable detatil/accordion element
 *
 * @param {string | ReactNode} details   - Details text
 * @param {string | ReactNode} summary   - Summary text
 * @param {string}  className            - Classname
 */
function Details({ details, summary, className }) {
  return (
    <Accordion className={className}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{details}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{summary}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default Details;

Details.propTypes = {
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string
};
