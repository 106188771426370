import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import MuiTextField from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import spacing from '@mui/system/spacing';

export const Button = styled(MuiButton)(spacing);

export const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export const Alert = styled(MuiAlert)(spacing);

export const TextField = styled(MuiTextField)(spacing);

export const Form = styled.form`
  max-width: 327px;
`;
