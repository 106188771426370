import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

/**
 * Configurations for internationalization.
 * We are using the Backend plugin to load translations from public folder using http.
 * Alternatively create a resources object with the import of the translations files
 * and add them to the init 'resources'.
 *
 * Translations are stored in:
 * public/locales/<lang_code>/<translation_name> eg shared.json
 * we can have three main files (nameSpaces)
 * COMMON - for universal translations eg error messages / cancel, close etc. Those translations are not allocated to the specific component or page.
 * SHARED - for reusable components inside shared folder. Each component is an object which can be nested following the sub-component structure.
 * PAGES - for page components, inside pages folder. Each page is an object ii eg: SignUp, Dashboard, ResetPassword etc.
 * COMPONENTS - for all components inside components folder eg GoogleButton, Footer etc. these can be nested following the sub-component structure eg
 *
 * Component/page translation nesting example:
 * Sidebar {
 *   sidebar_title: 'This is a title',
 *   SidebarFooter: {
 *     contact_support: 'Contact support',
 *     jobs: 'Job offers'
 *   }
 * }
 * when not using keyPrefix, separate the namespace with : eg shared:email_address
 * To extract new translations we use i18next-parser.
 * This tool scans the project and adds any missing keys to the specified json file
 * */

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: 'en',
    // String or array of namespaces to load (if we use multiple files, list them here).
    // If not specified default is 'translation'
    ns: ['common', 'shared', 'components', 'pages'],
    fallbackLng: 'en',
    debug: process.env.I18N_DEBUG === 'true',
    interpolation: {
      escapeValue: false
    }
  });
