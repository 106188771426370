import { useContext } from 'react';

// Contexts
import { ProjectContext } from 'contexts/Project';

const useProject = () => {
  const context = useContext(ProjectContext);

  if (!context) throw new Error('ProjectContext must be placed within ProjectProvider');

  return context;
};

export default useProject;
