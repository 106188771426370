import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';

const DEFAULT_ALERT_TIMEOUT = 6000;
const AlertContext = createContext();

/**
 * @name AlertProvider
 * @description Context for alert. This will allow to call a snackbar from anywhere inside the App.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 */
function AlertProvider({ children }) {
  const [alertPack, setAlertPack] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (alertPack.length && !messageInfo) {
      // Set a new alert when we don't have an active one
      setMessageInfo({ ...alertPack[0] });
      setAlertPack((prev) => prev.slice(1));
      setIsOpen(true);
    } else if (alertPack.length && messageInfo && isOpen) {
      // Close an active snack when a new one is added
      setIsOpen(false);
    }
  }, [alertPack, messageInfo, isOpen]);

  /**
   * @name setAlert
   * @description this will trigger alert display
   * @param  {String} message
   */
  const setAlert = (message) => {
    if (!message) {
      return;
    }
    setAlertPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  /**
   * @name handleClose
   * @description handles snackbar close
   * @param  {Event} event
   * @param  {String} reason
   */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  /**
   * @name handleExited
   * @description this function removes current `messageInfo` after alert unmounts
   */
  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <AlertContext.Provider
      value={{
        setAlert
      }}
    >
      {/* The main application */}
      {children}
      {/* Alert */}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={isOpen}
        autoHideDuration={DEFAULT_ALERT_TIMEOUT}
        onClose={handleClose}
        message={messageInfo ? messageInfo.message : undefined}
        TransitionProps={{
          onExited: handleExited
        }}
      />
    </AlertContext.Provider>
  );
}

AlertProvider.propTypes = {
  children: PropTypes.node,
  mockState: PropTypes.object
};

export { AlertContext, AlertProvider };
