import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';

// Components
import Button from 'components/AddButton';

export const CompetitorContainer = styled(Grid)`
  & .MuiGrid-root:not(:first-of-type) {
    margin-left: 12px;
  }
`;

export const AddButton = styled(Button)`
  border: 1px solid #d3d3d3;
  height: 48px;
  width: 48px;
  border-radius: 10px;
`;
