import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

// Styles
import {
  Typography,
  FilterWrapper,
  SelectFilterInput
} from 'pages/app/Application/KeywordTrackingTable/styled';

/**
 * @name SelectFilter
 * @description Filter by one or multiple elements from the provided options.
 * @param  {Object} props
 * @param  {String} props.label Header name of the corresponding column for this filter.
 * @param  {String} props.name Name of the corresponding column for this filter.
 * @param  {Function} props.onChange Function that sets up the filter for this component
 * @param  {Function} props.onBlur Formik onBlur handle.
 * @param  {String[] | String} props.value Current filter value. If `multiple` is passed, then it needs to be an `Array` of numbers.
 * `String` is passed only if the value is not yet defined.
 * @param  {'small' | 'medium' | 'large'} props.size Size of the input. Defaults to 'small'
 * @param  {Object[] | String[]} props.options Options to be displayed for the select component
 * @param  {Number} props.options.value Value of the single option if the `options` array is an array of objects.
 * @param  {String} props.options.label Display label of the single option if the `options` array is an array of objects.
 * @param  {String} props.multiple Decide if the `SelectFilter` should support multiple selection. Defaults to `false`.
 * @param  {String} props.width Current input width
 */
function SelectFilter({
  options,
  size,
  width,
  label,
  onChange,
  onBlur,
  name,
  value,
  multiple = false
}) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable'
  });
  return (
    <FilterWrapper sx={{ width }}>
      <FormControl fullWidth>
        <Typography>
          <label htmlFor={name}>{label}</label>
          {/* TODO: use InputLabel */}
        </Typography>
        <SelectFilterInput
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          size={size}
          multiple={multiple}
          value={value}
          displayEmpty
          data-testid={`filter-testid-${name}`}
          renderValue={(selected) => {
            // Default to `All` if the filter is not present.
            const defaultValue = <em>{t('SelectFilter.all')}</em>;
            if (selected === undefined) {
              return defaultValue;
            }

            if (selected === '') {
              return defaultValue;
            }
            // Default to `All` if the filter is not present.
            if (selected.length === 0) {
              return defaultValue;
            }

            if (multiple) {
              return selected.join(', ');
            }

            // Take selected `value` number and map it to the corresponding `options.label`.
            return selected;
          }}
        >
          <MenuItem disabled={multiple} value="">
            <em>{t('SelectFilter.all')}</em>
          </MenuItem>
          {options.map((option) => {
            // Check if `options` array is an array of objects
            if (typeof option === 'object' && option !== null) {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            }
            // Else `options` is an array of strings
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </SelectFilterInput>
      </FormControl>
    </FilterWrapper>
  );
}

SelectFilter.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired
  ]).isRequired,
  // `options` can be either an array of object with `value` and `label` properties
  // or an array of strings
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
      }).isRequired
    ),
    PropTypes.arrayOf(PropTypes.string).isRequired
  ]).isRequired,
  multiple: PropTypes.bool,
  width: PropTypes.number
};

export default SelectFilter;
