import React from 'react';
import { matchPath } from 'react-router-dom';

// Components
import SidebarNavListItem from './NavListItem';
import SidebarNavList from './NavList';

const reduceChildRoutes = ({ items, page, depth, currentRoute }) => {
  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false
          },
          currentRoute
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        type={page.type}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        type={page.type}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
