import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { Alert, TextField, Form, Button } from './styled';
const CreateProjectSchema = () =>
  Yup.object().shape({
    name: Yup.string().max(255).required(i18n.t('common:name_required'))
  });

/**
 * @name CreateProjectForm
 * @description This is a CreateProject form
 * @param  {object} props
 * @param  {function} props.handleOnSubmit
 */
function CreateProjectForm({ handleOnSubmit }) {
  // We do not specify keyPrefix, as we use multiple namespaces
  const { t } = useTranslation();
  const memoCreateProjectSchema = useMemo(() => CreateProjectSchema(), []);
  const { handleSubmit, errors, values, touched, handleBlur, handleChange, isSubmitting } =
    useFormik({
      initialValues: {
        name: '',
        reporting: false,
        submit: false
      },
      validationSchema: memoCreateProjectSchema,
      onSubmit: handleOnSubmit
    });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {/* create project form and api error */}
      {errors.submit && (
        <Alert mt={2} mb={1} severity="warning">
          {errors.submit}
        </Alert>
      )}
      {errors.detail && (
        <Alert mt={2} mb={1} severity="warning">
          {errors.detail}
        </Alert>
      )}
      <TextField
        type="text"
        name="name"
        label={t('common:name')}
        value={values.name}
        error={Boolean(touched.name && errors.name)}
        fullWidth
        helperText={touched.name && errors.name}
        onBlur={handleBlur}
        onChange={handleChange}
        my={2}
        disabled={isSubmitting}
      />
      <FormControlLabel
        control={
          <Checkbox
            name="reporting"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.reporting}
            color="primary"
            disabled={isSubmitting}
          />
        }
        label={t('components:CreateProject.reporting')}
      />
      <Button
        role="button"
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <CircularProgress color="secondary" size={30} />
        ) : (
          t('components:CreateProject.create_project')
        )}
      </Button>
    </Form>
  );
}

CreateProjectForm.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  successMsg: PropTypes.string
};

export default CreateProjectForm;
