import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import WarningIcon from '@mui/icons-material/Warning';

/**
 * @name ModalDataBody
 * @description ModalDataBody component for upd
 * @param {object} actionTypes - object containing action types
 * @param {string} type - type of action (change or delete)
 * @param {string} text - text to be displayed in modal
 * */
function ModalDataBody({ actionTypes, type, text }) {
  // when updating keywords/adding kws to list
  if (type === actionTypes.UPDATE || type === actionTypes.ADD) {
    return (
      <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>
        {text}
      </Typography>
    );
  }
  // when deleting keywords
  if (type === actionTypes.DELETE) {
    return (
      <Alert icon={<WarningIcon color="error" />} severity="error">
        {text}
      </Alert>
    );
  }
}

ModalDataBody.propTypes = {
  actionTypes: PropTypes.object,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default ModalDataBody;
