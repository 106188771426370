import { useTranslation } from 'react-i18next';
import {
  useGridApiContext,
  useGridSelector,
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue
} from '@mui/x-data-grid';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Button from '@mui/material/Button';

/**
 * @name CustomGridToolbarColumnsButton
 * @description This is a custom `GridToolbarColumnsButton` which is almost
 * identical to the `GridToolbarColumnsButton` but it does not check if the
 * `disableColumnSelector` flag is set. We are doing this in order to
 * hide `show` and `hide` columns from the all columns headers but to keep it as
 * a separate button in the toolbar.
 */
function CustomGridToolbarColumnsButton() {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable'
  });
  const apiRef = useGridApiContext();
  const { open, openedPanelValue } = useGridSelector(apiRef, gridPreferencePanelStateSelector);

  const showColumns = (event) => {
    if (open && openedPanelValue === GridPreferencePanelsValue.columns) {
      apiRef.current.hidePreferences();
    } else {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  };

  return (
    <Button onClick={showColumns} size="small" aria-label="Columns" startIcon={<ViewColumnIcon />}>
      {t('CustomGridToolbarColumnsButton.columns')}
    </Button>
  );
}

export default CustomGridToolbarColumnsButton;
