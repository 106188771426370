import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';

export const Box = styled(MuiBox)`
  padding: 20px 12px;
  height: 250px;
  overflow: scroll;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
`;
