import PropTypes from 'prop-types';

// Styles
import { ListItemText, Grid, Checkbox, Image } from './styled';

function Label({ icon, name, checked }) {
  return (
    <Grid container alignItems="center">
      <Checkbox checked={checked} />
      <Image src={icon} height={8} width={8} />
      <ListItemText ml={4} primary={name} />
    </Grid>
  );
}

Label.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool
};

export default Label;
