import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { Grid } from './styled';

// Utils
import { renderKWRankWithFallback } from 'utils/utils';

interface Props {
  value: number;
  isLoading: boolean;
}

/**
 * Renders the competitor row cell
 */
function CompetitorColumnCell({ value, isLoading }: Props) {
  return (
    <Grid>{isLoading ? <CircularProgress size={10} /> : renderKWRankWithFallback(value)}</Grid>
  );
}

export default CompetitorColumnCell;
