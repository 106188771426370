import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import MuiCardContent from '@mui/material/CardContent';
import MuiTextField from '@mui/material/TextField';
import MuiTypography from '@mui/material/Typography';
import NotesAddIcon from '@mui/icons-material/NoteAdd';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import spacing from '@mui/system/spacing';

// Constants
import { MAX_LENGTH } from './index';

export const Typography = styled(MuiTypography)(spacing);

export const CardContent = styled(MuiCardContent)`
  height: 100%;
  min-height: 150px;

  &:last-child {
    padding-bottom: 16px;
  }
`;

export const Box = styled(MuiBox)`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`;

export const StyledNotes = styled(NotesAddIcon)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

export const LoadingButton = styled(MuiLoadingButton)`
  margin-left: auto;
`;

export const Button = styled(MuiButton)`
  margin-left: auto;
`;

// This TextField will always try to take max card height (with it's padding in mind)
export const TextField = styled(MuiTextField)`
  height: 100%;

  .MuiOutlinedInput-root.MuiInputBase-fullWidth {
    height: calc(100% - 48px);
  }

  textarea {
    height: 100% !important;
    //resize: vertical; todo find solution for height before allowing resize
  }

  .MuiFormHelperText-root {
    margin-left: 0;
    color: ${(props) => props.length === MAX_LENGTH && `red`};
  }
`;
