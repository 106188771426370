import { useContext } from 'react';

// Contexts
import { LocalesListContext } from 'contexts/LocalesList';

const useLocalesList = () => {
  const context = useContext(LocalesListContext);

  if (!context) throw new Error('LocalesListContext must be placed within LocalesListProvider');

  return context;
};

export default useLocalesList;
