import { useQuery } from 'react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getOKSKeywords } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  iosVersion: number;
  listId: number;
}
function useOKSGenerator({ projectId, appId, platform, iosVersion, listId }: Props) {
  return useQuery(
    [CACHE_KEYS.OKS_GENERATOR, { projectId, appId, platform, iosVersion, listId }],
    () => getOKSKeywords({ projectId, appId, platform, iosVersion, listId }),
    {
      enabled: !!listId
    }
  );
}

export default useOKSGenerator;
