import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import AddIcon from '@mui/icons-material/Add';

// Constants
import { FETCH_STATE } from 'api/index';

// Components
import Skeleton from 'components/Skeleton';

// Hooks
import useApp from 'hooks/useApp';

// Styles
import { Button, Title, NonButtonItem } from './styled';
import { Divider } from 'components/Sidebar/NavListItem/styled';

// Utils
import { isEmpty } from 'utils/utils';

// Assets
import Plus from 'assets/svg/add_copy.svg';

function AppDetails() {
  const { t } = useTranslation('components', { keyPrefix: 'SidebarUtils' });
  const { status, app } = useApp();
  const hasApp = !isEmpty(app);

  const onAddMarketClick = () => {
    // todo when add app branch is merged fire open addLocale modal here
    console.log(app.platform, app.global_id);
  };

  return (
    <>
      {hasApp && (
        <>
          <Divider />
          <NonButtonItem>
            {/* app info section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2} mb={2}>
              {/* icon */}
              <Avatar
                src={app?.icon}
                alt="App-icon"
                variant="rounded"
                sx={{ width: 58, height: 58 }}
              >
                {status === FETCH_STATE.LOADING && <Skeleton height={58} />}
                {status === FETCH_STATE.ERROR && (
                  <ErrorOutlineIcon color="error" fontSize="large" />
                )}
                {status === FETCH_STATE.SUCCESS && (
                  <BreakfastDiningIcon color="secondary" fontSize="large" />
                )}
              </Avatar>
              {/* title and add market button */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'space-between'
                }}
                ml={2}
              >
                <Title variant="h5" height={20} overflow="hidden" mb={2}>
                  {status === FETCH_STATE.LOADING && <Skeleton width={100} height={20} />}
                  {status === FETCH_STATE.ERROR && <ErrorOutlineIcon color="error" />}
                  {status === FETCH_STATE.SUCCESS && app.name}
                </Title>
                <Button
                  variant="outlined"
                  startIcon={<Plus height={13} width={13} />}
                  size="small"
                  onClick={onAddMarketClick}
                  disabled={status !== FETCH_STATE.SUCCESS}
                >
                  {t('add_market')}
                </Button>
              </Box>
            </Box>
          </NonButtonItem>
        </>
      )}
    </>
  );
}

export default AppDetails;
