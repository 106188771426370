import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';

// Components
import SidebarNavSection from '../NavSection';

// Hooks
import useApp from 'hooks/useApp';

// Utils
import { isEmpty } from 'utils/utils';

// Styles
import { Items } from './styled';

const AppNav = ({ items }) => {
  const { t } = useTranslation();
  const { app } = useApp();

  const hasApp = !isEmpty(app);

  return (
    <>
      {hasApp && (
        <>
          <List disablePadding>
            <Items>
              {items &&
                items.map((item) => (
                  <SidebarNavSection
                    component="div"
                    key={item.title}
                    pages={item.pages}
                    title={t(`common:${item.title}`)}
                  />
                ))}
            </Items>
          </List>
        </>
      )}
    </>
  );
};

AppNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object)
};

export default AppNav;
