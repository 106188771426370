import { useContext, useState } from 'react';
import { useQuery } from 'react-query';

// Fetchers
import { API, CACHE_KEYS } from 'api';

// Context
import { KeywordsListsContext } from 'contexts/Keywords/KeywordLists';

/**
 * This hook fetches getAppKeywordsLists and stores the results in localStorage.
 * It also handles refetching of the data.
 * @param {number} appId
 * @param {string} projectId
 * @param {string} platform
 * @param {boolean} isMockState, disables the query if true
 * @returns {
 *  isAvailableListsSuccess: boolean,
 *  availableLists: array,
 *  setAvailableLists: function,
 *  listSelected: object,
 *  setListSelected: function
 *  refetchLists: function
 * }
 */
const useKeywordsLists = (projectId, appId, platform, isMockState) => {
  const { availableLists, setAvailableLists, listSelected, setListSelected } =
    useContext(KeywordsListsContext);

  // when changing selected list, also save it to localStorage
  const handleSetListSelected = (newList) => {
    setListSelected(newList);
    localStorage.setItem('keywordListId', newList.id);
    localStorage.setItem('keywordListName', newList.name);
  };

  //handle the lists response
  const handleSetAvailableLists = (responseData) => {
    // save available lists to state
    setAvailableLists(responseData);
    // check if there is already a list selected for this app
    const cachedList = responseData.find(
      (el) => el.id === parseInt(localStorage.getItem('keywordListId'), 10)
    );
    if (cachedList) {
      setListSelected(cachedList);
      return;
    }
    // if no list id found, select the first one from the array
    handleSetListSelected(responseData[0]);
  };

  const { status, refetch } = useQuery(
    [CACHE_KEYS.GET_KEYWORD_LISTS, projectId, appId, platform],
    () => API.getKeywordLists({ projectId, appId, platform }),
    {
      enabled: !isMockState,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (responseData) => {
        if (responseData.data.length === 0) {
          // if no lists set listId as 0 (list_id undefined fetches all kws of that app,
          // regardless of the list, and takes a very long time to respond
          // todo verify if above statement is true for Toaster 2
          localStorage.setItem('keywordListId', '0');
          localStorage.setItem('keywordListName', '');
          // do not continue
          return;
        }
        handleSetAvailableLists(responseData.data);
      }
    }
  );

  return {
    status,
    availableLists,
    setAvailableLists: handleSetAvailableLists,
    listSelected,
    setListSelected: handleSetListSelected,
    refetchLists: refetch
  };
};

export default useKeywordsLists;
