import { useMutation, useQueryClient } from 'react-query';

// Fetchers
import { addCompetitor } from 'api/api';

// Constants
import { CACHE_KEYS } from 'api/constants';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

function useCompetitorsMutation({ projectId, appId, platform }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ competitorId }: { competitorId: number }) =>
      addCompetitor({ projectId, appId, platform, competitorId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEYS.COMPETITORS, { projectId, appId, platform }]);
      }
    }
  );
}

export default useCompetitorsMutation;
