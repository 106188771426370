// Constants
import { FETCH_STATE } from 'api';
import { ACTIONS } from 'constants/App';

const NOTE_STATE = {
  UPDATED: 'updated'
};

export const AppReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_TRANSLATIONS:
      return {
        ...state,
        translateKeywords: !state.translateKeywords
      };

    case ACTIONS.INITIALIZE:
      return {
        ...state,
        app: action.payload.app,
        allTrackedLocales: action.payload.allTrackedLocales,
        noteStatus: FETCH_STATE.SUCCESS
      };

    case ACTIONS.ERROR:
      return {
        app: {},
        translateKeywords: true
      };

    case ACTIONS.SET_NOTE_LOADING:
      return {
        ...state,
        noteStatus: FETCH_STATE.LOADING
      };

    case ACTIONS.SET_NOTE_SUCCESS:
      return {
        ...state,
        app: {
          ...state.app,
          notes: [action.payload.updatedNote]
        },
        // We're using `NOTE_STATE` object instead of the default `FETCH_STATE`
        noteStatus: NOTE_STATE.UPDATED
      };

    case ACTIONS.SET_NOTE_RESET: {
      return {
        ...state,
        noteStatus: FETCH_STATE.IDLE
      };
    }

    case ACTIONS.SET_NOTE_ERROR: {
      return {
        ...state,
        noteStatus: FETCH_STATE.ERROR
      };
    }

    default:
      return state;
  }
};
