import { createContext, useState, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Modal from 'components/Modal';
import SharingCenter from 'components/SharingCenterWidget/SharingCenter';

interface SharingCenterContextType {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export const SharingCenterContext = createContext<SharingCenterContextType>(
  {} as SharingCenterContextType
);

/**
 * Provider for the SharingCenterContext.
 *
 * It provides the state of the SharingCenter modal and the function to open/close it.
 * It renders the SharingCenter modal if it's open, and removes it if it's closed.
 *
 * @param children  The children of the provider.
 */
function SharingCenterProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('components', { keyPrefix: 'SharingCenterWidget' });

  return (
    <SharingCenterContext.Provider
      value={{
        isOpen,
        setIsOpen
      }}
    >
      <>
        {children}
        <Modal
          isLoading={false}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isComponent
          body={<SharingCenter />}
          title={t('sharing_center')}
        />
      </>
    </SharingCenterContext.Provider>
  );
}

export default SharingCenterProvider;
