import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styles
import { AccordionSummary, AccordionStyled } from './styled';

/**
 * @name Accordion
 * @description custom reusable accordion
 * @param  {String} title accordion content summery
 * @param  {String} titleVariant title variant, default is h5
 * @param  {boolean} defaultExpanded is expanded by default, default value false
 * @param  {String, node} content accordion content summery, can be a simple string or can be a child component
 * */
function Accordion({ title, titleVariant = 'h5', defaultExpanded = false, content }) {
  return (
    <AccordionStyled defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant={titleVariant}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </AccordionStyled>
  );
}

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]),
  titleVariant: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired])
};

export default Accordion;
