import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';

// Styles
import { Wrapper, ListItemText, ListItemButton } from './styled';

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} container item xs={12} md={6}>
          <List>
            {/* TODO add footer list elements when we know what to add */}
            {/*<ListItemButton component="a" href="#">
              <ListItemText primary="Placeholder" />
            </ListItemButton>*/}
          </List>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItemText primary={`© ${new Date().getFullYear()} - Toaster`} />
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
