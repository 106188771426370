import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';

// Constants
import { THEMES } from 'constants/Theme';

// Theme
import createTheme from 'theme';

// Components
import GlobalStyle from 'components/GlobalStyle';

// Styles
import { Root, AppContent } from './styled';

/**
 * @name Presentation
 * @description Layout for presentation
 * @param  {Object} props
 * @param  {React.ReactNode} props.children
 */
const Presentation = ({ children }) => {
  return (
    <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
          {children}
          <Outlet />
        </AppContent>
      </Root>
    </MuiThemeProvider>
  );
};

Presentation.propTypes = {
  children: PropTypes.node
};

export default Presentation;
