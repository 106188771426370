import 'react-app-polyfill/stable';

import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ENVIRONMENT } from 'constants/Environment';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import App from './App';
import { ThemeProvider } from 'contexts/Theme';
import './i18n';
// CSS imports must be last
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-datepicker/dist/react-datepicker.css';

const CURRENT_ENVIRONMENT = process.env.APP_ENV || '';

// Sentry initialization. When APP_ENV is PRODUCTION or STAGING, the Sentry
// is enabled. Sentry also needs to be initialized with the correct
// DSN. We use staging environment to catch bugs before release, and production
// to catch bugs after release.
if (process.env.SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false })
    ],
    // We're adjusting this value in production
    tracesSampleRate: CURRENT_ENVIRONMENT === ENVIRONMENT.PRODUCTION ? 0.5 : 1.0,
    release: process.env.SENTRY_RELEASE,
    environment: CURRENT_ENVIRONMENT
  });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
