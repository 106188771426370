import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

// Constants
import { STATUS } from 'constants/Auth';

// Components
import BrandIcon from 'components/BrandIcon';
import SignInSuccess from 'components/SignInSuccess';
import SignUpForm from './Form';

// Fetchers
import { ERROR } from 'api';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import { Wrapper } from './styled';

function SignUpSuccess() {
  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Success!
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Check your email to verify your account.
      </Typography>
    </React.Fragment>
  );
}

/**
 * @name SignUp
 * @description Sign-up page with default form and Google OAuth. This Component
 * displays success component depending if sign-up was done via email and password or
 * by Google OAuth
 */
function SignUp() {
  // We do not specify namespace and keyPrefix, as we need also to translate schema. It does not work otherwise.
  const { t } = useTranslation();
  const [signUpStatus, setSignUpStatus] = useState(STATUS.IDLE);
  const { signUp } = useAuth();
  const handleOnSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      await signUp({
        username: values.username,
        email: values.email,
        password: values.password
      });
      setSignUpStatus(STATUS.LOCAL_SIGN_UP);
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('pages:SignUp.something_went_wrong');
        tempErrors = { submit: [message] };
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <BrandIcon isPhiture />
      <Wrapper>
        <Helmet title={t('common:sign_up')} />
        {signUpStatus === STATUS.LOCAL_SIGN_UP && <SignUpSuccess />}
        {signUpStatus === STATUS.GOOGLE_SIGN_UP && <SignInSuccess />}
        {signUpStatus === STATUS.IDLE && (
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {t('pages:SignUp.get_started')}
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              {t('pages:SignUp.start_creating_ux_for_customers')}
            </Typography>
            <SignUpForm setSignUpStatus={setSignUpStatus} handleOnSubmit={handleOnSubmit} />
          </React.Fragment>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
