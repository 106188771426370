// Cache keys which are being used by react-query to reference the cached data.
export const CACHE_KEYS = {
  // USERS
  USERS: 'USERS',
  USER_CURRENT: 'USER_CURRENT',
  USER_VERIFY_EMAIL: 'USER_VERIFY_EMAIL',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER: 'USER',

  // AUTH
  AUTH_TOKEN_REFRESH: 'AUTH_TOKEN_REFRESH',

  // PROJECTS
  PROJECTS: 'PROJECTS',
  PROJECTS_ACCESS: 'PROJECTS_ACCESS',
  PROJECT: 'PROJECT',
  SHARE_PROJECT: 'SHARE_PROJECT',
  REMOVE_SHARE_USER: 'REMOVE_USER',
  EDIT_SHARE_USER: 'EDIT_SHARE_USER',

  // APPS:
  APPS: 'APPS',
  APP: 'APP',
  APPS_SEARCH_AUTOCOMPLETE: 'APPS_SEARCH_AUTOCOMPLETE',

  APPS_COUNTRY_LANG_COMBO: 'APPS_COUNTRY_LANG_COMBO',
  APPS_COUNTRIES: 'APPS_COUNTRIES',
  APPS_LANGUAGES: 'APPS_LANGUAGES',

  GET_KEYWORD_LISTS: 'GET_KEYWORD_LISTS',

  GET_KEYWORDS_KEYWORD: 'GET_KEYWORDS_KEYWORD', // requestType: keyword
  GET_KEYWORDS_POPULARITY: 'GET_KEYWORDS_POPULARITY', // requestType: popularity
  GET_KEYWORDS_RANK: 'GET_KEYWORDS_RANK', // requestType: rank
  GET_KEYWORDS_TOTAL_APPS: 'GET_KEYWORDS_TOTAL_APPS', // requestType: total-apps
  GET_KEYWORDS_DOWNLOADS: 'GET_KEYWORDS_DOWNLOADS', // requestType: downloads

  // NOTES:
  NOTES: 'NOTES',
  NOTE: 'NOTE',

  // COMPETITORS
  COMPETITORS: 'COMPETITORS',
  COMPETITORS_KEYWORDS_RANK: 'COMPETITORS_KEYWORDS_RANK',
  COMPETITORS_SUGGESTIONS: 'COMPETITORS_SUGGESTIONS',

  // OKS
  OKS_METADATA: 'OKS_METADATA',
  OKS_GENERATOR: 'OKS_GENERATOR'
};

// List of API endpoints
export const API_URLS = {
  USERS: 'users/',
  USER_CURRENT: 'users/me/',
  USER_VERIFY_EMAIL: 'users/verify-email/',
  USER_RESET_PASSWORD: 'users/reset-password/',

  PROJECTS: 'projects/',
  PROJECTS_ACCESS: 'projects/access/',
  PROJECT: 'projects/:projectId',
  SHARE_PROJECT: 'projects/:projectId/users',
  EDIT_SHARE_USER: 'projects/:projectId/users/:userId',
  REMOVE_SHARE_USER: 'projects/:projectId/users/:userId',

  APPS: 'projects/:projectId/apps/',
  APP: `projects/:projectId/apps/:appId/:platform/`,

  KEYWORDS: 'projects/:projectId/apps/:appId/keywords/',
  KEYWORDS_ADD: 'projects/:projectId/apps/:appId/:platform/add-keywords/',
  KEYWORDS_GET: 'projects/:projectId/apps/:appId/:platform/get-keywords/:keywordListId/',
  KEYWORDS_GET_POPULARITY:
    'projects/:projectId/apps/:appId/:platform/get-keywords-popularity/:keywordListId/',
  KEYWORDS_GET_RANK: 'projects/:projectId/apps/:appId/:platform/get-keywords-ranks/:keywordListId/',
  KEYWORDS_GET_DOWNLOADS:
    'projects/:projectId/apps/:appId/:platform/get-keywords-downloads/:keywordListId/',
  KEYWORDS_GET_TOTAL_APPS:
    'projects/:projectId/apps/:appId/:platform/get-keywords-total-apps/:keywordListId/',
  KEYWORDS_DELETE: 'projects/:projectId/apps/:appId/:platform/keywords/remove/',
  KEYWORDS_UPDATE: 'projects/:projectId/apps/:appId/:platform/keywords/update-attributes/',
  GET_KEYWORDS_TEST: 'get-keywords-test/',
  KEYWORD_LISTS: 'projects/:projectId/apps/:appId/:platform/keyword-lists/',

  NOTES: 'projects/:projectId/apps/:appId/:platform/notes/',
  NOTE: 'projects/:projectId/apps/:appId/:platform/notes/:noteId/',

  APPS_SEARCH: 'projects/:projectId/apps/search/',

  APPS_COUNTRY_LANG_COMBO: 'projects/:projectId/apps/ioscomb/',
  APPS_COUNTRIES: 'projects/:projectId/apps/app-countries/',
  APPS_LANGUAGES: 'projects/:projectId/apps/app-languages/',
  APP_TIER: `projects/:projectId/apps/:appId/:platform/change-app-tier/`,
  LINK_APPS: 'projects/:projectId/apps/:appId/:platform/link-apps/',

  COMPETITORS: 'projects/:projectId/apps/:appId/:platform/competitors/',
  COMPETITORS_KEYWORDS_RANK: 'projects/:projectId/apps/:appId/:platform/competitors/keywords/',
  COMPETITORS_SUGGESTIONS: 'projects/:projectId/apps/:appId/:platform/competitors/suggestions/',

  OKS_METADATA: 'projects/:projectId/apps/:appId/:platform/oks-metadata/',
  OKS_GENERATOR: 'projects/:projectId/apps/:appId/:platform/keywords/:keywordListId/oks-generator/'
};

// List of endpoints related to user authentication
export const AUTH_URLS = {
  AUTH_SIGN_IN: 'login/',
  AUTH_SIGN_IN_GOOGLE: 'login/google/',
  AUTH_TOKEN_REFRESH: 'login/refresh/'
};

// List of query states. This is used throughout the app to determine the state of the query.
export const FETCH_STATE = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  EMPTY: 'empty'
};

export enum FETCH_STATE_ENUM {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  EMPTY = 'empty'
}

export const STALE_TIME = {
  INFINITY: Infinity,
  TEN_MINUTES: 10 * 60 * 1000
};

export const GET_KEYWORDS_REQ_TYPE = {
  KEYWORD: 'KEYWORD',
  RANK: 'RANK',
  POPULARITY: 'POPULARITY',
  DOWNLOADS: 'DOWNLOADS',
  TOTAL_APPS: 'TOTAL_APPS'
};
