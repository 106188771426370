import { useContext } from 'react';

// Contexts
import { AuthContext } from 'contexts/Auth';

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth must be placed within AuthProvider');

  return context;
};

export default useAuth;
