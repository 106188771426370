import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiCardContent from '@mui/material/CardContent';
import MuiTypography from '@mui/material/Typography';
import spacing from '@mui/system/spacing';
import CustomSelect from 'components/Select';
import MuiSwitch from '@mui/material/Switch';

export const Typography = styled(MuiTypography)(spacing);

export const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
`;

export const Subtitle = styled(Typography)`
  font-size: 12px;
`;

export const Logo = styled.img`
  display: inline-block;
  height: 112px;
  width: 112px;
  border-radius: 8px;
`;

export const CardContent = styled(MuiCardContent)`
  display: grid;
  grid-template-columns: minmax(100px, max-content) auto;
  gap: 11px;

  &:last-child {
    padding-bottom: 16px;
  }
`;

export const Flag = styled.img`
  border-radius: 50%;
  width: 13px;
  height: 13px;
`;

export const AppDetailsWrapper = styled(MuiBox)`
  display: flex;
  flex-direction: column;
`;

export const Box = styled(MuiBox)`
  margin-top: auto;
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2px;

  ${(props) => props.theme.breakpoints.up('md')} {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const Switch = styled(MuiSwitch)`
  .MuiSwitch-input {
    width: 200% !important;
  }

  & .MuiSwitch-thumb {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0px rgb(0 0 0 / 14%),
      0 1px 3px 0 rgb(0 0 0 / 12%);
  }
`;

export const TranslationBox = styled(MuiBox)`
  white-space: nowrap;
  background-color: #f0f0f0;
  padding: 0 4px;
  border-radius: 6px;
  height: 24px;
  font-size: 10px;
  display: flex;
  align-items: center;
`;

export const Select = styled(CustomSelect)`
  & .MuiInputBase-root {
    height: 24px;
    font-size: 10px;
  }
`;
