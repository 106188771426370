import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import MuiButton from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import spacing from '@mui/system/spacing';

export const Alert = styled(MuiAlert)(spacing);

export const TextField = styled(MuiTextField)(spacing);

export const Form = styled.form`
  max-width: 327px;
`;

export const Button = styled(MuiButton)`
  height: 40px;
  margin: ${(props) => props.theme.spacing(1.5)} 0};
`;
