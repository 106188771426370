import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';
// Components
import Skeleton from 'components/Skeleton';
// Constants
import { FETCH_STATE } from 'api/constants';

/**
 * @name TableCellWithStates
 * @description Shared cell component for all tables to display loading, error or empty state
 * @param {string} status - status of the cell
 * @param {string} tooltipTitle - title of the tooltip, optional, only for cells with long text
 * @param {node} children - children of the cell, normally the row value
 * */

const TableCellWithStates = ({ status, tooltipTitle, children }) => {
  if (status === FETCH_STATE.LOADING) {
    return (
      <Grid item xs={12} display="flex" justifyContent="center">
        <Skeleton />
      </Grid>
    );
  }

  if (status === FETCH_STATE.ERROR) {
    return (
      <Grid item xs={12} display="flex" justifyContent="center">
        <ErrorOutlineIcon color="error" />
      </Grid>
    );
  }

  if (tooltipTitle) {
    return (
      <Grid item xs={12} display="flex" justifyContent="start">
        <Tooltip title={tooltipTitle}>
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{children}</Box>
        </Tooltip>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} display="flex" justifyContent="center">
      {children}
    </Grid>
  );
};

// add propTypes
TableCellWithStates.propTypes = {
  status: PropTypes.string,
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

export default TableCellWithStates;
