import { useContext } from 'react';

// Contexts
import { SharingCenterContext } from 'contexts/SharingCenter';

function useSharingCenter() {
  const { isOpen, setIsOpen } = useContext(SharingCenterContext);

  return {
    isOpen,
    setIsOpen
  };
}

export default useSharingCenter;
