import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const AUTO_HIDE_DURATION = 6000;

/**
 * @name FeedbackToast
 * @description shared feedback toast for feedback
 * @param {string} status options: success, error
 * @param {string} message feedback message
 * @param {boolean} open controls if toast is open
 * @param {function} setOpen sets open state of toast
 * */

function FeedbackToast({ status, message, open, onClose }) {
  const feedbackStatus = status === 'idle' || status === 'loading' ? 'info' : status;
  return (
    <Snackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={feedbackStatus} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

FeedbackToast.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default FeedbackToast;
