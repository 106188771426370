import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

// Assets
import BrandIcon from 'vendor/logo.svg';

export const Brand = styled(BrandIcon)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  & > * {
    max-width: 327px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
