import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Components
import Competitor from './Competitor';
import AppModal from 'components/AppModal';

// Styles
import { CompetitorContainer, AddButton } from './styled';

// Constants
import { PLATFORMS } from 'constants/constants';

// Hooks
import useApp from 'hooks/useApp';
import useTrackStatus from 'hooks/useTrackStatus';

// Fetchers
import useCompetitorsSuggestionsQuery from 'api/queries/Competitors/useCompetitorsSuggestions';
import useCompetitorsMutation from 'api/queries/Competitors/useCompetitorsMutation';
import useCompetitorsQuery from 'api/queries/Competitors/useCompetitors';

// Types
import { FETCH_STATE_ENUM } from 'api/constants';

const platformOptions = [
  {
    value: PLATFORMS.IPHONE,
    label: 'iPhone'
  },
  {
    value: PLATFORMS.ANDROID,
    label: 'Android'
  },
  {
    value: PLATFORMS.IPAD,
    label: 'iPad'
  }
];

/**
 * Renders the list of the competitors and the button to add more competitors.
 */
function Competitors() {
  const { t } = useTranslation('components', { keyPrefix: 'AppHeader.Competitors' });
  const [isOpen, setIsOpen] = useState(false);
  const { projectId, appId, platform } = useParams();
  const { app } = useApp();

  const { data: competitors } = useCompetitorsQuery({ appId, platform, projectId });

  // move this into another component, prefetch it actually on button hover
  const { data: suggestions } = useCompetitorsSuggestionsQuery({ appId, platform, projectId });

  const addCompetitor = useCompetitorsMutation({ appId, platform, projectId });

  const { activeApp, setActiveApp } = useTrackStatus({ status: addCompetitor.status });

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const currentPlatform = platformOptions.filter((option) => option.value === app?.platform);

  const modalInitialParams = {
    platform: app?.platform,
    country: app?.country,
    locale: app?.locale,
    searchId: ''
  };

  /**
   * Adds a competitor to the list of tracked competitors for an app.
   * @param app  Competitor app
   */
  const handleSubmit = (app) => {
    const competitorId = app.app.id || app.app.app_id;

    addCompetitor.mutate({
      competitorId
    });
    setActiveApp(() => ({ [competitorId]: FETCH_STATE_ENUM.LOADING }));
  };

  /**
   * Checks if the app is already tracked.
   * @param app  Competitor app
   */
  const isTracked = (app) => {
    return competitors?.data.some(
      (competitor) => competitor.app_id === app.id || competitor.app_id === app.app_id
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container flexDirection={'column'} justifyContent={'center'}>
            <Typography component="h2" variant="appH2">
              {t('competitors')}
            </Typography>
            <CompetitorContainer container mt={4}>
              <Grid>
                <AddButton disabled={!modalInitialParams.platform} handleClick={handleOpenModal} />
              </Grid>
              {competitors?.data.map((competitor) => (
                <Competitor key={competitor.app_id} name={competitor.name} icon={competitor.icon} />
              ))}
            </CompetitorContainer>
          </Grid>
        </CardContent>
      </Card>
      {modalInitialParams.platform && (
        <AppModal
          initialParams={modalInitialParams}
          isOpen={isOpen}
          onClose={handleCloseModal}
          platformOptions={currentPlatform}
          disableSelection={true}
          title={t('track_competitor')}
          buttonText={t('track')}
          suggestions={suggestions?.data || []}
          onSubmit={handleSubmit}
          isTracked={isTracked}
          status={activeApp}
        />
      )}
    </>
  );
}

export default Competitors;
