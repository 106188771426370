import React, { forwardRef, useState } from 'react';
import { sub, isFuture } from 'date-fns';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';

// Styles
import { DateRangeInputBox, DateRangeInputWrapper, Select } from './styled';
import { Typography, FilterInput } from 'pages/app/Application/KeywordTrackingTable/styled';

const isNotFutureDate = (date) => !isFuture(date);
const DATA_RANGE_END = new Date();

/**
 * @name DateRangeFilter
 * @description Filter a DateRange of values. This filter uses
 * a React Datepicker.
 * @see https://reactdatepicker.com/ React Datepicker documentation
 * @param  {Object} props
 * @param  {Date} props.valueStart Start date
 * @param  {Date} props.valueEnd Start date
 * @param  {Function} props.onChange
 * @param  {String} props.size
 * @param  {Number} props.width
 * @param  {String} props.label
 * @param  {String} props.name
 */
function DateRangeFilter({ valueStart, valueEnd, onChange, size, width, label, name }) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable'
  });
  const [dateRange, setDateRange] = useState(0);
  let startDate = valueStart;
  let endDate = valueEnd;

  const handleCalendarClose = () => {
    if (valueEnd === null) {
      onChange('date_range_start', null);
      onChange('date_range_end', null);
      setDateRange(0);
    }
  };

  const handleOnChange = (dates) => {
    onChange('date_range_start', dates[0]);
    onChange('date_range_end', dates[1]);
    setDateRange(0);
  };

  const handleRangeChange = (e) => {
    const days = e.target.value;
    const dataRangeStart = sub(DATA_RANGE_END, { days });

    onChange('date_range_start', dataRangeStart);
    onChange('date_range_end', DATA_RANGE_END);
    setDateRange(days);
  };

  const DateRangeFilterCustomInput = forwardRef(({ value, onClick }, ref) => {
    let formattedValue = value.replace(' - ', ' to ');
    return (
      <FilterInput
        onClick={onClick}
        placeholder={t('DateRangeFilter.placeholder_text')}
        inputProps={{
          // `id` is a random gibberish in order to throw browser autofill
          // suggestions off
          id: 'dnac0',
          // Same for the autofill
          autoComplete: 'new-password',
          // Disable user input
          readOnly: true
        }}
        size={size}
        name={name}
        value={formattedValue}
      />
    );
  });

  DateRangeFilterCustomInput.displayName = 'DateRangeFilterCustomInput';

  DateRangeFilterCustomInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
  };

  return (
    <DateRangeInputBox width={width}>
      <Typography>
        <label htmlFor={name}>{label}</label>
        {/* TODO: use InputLabel */}
      </Typography>
      <DateRangeInputWrapper>
        {/* TODO DatePicker needs proper styling */}
        <DatePicker
          autoComplete="off"
          selected={startDate}
          onChange={handleOnChange}
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd-MM-yy"
          filterDate={isNotFutureDate}
          selectsRange
          onCalendarClose={handleCalendarClose}
          customInput={<DateRangeFilterCustomInput />}
          name={name}
        />
        <Select
          size={size}
          value={dateRange}
          onChange={handleRangeChange}
          displayEmpty
          data-testid={`filter-testid-${name}`}
        >
          <MenuItem value={0} disabled>
            {t('DateRangeFilter.select_date_range')}
          </MenuItem>
          <MenuItem value={30}>{t('DateRangeFilter.last_days', { days: '30' })}</MenuItem>
          <MenuItem value={90}>{t('DateRangeFilter.last_days', { days: '90' })}</MenuItem>
          <MenuItem value={180}>{t('DateRangeFilter.last_days', { days: '180' })}</MenuItem>
        </Select>
      </DateRangeInputWrapper>
    </DateRangeInputBox>
  );
}

DateRangeFilter.propTypes = {
  valueStart: PropTypes.object,
  valueEnd: PropTypes.object,
  width: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func
};

export default DateRangeFilter;
