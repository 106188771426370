import { useQuery } from 'react-query';

// Constants
import { CACHE_KEYS } from 'api/constants';

// Fetchers
import { getAppKeywords } from 'api/api';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
  iosVersion: number;
  listId: number;
}
function useKeywordsQuery({ projectId, appId, platform, iosVersion, listId }: Props) {
  return useQuery({
    queryKey: [CACHE_KEYS.GET_KEYWORDS, { projectId, appId, platform, iosVersion, listId }],
    queryFn: () => getAppKeywords({ projectId, appId, platform, iosVersion, listId }),
    enabled: !!listId
  });
}

export default useKeywordsQuery;
