import axios from 'axios';

// Set API url depending on the current environment
const BASE_URL = process.env.BASE_URL;

// Export API url to use it for other API calls
export const API_URL = '/api/v2';
export const AUTH_URL = '/auth';

/**
 * @name api
 * @description API axios instance
 * @returns AxiosInstance
 */
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
