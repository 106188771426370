import React from 'react';
import { useTranslation } from 'react-i18next';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

// Styles
import { Box } from './styled';

/**
 * @name ComingSoonMessage
 * @description simple component displaying coming soon message
 * */
function ComingSoonMessage() {
  const { t } = useTranslation('shared');
  return (
    <Box>
      {t('ComingSoonMessage.coming_soon')} <DirectionsRunIcon sx={{ fontSize: 50 }} />
    </Box>
  );
}

export default ComingSoonMessage;
