import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Constants
import { URLS } from 'constants/URL';

// Components
import GoogleButton from 'components/GoogleButton';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import { Alert, TextField, Form } from 'pages/auth/styled';

const signUpSchema = Yup.object().shape({
  username: Yup.string().max(255).required('common:username_required'),
  email: Yup.string().email('common:email_valid').max(255).required('common:email_required'),
  password: Yup.string()
    .min(8, 'components:SignUp.password_min_length')
    .max(255)
    .required('common:password_required'),
  confirmPassword: Yup.string().when('password', {
    is: (val) => val && val.length > 0,
    then: Yup.string().oneOf([Yup.ref('password')], 'components:SignUp.password_same')
  })
});

/**
 * @name SignUp
 * @description This is a SignUp form which also enables Google OAuth sign in
 * @param  {object} props
 * @param  {function} props.handleOnSubmit
 * @param  {function} props.setSignUpStatus if set to 'LOCAL_SIGN_UP' or 'GOOGLE_SIGN_UP'
 * hides form and displays next screen
 */
function SignUp({ setSignUpStatus, handleOnSubmit }) {
  const { t } = useTranslation('common');
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const { error: authGoogleError } = useAuth();
  const { handleSubmit, errors, values, touched, handleBlur, handleChange, isSubmitting } =
    useFormik({
      initialValues: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        submit: false
      },
      validationSchema: signUpSchema,
      onSubmit: handleOnSubmit
    });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {/* Here are the google errors for SignUp form */}
      {authGoogleError && (
        <Alert mt={2} mb={1} severity="warning">
          {authGoogleError}
        </Alert>
      )}
      {errors.submit && (
        <Alert mt={2} mb={1} severity="warning">
          {errors.submit}
        </Alert>
      )}
      <Grid container>
        <Grid item xs={12} my={4}>
          <TextField
            inputProps={{
              'data-testid': 'username-input'
            }}
            data-testid="username"
            type="text"
            name="username"
            label={t('common:username')}
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={
              touched.username &&
              errors.username && <span data-testid="username-errors">{t(errors.username)}</span>
            }
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={4}>
          <TextField
            data-testid="email"
            inputProps={{
              'data-testid': 'email-input'
            }}
            type="email"
            name="email"
            label={t('email_address')}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && t(errors.email)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={4}>
          <TextField
            inputProps={{
              'data-testid': 'password-input'
            }}
            data-testid="password"
            type="password"
            name="password"
            label={t('common:password')}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={
              touched.password &&
              errors.password && <span data-testid="password-errors">{t(errors.password)}</span>
            }
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={4}>
          <TextField
            data-testid="confirm-password"
            inputProps={{
              'data-testid': 'confirm-password-input'
            }}
            type="password"
            name="confirmPassword"
            label={t('common:confirm_password')}
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && t(errors.confirmPassword)}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={2}>
          <Button
            data-testid="submit-button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || isGoogleSubmitting}
          >
            {t('common:sign_up')}
          </Button>
        </Grid>
        <Grid item xs={12} my={2}>
          <GoogleButton
            setSignInStatus={setSignUpStatus}
            setIsGoogleSubmitting={setIsGoogleSubmitting}
          />
        </Grid>
        <Grid item xs={12} my={2}>
          <Button component={Link} to={URLS.SIGN_IN} fullWidth color="primary">
            {t('common:sign_in')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}

SignUp.propTypes = {
  setSignUpStatus: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired
};

export default SignUp;
