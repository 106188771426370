// @ts-nocheck
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Title = styled(Typography)`
  color: white;
  font-weight: 700;
  font-size: 16px;
`;

export const Button = styled(MuiButton)(({ theme }) => ({
  color: theme.sidebar.color,
  border: 0,
  fontSize: '9px'
}));

export const NonButtonItem = styled(Box)<{ depth?: number }>`
  padding-top: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) => props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;
