import { FC } from 'react';

// Hooks
import useSharingCenter from 'hooks/useSharingCenter';

// Styles
import { Item, Title } from 'components/Sidebar/NavListItem/styled';

interface Props {
  title: string;
  icon: FC;
}

function SharingCenterItem({ title, icon: Icon }: Props) {
  const { isOpen, setIsOpen } = useSharingCenter();

  return (
    <Item component={'div'} onClick={() => setIsOpen(!isOpen)} activeclassname="active">
      <Icon />
      <Title>{title}</Title>
    </Item>
  );
}

export default SharingCenterItem;
