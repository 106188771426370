import React from 'react';
import { useParams } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Fetchers
import { FETCH_STATE } from 'api/index';

// Styles
import { AppInfoContainer, IconContainer, RowContainer, TextContainer } from './styled';

// Types
import { PLATFORMS } from 'types/Platform';
import { SubmitData } from 'components/AppModal';
import { FETCH_STATE_ENUM } from 'api/constants';

interface Props {
  app: any;
  country: string;
  locale: string;
  platform: PLATFORMS;
  isTracked: boolean;
  onSubmit: (data: SubmitData) => void;
  status: { [id: string]: FETCH_STATE_ENUM };
  buttonText: string;
}

/**
 * AddAppRow component is used to display the app info and add button
 *
 * @param app         App object
 * @param country     App code
 * @param locale      App locale
 * @param platform    App platform
 * @param isTracked   App is tracked or not
 * @param onSubmit    On submit callback
 * @param status      Status of the request
 * @param buttonText  Button text
 */
function AddAppRow({
  app,
  country,
  locale,
  platform,
  isTracked,
  onSubmit,
  status,
  buttonText
}: Props) {
  const { projectId } = useParams();

  const onAddAppClick = () => {
    const data = {
      projectId,
      locale,
      country,
      app,
      platform
    };

    onSubmit(data);
  };

  return (
    <RowContainer>
      <AppInfoContainer>
        <Avatar sx={{ borderRadius: '10px' }} variant="square" alt="icon" src={app.icon} />
        <TextContainer>
          <Typography variant="subtitle2">{app.name}</Typography>
          <Typography variant="caption">{app.publisher}</Typography>
          <Typography variant="caption">{app.global_id || app.app_global_id}</Typography>
        </TextContainer>
      </AppInfoContainer>
      <Box sx={{ alignSelf: 'center', textAlign: 'center' }}>
        {!isTracked ? (
          <React.Fragment>
            {(!status[app.id || app.app_id] ||
              status[app.id || app.app_id] === FETCH_STATE.IDLE) && (
              <Button variant="outlined" color="secondary" onClick={onAddAppClick}>
                {buttonText}
              </Button>
            )}
            {status[app.id || app.app_id] === FETCH_STATE.ERROR && (
              <IconContainer>
                <ErrorOutlineIcon fontSize="large" color="error" />
              </IconContainer>
            )}
            {status[app.id || app.app_id] === FETCH_STATE.LOADING && (
              <IconContainer>
                <CircularProgress color="secondary" />
              </IconContainer>
            )}
          </React.Fragment>
        ) : (
          <IconContainer>
            <DoneIcon fontSize="large" color="success" />
          </IconContainer>
        )}
      </Box>
    </RowContainer>
  );
}

export default AddAppRow;
