// Types
import { Project, ProjectRole } from 'types/Project';

// TODO: needs country code in app.app_other_market response, for options and flag
// temporary workaround to get matching value between app.country (=== country code eg US) and options (eg United States)
export const getCountryName = (countryCode: string) => {
  if (!countryCode) return;

  return new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode);
};

export const canUserLinkApp = (projects: Project[], projectId: string) => {
  const project = projects.find((project) => project.project_id === projectId);

  return project?.role === ProjectRole.Admin;
};
