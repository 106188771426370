import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// Fetchers
import { FETCH_STATE } from 'api';

// Components
import TooltipWithIcon from 'components/TooltipWithIcon';

// Styles
import { GridItem, TextField, Typography, CSVTypography, FileDropBox } from './styled';

//types of file accepted by uploader
const FILE_TYPES = ['CSV'];
/**
 * @name AddKeywordManual
 * @description Tab to add keywords manually, either with input or with csv
 * @param {string} status upload status
 * @param {object} payload for add keywords api
 * @param {function} setPayload sets payload in parent
 * */
function AddKeywordManual({ status, payload, setPayload }) {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.AddKeywordManual'
  });
  // destructured payload object
  const { addIndividualTerms, keywords } = payload;

  // add keywords via input
  const onInputDown = (e) => {
    setPayload('keywords', e.target.value);
  };

  // TODO move to parent as payload, when api ready
  // add keywords as csv
  const [csvFile, setCsvFile] = useState(null);
  const handleFileUpload = (file) => {
    setCsvFile(file);
  };

  // toggle long tail
  const onSwitchToggle = () => {
    setPayload('addIndividualTerms', !addIndividualTerms);
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="start"
      justifyContent="space-between"
      sx={{ height: '100%' }}
    >
      {/* manually add keywords in input*/}
      <GridItem item md={12} lg={6}>
        {/* manually add keywords in input, title*/}
        <Typography variant="subtitle2" gutterBottom>
          {t('add_separate_comma')}
          <TooltipWithIcon title={<Box>{t('keywords_added_generic_relevant')}</Box>} />
        </Typography>
        {/* manually add keywords in input, main component*/}
        <TextField
          value={keywords}
          variant="outlined"
          onChange={onInputDown}
          fullWidth
          disabled={status === FETCH_STATE.LOADING}
          label={t('enter_keywords')}
          multiline
          rows={2}
        />
        {/*toggle long tail*/}
        <Box display="flex" flexDirection="column">
          {/*toggle long tail, title*/}
          <Typography variant="subtitle3" sx={{ alignItems: 'center !important' }} gutterBottom>
            {t('add_individual_terms')}
            <TooltipWithIcon
              title={<Box sx={{ whiteSpace: 'pre-line' }}>{t('individual_terms_tooltip')}</Box>}
            />
          </Typography>
          {/*toggle long tail, main component*/}
          <Switch
            checked={addIndividualTerms}
            onChange={onSwitchToggle}
            inputProps={{ 'aria-label': 'addIndividualTerms' }}
          />
        </Box>
      </GridItem>
      {/*import csv*/}
      <GridItem item md={12} lg={5} sx={{ height: '100%' }}>
        {/*import csv, title*/}
        <Typography variant="subtitle2" gutterBottom>
          {t('import_from_csv')}
          <TooltipWithIcon
            title={<Box sx={{ whiteSpace: 'pre-line' }}>{t('csv_requirements')}</Box>}
          />
        </Typography>
        {/*import csv, main component*/}
        <FileUploader handleChange={handleFileUpload} name="file" types={FILE_TYPES}>
          <FileDropBox variant="outlined">
            {/*import csv, main component, container*/}
            <Box display="flex" flexDirection="column" alignItems="center">
              {/*import csv, main component, container, icon*/}
              <FileUploadIcon fontSize="large" color="secondary" />
              {/*import csv, main component, container, text with tooltip*/}
              <Tooltip title={csvFile ? csvFile.name : t('choose_file')}>
                <CSVTypography
                  sx={{ color: csvFile ? 'primary.main' : 'secondary.main' }}
                  variant="subtitle2"
                  mr={2}
                  gutterBottom
                >
                  {csvFile ? csvFile.name : t('choose_file')}
                </CSVTypography>
              </Tooltip>
            </Box>
          </FileDropBox>
        </FileUploader>
        <Alert severity="warning">Feature not yet available</Alert>
      </GridItem>
    </Grid>
  );
}

AddKeywordManual.propTypes = {
  status: PropTypes.string,
  payload: PropTypes.object,
  setPayload: PropTypes.func
};

export default AddKeywordManual;
