import React, { useState } from 'react';
import styled from '@emotion/styled';
import i18n from 'i18next';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
// Constants
import {
  KEYWORD_RELEVANCIES,
  KEYWORD_RELEVANCY_OPTIONS_MAP,
  KEYWORD_TYPE_OPTIONS_MAP,
  KEYWORD_TYPES
} from 'constants/constants';
import { FETCH_STATE, GET_KEYWORDS_REQ_TYPE } from 'api/constants';

// Components
import Select from 'components/Select';
import ConfirmCancelButton from 'components/ConfirmCancelButton';
import TableCellWithStates from 'components/TableCellWithStates';

export const DATE_STRING_FORMAT = 'dd-MM-yyyy';
export const KEYWORD_TRACKING_TABLE_RELEVANCY = Object.values(KEYWORD_RELEVANCIES);
export const KEYWORD_TRACKING_TABLE_TYPE = Object.values(KEYWORD_TYPES);
export const DEFAULT_SORT = [{ field: 'keywords', sort: 'asc' }];
export const DEFAULT_DENSITY = 'standard';
export const FILTER_TYPES = {
  RANGE: 'RANGE',
  TEXT: 'TEXT',
  MULTISELECT: 'MULTISELECT',
  SELECT: 'SELECT',
  DATE_RANGE: 'DATE_RANGE'
};

// This is important. If you want to add a new SELECT TYPE filter, add a corresponding
// options list here. currently we have: relevancy_name, type_name
export const SELECT_FILTER_OPTIONS = {
  relevancy_name: KEYWORD_TRACKING_TABLE_RELEVANCY,
  type_name: KEYWORD_TRACKING_TABLE_TYPE
};

const KeywordsHeaderTitle = styled(Typography)`
  font-weight: 500;
  font-size: 10px;
`;

/**
 * @name keywordTrackingTableColumns
 * @description Columns to be displayed inside KeywordTrackingTable component.
 * @param  {'standard' | 'compact' | 'comfortable'} density Current density of the table.
 * If the density is `compact` then we change some cells styling
 * @param  {Number} count Number of total keywords to be displayed in the header of the `name` column.
 * @param deleteKeywordMutation
 * @param updateKeywordMutation
 * @param queriesStatuses
 * @return  {{
 *  field: String,
 *  renderHeader: (props) => JSX.Element,
 *  headerClassName: String,
 *  headerName: String,
 *  width: Number,
 *  renderCell: (props) => JSX.Element,
 *  filterType: 'RANGE' | 'SELECT' | 'MULTISELECT' | "TEXT"
 * }}
 */
export const keywordTrackingTableColumns = (
  density = 'standard',
  count = 0,
  deleteKeywordMutation,
  updateKeywordMutation,
  queriesStatuses
) => {
  return [
    {
      field: 'name',
      renderHeader: () => {
        return (
          <KeywordsHeaderTitle data-testid="keyword-tracking-table-count">
            {i18n.t('components:KeywordTrackingTable.columns.keywords', { count })}
          </KeywordsHeaderTitle>
        );
      },
      headerClassName: 'MuiDataGrid-columnHeaderTitle',
      headerName: i18n.t('components:KeywordTrackingTable.columns.keywords_header_name'),
      minWidth: 170,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.name} arrow>
            <Chip size={density === 'compact' ? 'small' : 'medium'} label={row.name} />
          </Tooltip>
        );
      },
      filterType: FILTER_TYPES.TEXT
    },
    {
      field: 'type_name',
      headerName: i18n.t('components:KeywordTrackingTable.columns.type_header_name'),
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Select
            id="type"
            value={row.type_name}
            options={KEYWORD_TRACKING_TABLE_TYPE}
            isCompact={density === 'compact'}
            width={125}
            onChange={(e) =>
              updateKeywordMutation.mutate({
                keywordIds: row.keyword_id.toString(),
                type: KEYWORD_TYPE_OPTIONS_MAP[e.target.value],
                type_name: e.target.value
              })
            }
            //add loading state only for the row being updated
            status={
              updateKeywordMutation?.variables?.keywordIds.includes(row.keyword_id.toString()) &&
              updateKeywordMutation?.variables?.type
                ? updateKeywordMutation?.status
                : queriesStatuses[GET_KEYWORDS_REQ_TYPE.KEYWORD]
            }
          />
        );
      },
      filterType: FILTER_TYPES.MULTISELECT,
      filterOptions: KEYWORD_TRACKING_TABLE_TYPE
    },
    {
      field: 'relevancy_name',
      headerName: i18n.t('components:KeywordTrackingTable.columns.relevancy_header_name'),
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Select
            id="relevancy"
            value={row.relevancy_name}
            options={KEYWORD_TRACKING_TABLE_RELEVANCY}
            isCompact={density === 'compact'}
            width={125}
            onChange={(e) =>
              updateKeywordMutation.mutate({
                keywordIds: row.keyword_id.toString(),
                relevancy: KEYWORD_RELEVANCY_OPTIONS_MAP[e.target.value],
                relevancy_name: e.target.value
              })
            }
            //add loading state only for the row being updated
            status={
              updateKeywordMutation?.variables?.keywordIds.includes(row.keyword_id.toString()) &&
              updateKeywordMutation?.variables?.relevancy
                ? updateKeywordMutation?.status
                : queriesStatuses[GET_KEYWORDS_REQ_TYPE.KEYWORD]
            }
          />
        );
      },
      filterType: FILTER_TYPES.MULTISELECT,
      filterOptions: KEYWORD_TRACKING_TABLE_RELEVANCY
    },
    {
      field: 'popularity_score',
      headerName: i18n.t('components:KeywordTrackingTable.columns.popularity_score_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.POPULARITY]}>
          {props.value}
        </TableCellWithStates>
      )
    },
    {
      field: 'popularity_change',
      headerName: i18n.t(
        'components:KeywordTrackingTable.columns.popularity_score_change_header_name'
      ),
      type: 'number',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates
          status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.POPULARITY]}
          tooltipTitle={props.value}
        >
          {props.value}
        </TableCellWithStates>
      )
    },
    {
      field: 'max_results',
      headerName: i18n.t('components:KeywordTrackingTable.columns.total_apps_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.TOTAL_APPS]}>
          {props.value}
        </TableCellWithStates>
      )
    },
    {
      field: 'relevancy',
      headerName: i18n.t('components:KeywordTrackingTable.columns.competition_header_name'),
      type: 'number',
      flex: 0.75,
      editable: false,
      // TODO check if we need this filter
      //filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.KEYWORD]}>
          {!!props.value
            ? 'N/D'
            : `${props.row.relevancy_competitor}/${props.row.competitors_count} (${props.value}%)`}
        </TableCellWithStates>
      )
    },
    {
      field: 'rank',
      headerName: i18n.t('components:KeywordTrackingTable.columns.rank_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.RANK]}>
          {props.value}
        </TableCellWithStates>
      )
    },
    {
      field: 'rank_change',
      headerName: i18n.t('components:KeywordTrackingTable.columns.rank_change_header_name'),
      type: 'string',
      flex: 0.75,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates
          status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.RANK]}
          tooltipTitle={props.value}
        >
          {props.value}
        </TableCellWithStates>
      )
    },
    {
      field: 'download_score',
      headerName: i18n.t('components:KeywordTrackingTable.columns.download_score_header_name'),
      type: 'number',
      flex: 0.85,
      editable: false,
      filterType: FILTER_TYPES.RANGE,
      renderCell: (props) => (
        <TableCellWithStates status={queriesStatuses[GET_KEYWORDS_REQ_TYPE.DOWNLOADS]}>
          {props.value ?? 'N/D'}
        </TableCellWithStates>
      )
    },
    // TODO what is this column for?
    /*    {
      field: 'live_rank',
      headerName: i18n.t('components:KeywordTrackingTable.columns.live_rank_header_name'),
      minWidth: 92,
      flex: 0.75,
      // Disable default Material UI datagrid hooks
      editable: false,
      sortable: false,
      renderCell: () => {
        return (
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        );
      }
    },*/
    {
      field: 'keyword_id',
      headerName: i18n.t('components:KeywordTrackingTable.columns.delete_header_name'),
      flex: 0.75,
      // Disable default Material UI datagrid hooks
      editable: false,
      sortable: false,
      renderCell: (props) => {
        const [isConfirm, setIsConfirm] = useState(false);
        return (
          <Box display="flex" sx={{ width: '80px' }} justifyContent="center">
            {deleteKeywordMutation?.variables?.keywordIds.includes(props.value.toString()) &&
            deleteKeywordMutation.status === FETCH_STATE.LOADING ? (
              <ConfirmCancelButton type="loading" />
            ) : (
              <>
                <ConfirmCancelButton
                  type={isConfirm ? 'confirm' : 'delete'}
                  action={() =>
                    isConfirm
                      ? deleteKeywordMutation.mutate({ keywordIds: props.value.toString() })
                      : setIsConfirm(true)
                  }
                />
                {isConfirm && (
                  <ConfirmCancelButton type="cancel" action={() => setIsConfirm(false)} />
                )}
              </>
            )}
          </Box>
        );
      }
    }
  ];
};
