// Constants
import { APP_PAGES } from 'constants/constants';
import { EXTERNAL_URLS, URLS } from 'constants/URL';

// Assets
import Settings from 'assets/svg/account.svg';
import HomeIcon from 'assets/svg/home.svg';
import Project from 'assets/svg/folder.svg';
import Star from 'assets/svg/star.svg';
import Sharing from 'assets/svg/sharing_centre.svg';
import Plus from 'assets/svg/add_copy.svg';

import Error from 'assets/svg/error.svg';
import OKS from 'assets/svg/oks.svg';
import KeywordTracking from 'assets/svg/tune.svg';

export enum Pages {
  DASHBOARD = 'dashboard',
  PROJECTS = 'projects',
  USER_SETTINGS = 'user_settings',
  REPORT_A_BUG = 'report_a_bug',
  CREATE_NEW_PROJECT = 'create_new_project',
  OPEN_SHARING_CENTER = 'open_sharing_center',
  QUICK_ACCESS = 'quick_access',
  TRACKING_TABLE = 'tracking_table',
  OKS = 'oks'
}

export const dashboardPages = [
  {
    href: URLS.DASHBOARD,
    icon: HomeIcon,
    title: 'apps_dashboard',
    type: Pages.DASHBOARD
  },
  {
    icon: Project,
    title: 'projects',
    type: Pages.PROJECTS
  }
];

export const quickAccessPages = [
  {
    icon: Star,
    title: 'quick_access',
    type: Pages.QUICK_ACCESS,
    children: [
      {
        href: URLS.PROJECT_CREATE,
        icon: Plus,
        title: 'create_new_project',
        type: Pages.CREATE_NEW_PROJECT
      },
      {
        icon: Sharing,
        title: 'open_sharing_center',
        type: Pages.OPEN_SHARING_CENTER
      }
    ]
  }
];

export const settingsPages = [
  {
    href: URLS.USER_SETTINGS,
    icon: Settings,
    title: 'user_settings',
    type: Pages.USER_SETTINGS
  },
  {
    icon: Error,
    title: 'report_a_bug',
    href: EXTERNAL_URLS.REPORT_A_BUG,
    type: Pages.REPORT_A_BUG
  }
];

export const appItems = [
  {
    pages: [
      {
        href: URLS.TRACKING_TABLE,
        icon: KeywordTracking,
        title: APP_PAGES.TRACKING_TABLE,
        type: Pages.TRACKING_TABLE
      },
      {
        href: URLS.OKS,
        icon: OKS,
        title: APP_PAGES.OKS,
        type: Pages.OKS
      }
      // features not yet available
      /*
     {
       href: createUrl(URLS.CHANGES_BACKLOG, ['1', '1']),
       icon: Calendar,
       title: APP_PAGES.CHANGES_BACKLOG
     },
     {
       href: createUrl(URLS.KEYWORDS_ANALYSIS, ['1', '1']),
       icon: Activity,
       title: APP_PAGES.KEYWORDS_ANALYSIS
     },
     {
       href: createUrl(URLS.SEQUENTIAL_ANALYSIS, ['1', '1']),
       icon: Search,
       title: APP_PAGES.SEQUENTIAL_ANALYSIS
     }*/
    ]
  }
];
