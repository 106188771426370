import React from 'react';
import MuiBox from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';

// Styles
import { RangeFilterInput, RangeFilterWrapper, RangeInputBox } from './styled';
import { Typography } from 'pages/app/Application/KeywordTrackingTable/styled';

/**
 * @name RangeFilter
 * @description Filter a range of values
 * @param  {Object} props
 * @param  {String} props.label Header name of the corresponding column for this filter.
 * @param  {String} props.name Name of the corresponding column for this filter.
 * @param  {Function} props.onChange Function that sets up the filter for this component
 * @param  {Function} props.onBlur Formik onBlur handle.
 * @param  {String[]} props.value Current filter values
 * @param  {'small' | 'medium' | 'large'} props.size Size of the input. Defaults to 'small'
 * @param  {Array} props.errors Errors of the input values
 * @param  {Number} props.width
 */
function RangeFilter({ label, name, onChange, onBlur, value, errors, width, size = 'small' }) {
  return (
    <RangeInputBox width={width}>
      <MuiBox>
        <Typography>{label}</Typography>
        <RangeFilterWrapper>
          <RangeFilterInput
            fullWidth
            onBlur={onBlur}
            onChange={onChange}
            // Do not validate if the `max` value is empty. Otherwise we won't be able
            // to pass anything there.
            InputProps={{ inputProps: { min: 0, max: value[1] === '0' ? null : value[1] } }}
            type="number"
            inputMode="numeric"
            name={`${name}_min`}
            value={value[0] === '0' ? '' : value[0]}
            size={size}
            sx={{
              width: `${width}px`
            }}
            variant="outlined"
            placeholder="10"
            error={errors[0]}
          />
          <RangeFilterInput
            fullWidth
            onBlur={onBlur}
            onChange={onChange}
            InputProps={{ inputProps: { min: value[0] } }}
            type="number"
            name={`${name}_max`}
            value={value[1] === '0' ? '' : value[1]}
            size={size}
            sx={{ width: `${width}px` }}
            variant="outlined"
            placeholder="100"
            // We only check for errors on the `max` input
            error={errors[1]}
          />
        </RangeFilterWrapper>
        {errors[0] && (
          <FormHelperText error sx={{ height: 'unset' }}>
            {errors[0]}
          </FormHelperText>
        )}
        {errors[1] && (
          <FormHelperText error sx={{ height: 'unset' }}>
            {errors[1]}
          </FormHelperText>
        )}
      </MuiBox>
    </RangeInputBox>
  );
}

RangeFilter.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  errors: PropTypes.array,
  width: PropTypes.number
};

export default RangeFilter;
