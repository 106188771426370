import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Utils
import { buildUrl } from 'utils/utils';

// Constants
import { URLS } from 'constants/URL';

// Styles
import {
  Button,
  Link,
  Item,
  Collapse,
  Title,
  Badge,
  ProjectItem,
  ExpandLessIcon,
  ExpandMoreIcon
} from './styled';

/**
 * @name ProjectsGroup
 * @description This component displays expandable list of the provided category.
 * @param  {Object} props
 * @param  {String} props.title
 * @param  depth
 * @param  {{project_id: String, account_name: String}[]} props.projects Project to be
 * listed inside this component
 * @param  {Number} props.badge Number to be displayed inside the `badge`
 * @param  {Boolean} props.open Defaults to `false`
 * @param  {String} props.currentSelectedProjectId
 */
function ProjectsGroup({
  title,
  depth = 0,
  projects,
  badge,
  open = false,
  currentSelectedProjectId
}) {
  const [isOpen, setIsOpen] = useState(open);

  const handleToggle = () => {
    setIsOpen((state) => !state);
  };

  return (
    <React.Fragment>
      <Item onClick={handleToggle}>
        {badge && <Badge label={badge} />}
        <Title depth={depth}>{title}</Title>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Item>
      <Collapse in={isOpen}>
        <React.Fragment>
          {projects.map((project) => {
            const { project_id: projectId, account_name: projectName } = project;

            return (
              <ProjectItem
                key={projectId}
                active={currentSelectedProjectId === projectId ? 'true' : ''}
              >
                <Link
                  component={NavLink}
                  // variant="body1"
                  to={buildUrl(URLS.PROJECT, { projectId })}
                >
                  {projectName}
                </Link>
                <Button
                  component={NavLink}
                  to={buildUrl(URLS.PROJECT_SETTINGS_PAGE, { projectId })}
                >
                  <SettingsIcon />
                </Button>
              </ProjectItem>
            );
          })}
        </React.Fragment>
      </Collapse>
    </React.Fragment>
  );
}

ProjectsGroup.propTypes = {
  title: PropTypes.string.isRequired,
  depth: PropTypes.number,
  badge: PropTypes.number,
  open: PropTypes.bool,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      account_name: PropTypes.string.isRequired,
      project_id: PropTypes.string.isRequired
    })
  ).isRequired,
  currentSelectedProjectId: PropTypes.string
};

export default ProjectsGroup;
