import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';

// Components
import Settings from 'components/Settings';
import GlobalStyle from 'components/GlobalStyle';
import ErrorBoundary from 'components/ErrorBoundary';

// Styles
import { Root } from './styled';

/**
 * @name Auth
 * @description Layout for the authentication pages. This layout has
 * its own error boundary.
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 */
const Auth = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <ErrorBoundary location="auth">{children}</ErrorBoundary>
      <Outlet />
    </Root>
  );
};

Auth.propTypes = {
  children: PropTypes.node
};

export default Auth;
