const filterMethods = {
  TEXT: (row, id, filterValue) => {
    const filter = String(filterValue).toLowerCase();
    const rowValue = row[id] ? String(row[id]).toLowerCase() : undefined;
    const translation = row.en_translation ? String(row.en_translation).toLowerCase() : undefined;
    if (!rowValue && !translation) return true;

    return rowValue?.startsWith(filter) || translation?.startsWith(filter);
  },

  RANGE: (row, id, filterValue, filterParamName) => {
    // ignore undefined attributes
    if (row[id] === undefined || row[id] === null) return true;
    // TODO optimize this
    if (filterParamName.includes('min')) {
      return parseInt(row[id]) >= Number(filterValue);
    }
    if (filterParamName.includes('max')) {
      return parseInt(row[id]) <= parseInt(filterValue);
    }
    return true;
  },

  MULTISELECT: (row, id, filterValuesArray) => filterValuesArray.includes(row[id])
};

export default filterMethods;
