export const PLATFORMS = {
  IPHONE: 'iphone',
  IPAD: 'ipad',
  IOS: 'ios',
  ANDROID: 'android'
};

export const PHITURE_GROUP = 'Standard (phiture)';

export const PROJECT_ROLES = [
  {
    id: '1',
    role: 'Admin'
  },
  {
    id: '2',
    role: 'Owner'
  },
  {
    id: '3',
    role: 'Editor'
  },
  {
    id: '4',
    role: 'Client(External to Phiture)'
  },
  { id: '5', role: 'Freelancer Research + Relevancy(external)' },
  {
    id: '6',
    role: 'Freelancer(Optimization)'
  },
  {
    id: '7',
    role: 'Freelancer Analysis'
  },
  { id: '8', role: 'Localization' }
];

// Hardcoded KEYWORD TYPES that are the same on the backend.
export const KEYWORD_TYPES = {
  BRAND: 'Brand',
  COMPETITION: 'Competition',
  GENERIC: 'Generic'
};
export const KEYWORD_TYPE_OPTIONS = [
  { label: 'None', value: '' },
  { label: 'Brand', value: 1 },
  { label: 'Competition', value: 2 },
  { label: 'Generic', value: 3 }
];
export const KEYWORD_TYPE_OPTIONS_MAP = KEYWORD_TYPE_OPTIONS.reduce(
  (acc, option) => ({ ...acc, [option.label]: option.value }),
  {}
);

// Hardcoded KEYWORD_RELEVANCIES TYPES that are the same on the backend.
export const KEYWORD_RELEVANCIES = {
  IRRELEVANT: 'Irrelevant',
  RELEVANT: 'Relevant',
  EXTREMELY_RELEVANT: 'Extremely relevant',
  STARRED: 'Starred'
};
export const KEYWORD_RELEVANCY_OPTIONS = [
  { label: 'None', value: '' },
  { label: 'Irrelevant', value: 1 },
  { label: 'Relevant', value: 2 },
  { label: 'Extremely relevant', value: 3 },
  { label: 'Starred', value: 4 }
];

export const KEYWORD_RELEVANCY_OPTIONS_MAP = KEYWORD_RELEVANCY_OPTIONS.reduce(
  (acc, option) => ({ ...acc, [option.label]: option.value }),
  {}
);

export const APP_PAGES = {
  TRACKING_TABLE: 'keywords_tracking',
  OKS: 'optimal_keyword_set',
  CHANGES_BACKLOG: 'changes_backlog',
  KEYWORDS_ANALYSIS: 'keyword_analysis',
  SEQUENTIAL_ANALYSIS: 'sequential_analysis'
};

export const IOS_VERSION = 7;

// Static Priority (tier) options
export const PRIORITY_OPTIONS = [
  { value: '1', label: 'components:AppHeader.Details.priority_count' },
  { value: '2', label: 'components:AppHeader.Details.priority_count' },
  { value: '3', label: 'components:AppHeader.Details.priority_count' }
];

// Static Platform options
export const PLATFORM_OPTIONS = [
  {
    value: PLATFORMS.IPHONE,
    label: 'iPhone'
  },
  {
    value: PLATFORMS.ANDROID,
    label: 'Android'
  }
];
