import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

// Components
import Loader from 'components/Loader';
import ResetPasswordForm from './Form';

// Hooks
import useAuth from 'hooks/useAuth';

// Styles
import { Brand, Wrapper } from './styled';

// Fetchers
import { ERROR } from 'api';

// Constants
import { URLS } from 'constants/URL';
import { STATUS } from 'constants/Auth';

/**
 * @name ResetPasswordSuccess
 * @description This component is used in the Reset Password page. When the
 * reset password is successful, the user will be redirected to the sign-in.
 */
function ResetPasswordSuccess() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ResetPassword'
  });
  const navigate = useNavigate();

  useEffect(() => {
    // If user is logged in, redirect user to the dashboard
    let timer = setTimeout(() => {
      navigate(URLS.SIGN_IN);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('success')}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t('check_email_finish_reset')}
      </Typography>
      <Loader />
    </React.Fragment>
  );
}

function ResetPassword() {
  const { t } = useTranslation('pages', {
    keyPrefix: 'ResetPassword'
  });
  const [resetPasswordStatus, setResetPasswordStatus] = useState(STATUS.IDLE);
  const { passwordReset } = useAuth();
  const handleOnSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      await passwordReset({ email: values.email });
      setResetPasswordStatus(STATUS.RESET_PASSWORD);
    } catch (error) {
      let tempErrors = {};
      // If error is raised because of invalid payload
      // print returned errors from the API on the UI
      if (error instanceof ERROR.ApiResponseBadRequest) {
        const { errorData } = error;
        delete Object.assign(errorData, { submit: errorData['non_field_errors'] })[
          'non_field_errors'
        ];

        tempErrors = { ...errorData };
      } else {
        const message = error.message || t('something_went_wrong');
        tempErrors = { submit: [message] };
      }

      setErrors(tempErrors);
      setStatus({ success: false });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title={t('title')} />
        {resetPasswordStatus === STATUS.RESET_PASSWORD && <ResetPasswordSuccess />}
        {resetPasswordStatus === STATUS.IDLE && (
          <React.Fragment>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {t('title')}
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              {t('enter_email_to_reset_pw')}
            </Typography>
            <ResetPasswordForm handleOnSubmit={handleOnSubmit} />
          </React.Fragment>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
