import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import MuiTextField from '@mui/material/TextField';
import spacing from '@mui/system/spacing';

export const TextField = styled(MuiTextField)(spacing);

export const Alert = styled(MuiAlert)(spacing);

export const SharedUsersListWrapper = styled.ul`
  max-height: ${(props) => !props.noOverflow && '250px'};
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: ${(props) => props.theme.spacing(5)};
  margin-bottom: ${(props) => props.theme.spacing(5)};
`;

export const SharedUsersWrapper = styled.li`
  & + * {
    margin-top: ${(props) => props.theme.spacing(2)};
  }

  form {
    display: flex;
    gap: ${(props) => props.theme.spacing(2)};
    align-items: center;
  }

  form > * {
    width: 60%;
  }

  form > * + * {
    width: 20%;
  }

  form > button {
    width: 10%;
  }

  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: unset;
    color: rgba(0, 0, 0, 0.87);
  }
`;
