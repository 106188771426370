import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import MuiGrid from '@mui/material/Grid';

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export const Container = styled(MuiGrid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
