import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  // Make everything the same size as the Google OAuth button
  & > * {
    max-width: 327px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
