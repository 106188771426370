import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

// Styles
import { CompetitorIcon } from './styled';

/**
 * Renders the competitor icon.
 *
 * @param name   Competitor name
 * @param icon   Competitor icon
 */
function Competitor({ name, icon }) {
  return (
    <Grid>
      <CompetitorIcon src={icon} alt={name} />
    </Grid>
  );
}

Competitor.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default Competitor;
