import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

/**
 * Simple custom search input with optional search icon.
 * With icon allows for submit search,
 * Without icon only works as text input
 * @param {boolean} withIcon - default false if true adds a search icon submit button
 * @param {string} variant - optional, default outlined (other options: standard and filled)
 * @param {string} id - required
 * @param {string} label - optional, default null
 * @param {string} placeholder - optional, default Search
 * @param {string} value - default ''
 * @param {boolean} disabled for both the text input and the submit button
 * @param {function} onChange
 * @param {function} onsubmit - optional, default null
 * */

function SearchBarWithIcon({
  variant = 'outlined',
  id,
  label = null,
  onSubmit = null,
  onChange,
  value,
  placeholder = 'Search...',
  disabled,
  withIcon = false
}) {
  //on input change
  const onInputChange = (e) => {
    onChange(e.target.value);
  };

  // onSubmit
  const onSubmitForm = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Box display="flex">
      <TextField
        sx={{ input: { padding: '11px' } }}
        fullWidth
        color="error"
        size="small"
        id={id}
        label={label}
        variant={variant}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        autoComplete="off"
      />
      {withIcon && (
        <Button
          variant="outlined"
          onClick={onSubmitForm}
          aria-label="submit"
          disabled={disabled || !onSubmit || !value}
        >
          <SearchIcon color="primary" />
        </Button>
      )}
    </Box>
  );
}

SearchBarWithIcon.propTypes = {
  variant: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  withIcon: PropTypes.bool
};

export default SearchBarWithIcon;
