import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { Box } from './styled';

/**
 * @name AddKeywordPanel
 * @description sub-component panel for AddKeywords tabs component
 * */
function AddKeywordPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`addKeyword-tabpanel-${index}`}
      aria-labelledby={`addKeyword-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

AddKeywordPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default AddKeywordPanel;
