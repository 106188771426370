import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';

export const Box = styled(MuiBox)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 10px;
  &:last-of-type {
    color: grey;
  }
`;

export const Translation = styled(Box)`
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
`;

export const Grid = styled(MuiGrid)`
  overflow: hidden;
`;
