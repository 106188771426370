import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
/**
 * @name PageTitle
 * @description simple component displaying the title of the page
 * @param {string} title
 * */
function PageTitle({ title }) {
  return (
    <Typography variant="h3" gutterBottom>
      {title}
    </Typography>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageTitle;
