import { useEffect, useState } from 'react';

/**
 * @name useFilters
 * @description simple hook to manage filter object
 * @param initialFilters {object}
 * @param callback {undefined | function} optional, callback to be triggered at filters change
 * */
function useFilters<Filters extends object, Value>(
  initialFilters: Filters,
  callback?: (filters: Filters) => void
): [Filters, (key: keyof Filters, value: Value) => void, () => void] {
  const [filters, setFilters] = useState({ ...initialFilters });

  // reset filters function
  const resetFilters = () => {
    setFilters(initialFilters);
  };

  // update filter object
  const onFilterInput = (key: keyof Filters, value: Value) => {
    setFilters({
      ...filters,
      ...{
        [key]: value
      }
    });
  };

  // on filter change callback
  useEffect(() => {
    if (callback) callback(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return [filters, onFilterInput, resetFilters];
}

export default useFilters;
