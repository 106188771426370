import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import MuiDialogContent from '@mui/material/DialogContent';

// Components
import Alert from 'components/CollapsableAlert';
import Details from 'components/Details';

export const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
`;

export const SelectsContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const ResultsContainer = styled(Box)`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 100%;
  overflow: scroll;
`;

export const CollapsableAlert = styled(Alert)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      margin-top: 20px;
      &.MuiCollapse-root {
        min-height: auto !important;
      }
    `}
`;

export const InfoDetails = styled(Details)`
  background-color: inherit;

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
`;
