import styled from '@emotion/styled';
import MuiAccordionSummery from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';

export const AccordionStyled = styled(MuiAccordion)`
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 2.6px;
  border-radius: 4px;
`;
// summery container: place arrow on the left and rotate when expanded
export const AccordionSummary = styled(MuiAccordionSummery)`
  flex-direction: row-reverse;
  & .muiaccordionsummary-expandiconwrapper.mui-expanded: {
    transform: rotate(180deg);
  }
`;
