import React from 'react';

// Components
import SidebarNavList from 'components/Sidebar/NavList';

const SidebarNavSection = ({ pages, component: Component = 'nav' }) => {
  return (
    <Component>
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
