import { useMutation, useQueryClient } from 'react-query';

// Fetchers
import { linkApps } from 'api/api';
import { CACHE_KEYS } from 'api/constants';

interface Props {
  projectId: string;
  appId: string;
  platform: string;
}

function useLinkAppsMutation({ projectId, appId, platform }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ newAppId, applePlatform }: { newAppId: string; applePlatform?: string }) =>
      linkApps({ projectId, appId, platform, newAppId, applePlatform }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CACHE_KEYS.APPS, projectId, appId, platform]);
      }
    }
  );
}

export default useLinkAppsMutation;
