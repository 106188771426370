export const URLS = {
  DASHBOARD: '/app/dashboard',
  PROJECT: '/app/project/:projectId',
  SIGN_IN: '/app/sign-in',
  SIGN_UP: '/app/sign-up',
  RESET_PASSWORD: '/app/reset-password',
  PROJECT_APP_PAGE: '/app/project/:projectId/app/:appId',
  PROJECT_SETTINGS_PAGE: '/app/project/:projectId/settings',
  PROJECT_CREATE: '/app/project/create',
  PROJECT_EMPTY: '/app/project/empty',
  USER_SETTINGS: '/app/user-settings',
  ACCOUNT_CONFIRMATION: '/app/account-confirmation',
  TRACKING_TABLE: '/app/project/:projectId/app/:appId/platform/:platform/keyword-tracking',
  OKS: '/app/project/:projectId/app/:appId/platform/:platform/optimal-keyword-set',
  CHANGES_BACKLOG: '/app/project/:projectId/app/:appId/changes-backlog',
  KEYWORDS_ANALYSIS: '/app/project/:projectId/app/:appId/keyword-analysis',
  SEQUENTIAL_ANALYSIS: '/app/project/:projectId/app/:appId/sequential-analysis'
};

export const EXTERNAL_URLS = {
  REPORT_A_BUG:
    'https://docs.google.com/forms/d/1lox-Qk1s8lio3zbWJtekcuWj-K0GrRoh45HgkHUgoDI/viewform?edit_requested=true'
};
