import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import PlaceIcon from '@mui/icons-material/Place';
// Assets
import toaster from 'assets/toaster.png';

// Components
import Footer from './Footer';
import AppNav from './Nav';
import Navigation from './Navigation';
import AppDetails from 'components/Sidebar/Navigation/AppDetails';

// Styles
import { Drawer, Brand, BrandIcon } from './styled';

// Constants
import { URLS } from 'constants/URL';

// Utils
import { buildUrl } from 'utils/utils';
import {
  dashboardPages,
  quickAccessPages,
  settingsPages
} from 'components/Sidebar/Navigation/sidebarUtilsItems';

// Hooks
import useProject from 'hooks/useProject';

/**
 * @name Sidebar
 * @description Sidebar menu to navigate within the app page. only visible inside app page
 **/

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  const { projectId } = useParams();
  const { project } = useProject();

  return (
    <Drawer variant="permanent" {...rest}>
      <Grid sx={{ height: '100%' }}>
        <Brand component={NavLink} to={buildUrl(URLS.PROJECT, { projectId })}>
          <BrandIcon src={toaster} alt="Toaster" />
        </Brand>
        <Grid display="flex" justifyContent="center" p={4}>
          <Chip
            icon={<PlaceIcon />}
            label={project.name}
            size="small"
            color="primary"
            variant="outlined"
          />
        </Grid>
        <Navigation pages={dashboardPages} />
        <AppDetails />
        <AppNav items={items} />
        <Navigation pages={quickAccessPages} />
      </Grid>
      <Grid>
        <Navigation pages={settingsPages} />
        {!!showFooter && <Footer />}
      </Grid>
    </Drawer>
  );
};

Sidebar.propTypes = {
  items: PropTypes.array,
  showFooter: PropTypes.bool
};

export default Sidebar;
