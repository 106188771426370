import PropTypes from 'prop-types';

// Styles
import { InputLabel, Typography } from './styled';

/**
 * Renders an input label with current and maximum allowed length
 *
 * @param label     Input label
 * @param id        Input id
 * @param current   Current input length
 * @param max       Maximum length allowed
 */
function Label({ label, id, current, max }) {
  return (
    <InputLabel htmlFor={id}>
      <Typography>{label}</Typography>
      <Typography>
        {current}/{max}
      </Typography>
    </InputLabel>
  );
}

Label.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};
export default Label;
