import React, { createContext, useState, PropsWithChildren } from 'react';

export const KeywordsListsContext = createContext({});

// if list not selected set Default list as selected list
// - when there is no id the get kws api will return the default list

const initialList = {
  id: undefined,
  name: 'Default'
};

function KeywordsListsProvider({
  children,
  mockState = null
}: PropsWithChildren & { mockState?: any }) {
  // List of available keywords lists
  const [availableLists, setAvailableLists] = useState([]);
  // Currently selected keywords list
  const [listSelected, setListSelected] = useState(mockState || initialList);

  return (
    <KeywordsListsContext.Provider
      value={{
        availableLists,
        setAvailableLists,
        listSelected,
        setListSelected
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </KeywordsListsContext.Provider>
  );
}

export default KeywordsListsProvider;
