export const ACTIONS = {
  INITIALIZE: 'INITIALIZE',
  ERROR: 'ERROR',
  ADD_NOTE: 'ADD_NOTE',
  SET_NOTE_LOADING: 'SET_NOTE_LOADING',
  SET_NOTE_SUCCESS: 'SET_NOTE_SUCCESS',
  SET_NOTE_ERROR: 'SET_NOTE_ERROR',
  SET_NOTE_RESET: 'SET_NOTE_RESET',
  SET_NOTE_UPDATE: 'SET_NOTE_UPDATE',
  TOGGLE_TRANSLATIONS: 'TOGGLE_TRANSLATIONS'
};
