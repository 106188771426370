import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

// Hooks
import useAuth from 'hooks/useAuth';

// Utils
import { buildUrl } from 'utils/utils';

// Constants
import { URLS } from 'constants/URL';

/**
 * @name CreateProjectSuccess
 * @description This component allows user to navigate to new project dashboard
 * @param {string} successMsg
 * @param {string} newProjectId
 * @param {string} newProjectName
 */
function CreateProjectSuccess({ successMsg, newProjectId, newProjectName }) {
  const { refetchUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('components', { keyPrefix: 'CreateProjectSuccess' });

  const onGoToDashboardClick = () => {
    // navigate to project apps dashboard with a flag
    refetchUser();
    navigate(buildUrl(URLS.PROJECT, { projectId: newProjectId }));
  };

  return (
    <Box>
      <Typography variant="subtitle1" component="div" gutterBottom>
        {successMsg}
      </Typography>

      <Button fullWidth variant="outlined" color="secondary" onClick={onGoToDashboardClick}>
        {t('go_to_project_dashboard')}
      </Button>
    </Box>
  );
}

CreateProjectSuccess.propTypes = {
  successMsg: PropTypes.string,
  newProjectId: PropTypes.string,
  newProjectName: PropTypes.string
};

export default CreateProjectSuccess;
