import MuiBox from '@mui/material/Box';
import styled from '@emotion/styled';

export const BoxColumn = styled(MuiBox)`
  display: flex;
  flex-direction: column;
`;
export const BoxRow = styled(MuiBox)`
  display: flex;
`;
