import PropTypes from 'prop-types';

// Styles
import { Box, TextFieldInput } from './styled';

/**
 * Renders a controlled text input component.
 *
 * @param value             Input value
 * @param handleChange      Input Change handler
 * @param handleBlur        Input blur handler
 * @param type              Input type
 * @param name              Input name
 * @param label             Input Label
 * @param fullWidth         Input width boolean
 * @param variant           Input variant
 * @param error             Error state
 * @param helperText        Text to render underneath the input
 * @param className         Classname
 * @param id                Input id
 * @param maxLength         Input maxlength
 * @param placeholder       Input placeholder
 * @param multiline         Boolean to render select
 * @param disabled          Boolean to disable input
 * @param rows              Number of rows to render for textarea
 * @param onKeyPress        Key press handler
 */
function TextField({
  value,
  handleChange,
  handleBlur,
  type = 'text',
  name,
  label,
  fullWidth = false,
  variant,
  error,
  helperText,
  className,
  id,
  maxLength,
  placeholder,
  multiline = false,
  disabled = false,
  rows,
  onKeyPress,
  InputProps,
  sx
}) {
  return (
    <Box className={className}>
      {label}
      <TextFieldInput
        id={id}
        name={name}
        type={type}
        value={value}
        error={error}
        placeholder={placeholder}
        onChange={handleChange}
        fullWidth={fullWidth}
        variant={variant}
        helperText={helperText}
        onBlur={handleBlur}
        onKeyDown={onKeyPress}
        inputProps={{
          maxLength
        }}
        InputProps={InputProps}
        minRows={rows}
        maxRows={rows}
        multiline={multiline}
        disabled={disabled}
        sx={sx}
      />
    </Box>
  );
}

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  onKeyPress: PropTypes.func
};

export default TextField;
