import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '@mui/lab';
import PaletteIcon from '@mui/icons-material/Palette';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';

// Constants
import { THEMES } from 'constants/Theme';

// Hooks
import useTheme from 'hooks/useTheme';

// Styles
import { Fab, Wrapper, Heading, DemoTitle, DemoButton, DemoButtonInner } from './styled';

function Demo({ title, themeVariant }) {
  const { theme, setTheme } = useTheme();

  return (
    <Grid item xs={6}>
      <DemoButton active={themeVariant === theme} onClick={() => setTheme(themeVariant)}>
        <DemoButtonInner selectedTheme={themeVariant} />
      </DemoButton>
      <DemoTitle variant="subtitle2" gutterBottom>
        {title}
      </DemoTitle>
    </Grid>
  );
}

function Demos() {
  return (
    <Wrapper>
      <Heading>Select a demo</Heading>

      <Box px={4} my={3}>
        <Alert icon={false} severity="info">
          <strong>Hello!</strong> Select your style below. Choose the ones that best fits your
          needs.
        </Alert>
      </Box>

      <Box px={4} my={3}>
        <Grid container spacing={3}>
          <Demo title="Dark" themeVariant={THEMES.DARK} />
          <Demo title="Light" themeVariant={THEMES.LIGHT} />
          <Demo title="Default" themeVariant={THEMES.DEFAULT} />
          <Demo title="Blue" themeVariant={THEMES.BLUE} />
          <Demo title="Green" themeVariant={THEMES.GREEN} />
          <Demo title="Indigo" themeVariant={THEMES.INDIGO} />
        </Grid>
      </Box>

      <Box my={3} mx={4}>
        <Button
          component={Link}
          to="/documentation/welcome"
          variant="outlined"
          size="large"
          target="_blank"
          fullWidth={true}
        >
          Documentation
        </Button>
      </Box>
      <Box my={3} mx={4}>
        <Button
          href="https://mui.com/store/items/mira-pro-react-material-admin-dashboard/"
          variant="contained"
          color="primary"
          size="large"
          target="_blank"
          fullWidth={true}
        >
          Get Mira Pro
        </Button>
      </Box>
    </Wrapper>
  );
}

function Settings() {
  const [state, setState] = useState({
    isOpen: false
  });

  const toggleDrawer = (open) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
        <PaletteIcon />
      </Fab>
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Demos />
      </Drawer>
    </React.Fragment>
  );
}

export default Settings;
