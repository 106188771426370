import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import SaveIcon from '@mui/icons-material/Save';

// Hooks
import useApp from 'hooks/useApp';
import usePrompt from 'hooks/usePrompt';

// Constants
import { FETCH_STATE } from 'api';

// Components
import Skeleton from 'components/Skeleton';
import NotesHelperText from './NotesHelperText';

// Styles
import {
  Button,
  Box,
  Typography,
  TextField,
  LoadingButton,
  CardContent,
  StyledNotes
} from './styled';

// Utils
import { isEmpty } from 'utils/utils';

// app note max length
export const MAX_LENGTH = 3000;

const EMPTY_NOTE = '';

/**
 * @name Notes
 * @description This component is responsible for displaying and updating app-specific and project-specific user notes for each user.
 * If the note is changed but not saved, and then user clicks outside of the component, the note will revert back
 */
function Notes() {
  // Uses both `components` and `common` namespace
  const { t } = useTranslation();
  const { app, status: appStatus, setNote: addNote, noteStatus, resetNoteStatus } = useApp();
  const [notes, setNotes] = useState({ id: null, note: EMPTY_NOTE });

  // Derived state based on the notes.
  const noteCharLength = notes.note.split('').length || 0;
  // Make sure to check if value is undefined, then change it to empty string.
  const notSavedChanges =
    (app?.notes?.[0]?.note === undefined ? '' : app.notes[0].note) !== notes.note;
  const noChanges = app?.notes?.[0]?.note === notes.note;

  // Display alert when trying to change page and notes are not saved.
  usePrompt(t('components:AppHeader.Notes.no_saved_changes'), notSavedChanges);

  /**
   * @name handleOnChange
   * @description Pass current value to the component state
   * @param  {event} e
   */
  const handleOnChange = (e) => {
    setNotes((prevState) => {
      return {
        ...prevState,
        note: e.target.value
      };
    });
  };

  /**
   * @name handleOnSubmit
   * @description Send current state to the API. Then retrieve the updated state
   * and pass it to the component in props.
   */
  const handleOnSubmit = () => {
    const { id, note } = notes;
    addNote({
      id,
      note
    });
  };

  // Get user note for the current app within the current project
  // and set it in the component state.
  useEffect(() => {
    // Wait for the appStatus to be `success` and for an app to be loaded
    // This fixes issues when a user tries to go back to this page.
    if (!isEmpty(app) && appStatus === FETCH_STATE.SUCCESS && app.notes?.length > 0) {
      // We only have access to one note per app
      const { id, note } = app.notes[0];
      setNotes({ id, note });
    }
  }, [appStatus, app]);

  // If noteStatus is `error` and there were changes to the current note, remove the errors.
  useEffect(() => {
    let timer;
    if (noteStatus === FETCH_STATE.ERROR) {
      // This runs only if the noteStatus is `error`
      resetNoteStatus();
    }

    // Custom noteStatus
    // Clear the notification after 2 seconds.
    if (noteStatus === 'updated') {
      setTimeout(() => {
        resetNoteStatus();
      }, 2 * 1000);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  if (appStatus === FETCH_STATE.LOADING) {
    return (
      <Card>
        <CardContent>
          <Box mb={2}>
            <StyledNotes />
            <Typography
              title={t('components:AppHeader.Notes.a11y_title')}
              component="h2"
              variant="appH2"
            >
              {t('components:AppHeader.Notes.title')}
            </Typography>
            <Button disabled startIcon={<SaveIcon />} size="small" type="submit" variant="outlined">
              {t('common:save')}
            </Button>
          </Box>
          <Skeleton height="calc(100% - 48px)" maxWidth={'100%'} mb={1} />
          <Skeleton height={18} width={50} />
        </CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <StyledNotes />
          <Typography
            data-testid="notes-title"
            title={t('components:AppHeader.Notes.a11y_title')}
            component="h2"
            variant="appH2"
          >
            {t('components:AppHeader.Notes.title')}
          </Typography>
          <LoadingButton
            loading={noteStatus === FETCH_STATE.LOADING}
            loadingPosition="start"
            onClick={handleOnSubmit}
            startIcon={<SaveIcon />}
            size="small"
            type="submit"
            variant="outlined"
            disabled={noChanges || noteStatus === FETCH_STATE.LOADING}
          >
            {t('common:save')}
          </LoadingButton>
        </Box>
        <TextField
          disabled={noteStatus === FETCH_STATE.LOADING}
          label={t('components:AppHeader.Notes.title')}
          multiline={true}
          onChange={handleOnChange}
          value={notes.note}
          rows={5}
          size="small"
          fullWidth
          inputProps={{
            maxLength: MAX_LENGTH
          }}
          length={noteCharLength}
          helperText={
            <React.Fragment>
              {`${noteCharLength}/${MAX_LENGTH}`}
              <NotesHelperText noteStatus={noteStatus} />
            </React.Fragment>
          }
        />
      </CardContent>
    </Card>
  );
}

export default Notes;
