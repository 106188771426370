import { useContext } from 'react';

// Contexts
import { AppContext } from 'contexts/App';

const useApp = () => {
  const context = useContext(AppContext);

  if (!context) throw new Error('App must be placed within AppProvider');

  return context;
};

export default useApp;
