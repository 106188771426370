import styled from '@emotion/styled';
import { lighten, rgba } from 'polished';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MuiButton from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MuiCollapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const Button = styled(MuiButton)`
  margin-left: auto;
  padding: 0;
  color: white;
  min-width: fit-content;
`;

export const Link = styled(MuiLink)`
  display: flex;
  color: unset;
  width: 100%;
  flex-direction: row;
  align-items: center;
  &:hover {
    text-decoration: unset;
  }
`;

export const Collapse = styled(MuiCollapse)`
  max-height: 200px;
  overflow-y: auto;
`;

export const Item = styled(ListItemButton)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  padding: ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(7)}
    ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(8)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.menu.color};
    opacity: 0.5;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const Title = styled(ListItemText)`
  margin: 0;
  span {
    padding: 0 ${(props) => props.theme.spacing(4)};
    font-size: 14px;
    color: white;
  }
`;

export const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightSemiBold};
  height: 20px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

export const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.menu.color, 0.5)};
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.menu.color, 0.5)};
`;

export const ProjectItem = styled(ListItem)`
  padding: ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(7)}
    ${(props) => props.theme.spacing(3)} ${(props) => props.theme.spacing(8)};
  background-color: ${({ theme }) => theme.sidebar.project};
  color: white;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  font-size: 14px;
`;
