import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import MuiSelect from '@mui/material/Select';
import MuiTypography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export const Typography = styled(MuiTypography)`
  font-weight: 500;
`;
export const FilterWrapper = styled(MuiBox)`
  flex-shrink: 0;
`;

// Should be Select or TextField = textfield and DateRange is for TextFieldFilter
// Select is for SelectFilter
export const FilterInput = styled(TextField)`
  .MuiInputBase-input {
    padding: 8.5px 8px;
  }
`;

export const SelectFilterInput = styled(MuiSelect)`
  .MuiInputBase-input {
    padding: 8.5px 8px;
  }
`;

export const FlexBox = styled(MuiBox)`
  display: flex;
  height: 100%;
  min-height: 500px;
`;

export const FlexItem = styled(MuiBox)`
  flex-grow: 1;
`;
