import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MuiTextField from '@mui/material/TextField';
import MuiTypography from '@mui/material/Typography';

export const Typography = styled(MuiTypography)`
  height: 40px;
  display: flex;
  align-items: end;
  white-space: pre-line; // allows for line break if translation file has \n
`;

export const CSVTypography = styled(MuiTypography)`
  height: 40px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 2px;
  white-space: pre-line; // allows for line break if translation file has \n
`;
export const TextField = styled(MuiTextField)`
  height: calc(100% - 48px);

  .MuiOutlinedInput-root.MuiInputBase-fullWidth {
    height: calc(100% - 48px);
  }

  textarea {
    height: 100% !important;
  }
`;

export const FileDropBox = styled(Card)((props) => ({
  height: 'calc(80% - 48px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '10px',
  border: `1px dashed ${props.theme.palette.secondary.main}`,
  cursor: 'pointer'
}));

export const GridItem = styled(Grid)`
  padding: 5px;
  margin: 5px;
`;
