import React, { useState, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useFormik } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Components
import GoogleButton from 'components/GoogleButton';

// Hooks
import useAuth from 'hooks/useAuth';

// Constants
import { URLS } from 'constants/URL';

// Styles
import { Alert, TextField, Form } from 'pages/auth/styled';

const SignInSchema = () =>
  Yup.object().shape({
    username: Yup.string().max(255).required(i18n.t('common:username_required')),
    password: Yup.string().max(255).required(i18n.t('common:password_required'))
  });

/**
 * @name SignIn
 * @description This is a SignIn form which also enables Google OAuth sign in
 * @param  {object} props
 * @param  {function} props.handleOnSubmit
 * @param  {function} props.setSignInStatus
 */
function SignIn({ handleOnSubmit, setSignInStatus }) {
  // Uses both `components` and `common` namespace.
  // We do not specify namespace and keyPrefix, as we need also to translate schema. It does not work otherwise.
  const { t } = useTranslation();
  const memoSignInSchema = useMemo(() => SignInSchema(), []);
  const [searchParams] = useSearchParams();
  // If the account was confirmed by email, don't display Google OAuth component.
  const accountConfirmed = searchParams.get('account_confirmed') === 'true';
  // Disable form submission when processing Google OAuth
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const { error: authGoogleError } = useAuth();
  const { handleSubmit, errors, values, touched, handleBlur, handleChange, isSubmitting } =
    useFormik({
      initialValues: {
        username: '',
        password: '',
        remember: '',
        submit: false
      },
      validationSchema: memoSignInSchema,
      onSubmit: handleOnSubmit
    });

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {/* Here are the google errors for SignUp form */}
      {authGoogleError && (
        <Alert mt={2} mb={1} severity="warning">
          {authGoogleError}
        </Alert>
      )}
      {errors.msg && (
        <Alert mt={2} mb={1} severity="warning">
          {errors.msg}
        </Alert>
      )}
      <Grid container>
        <Grid item xs={12} my={4}>
          <TextField
            type="text"
            name="username"
            label={t('common:username')}
            value={values.username}
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={4}>
          <TextField
            type="password"
            name="password"
            label={t('common:password')}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} my={4}>
          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.remember}
                color="primary"
                size="small"
              />
            }
            label={t('common:remember_me')}
          />
        </Grid>
        <Grid item xs={12} my={2}>
          <Button
            role="button"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || isGoogleSubmitting}
          >
            {t('common:sign_in')}
          </Button>
        </Grid>
        {!accountConfirmed && (
          <Grid item xs={12} my={2}>
            <GoogleButton
              variant="SIGN_IN"
              setSignInStatus={setSignInStatus}
              setIsGoogleSubmitting={setIsGoogleSubmitting}
            />
          </Grid>
        )}
        <Grid item xs={12} my={2}>
          <Button component={Link} to={URLS.SIGN_UP} fullWidth color="primary">
            {t('common:sign_up')}
          </Button>
        </Grid>
        <Grid item xs={12} my={2}>
          <Button component={Link} to={URLS.RESET_PASSWORD} fullWidth color="primary">
            {t('components:SignIn.forgot_password')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}

SignIn.propTypes = {
  handleOnSubmit: PropTypes.func.isRequired,
  setSignInStatus: PropTypes.func.isRequired
};

export default SignIn;
