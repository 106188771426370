// Constants
import { ACTIONS } from 'constants/Auth';

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload?.isAuthenticated || false,
        isInitialized: true,
        user: action.payload?.user || {},
        error: '',
        accessToken: action.payload?.accessToken || '',
        refreshToken: action.payload?.refreshToken || ''
      };
    case ACTIONS.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken
      };
    case ACTIONS.SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        error: '',
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };
    case ACTIONS.SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        error: '',
        accessToken: '',
        refreshToken: ''
      };

    case ACTIONS.SIGN_UP:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        error: '',
        accessToken: '',
        refreshToken: ''
      };

    case ACTIONS.ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        error: '',
        accessToken: '',
        refreshToken: ''
      };
    }

    case ACTIONS.ERROR_GOOGLE: {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        error: action.payload.error,
        accessToken: '',
        refreshToken: ''
      };
    }
    case ACTIONS.UPDATE_PROJECTS_LIST: {
      return {
        ...state,
        user: action.payload.user
      };
    }

    default:
      return state;
  }
};
