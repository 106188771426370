import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';

// Components
import CustomGridToolbarColumnsButton from './CustomGridToolbarColumnsButton';

// Hooks
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

/**
 * @name KeywordTrackingTableToolbar
 * @description Here we display additional buttons for the KeywordTrackingTable.
 */
function KeywordTrackingTableToolbar() {
  const { t } = useTranslation('components', {
    keyPrefix: 'KeywordTrackingTable.KeywordTrackingTableToolbar'
  });

  return (
    <React.Fragment>
      <GridToolbarContainer>
        <CustomGridToolbarColumnsButton />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    </React.Fragment>
  );
}

export default KeywordTrackingTableToolbar;
