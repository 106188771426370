// Styles
import { Grid, Icon } from './styled';

interface Props {
  name: string;
  icon: string;
}

/**
 * Renders the competitor column header cell with the icon
 *
 * @param name  Competitor name
 * @param icon  Competitor icon
 */
function CompetitorColumnCell({ name, icon }: Props) {
  return (
    <Grid>
      <Icon alt={name} src={icon} />
    </Grid>
  );
}

export default CompetitorColumnCell;
