import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Constants
import { THEMES } from 'constants/Theme';

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: (theme) => {}
};

const ThemeContext = React.createContext(initialState);

/**
 * @name ThemeProvider
 * @param  {object} props
 * @param  {React.ReactNode} props.children
 */
function ThemeProvider({ children }) {
  const [theme, _setTheme] = React.useState(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');

    if (storedTheme) {
      _setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const setTheme = (theme) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    _setTheme(theme);
  };

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}

ThemeProvider.propTypes = {
  children: PropTypes.node
};

export { ThemeProvider, ThemeContext };
