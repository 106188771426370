import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// Constants
const BUTTON_TYPE = {
  LOADING: 'loading',
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  DELETE: 'delete'
};

/**
 * @name ConfirmCancelButton
 * @description This component is used to show a loading icon, confirm icon, cancel icon or delete icon
 * @param {string} type
 * @param {function} action
 * */
function ConfirmCancelButton({ type, action }) {
  const { t } = useTranslation('components', { keyPrefix: 'ConfirmCancelButton' });
  if (type === BUTTON_TYPE.LOADING) {
    return (
      <IconButton>
        <CircularProgress size={20} />
      </IconButton>
    );
  }
  if (type === BUTTON_TYPE.CONFIRM) {
    return (
      <Tooltip title={t('confirm_delete')}>
        <IconButton onClick={action}>
          <CheckIcon color="success" size={5} />
        </IconButton>
      </Tooltip>
    );
  }
  if (type === BUTTON_TYPE.CANCEL) {
    return (
      <Tooltip title={t('cancel_delete')}>
        <IconButton onClick={action}>
          <ClearIcon color="error" size={5} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <IconButton onClick={action}>
      <DeleteIcon color="error" />
    </IconButton>
  );
}

ConfirmCancelButton.propTypes = {
  type: PropTypes.string,
  action: PropTypes.func
};

export default ConfirmCancelButton;
