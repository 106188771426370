import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ReplayIcon from '@mui/icons-material/Replay';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
// Hooks
import useKeywordTrackingTable from 'hooks/useKeywordTrackingTable';

/**
 * @name KeywordTrackingTableError
 * @description Overlay for when all tracked table queries are error
 * */
const KeywordTrackingTableError = () => {
  const { refetchKeywords, isTableFetching } = useKeywordTrackingTable();
  const { t } = useTranslation('components', { keyPrefix: 'KeywordTrackingTable' });
  return (
    <>
      <Alert severity="error" sx={{ mt: 2 }}>
        {t('KeywordTrackingTableError.error_retrieving_keywords')}
      </Alert>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Button
          sx={{ marginTop: 10 }}
          disabled={isTableFetching}
          endIcon={isTableFetching ? <CircularProgress size={20} /> : <ReplayIcon />}
          variant="outlined"
          color="error"
          onClick={refetchKeywords}
        >
          {t('KeywordTrackingTableError.refetch')}
        </Button>
      </Box>
    </>
  );
};

export default KeywordTrackingTableError;
