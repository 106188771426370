import styled from '@emotion/styled';
import MuiTextField from '@mui/material/TextField';
import MuiBox from '@mui/material/Box';

export const Box = styled(MuiBox)`
  display: inline-block;
  font-weight: 500;
`;

export const TextFieldInput = styled(MuiTextField)`
  & .MuiInputBase-input {
    padding: 8.5px 8px;
  }
`;
