import styled from '@emotion/styled';
import MuiFormControl from '@mui/material/FormControl';
import { css } from '@emotion/react';

export const FormControl = styled(({ compact, ...props }) => <MuiFormControl {...props} />)`
  width: ${(props) => `${props.width}px`};

  & .MuiSelect-select {
    ${({ compact }) =>
      compact &&
      css`
        padding: 4.5px 14px;
      `}
  }
`;
