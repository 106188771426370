import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

// Components
import Loader from 'components/Loader';
import BrandIcon from 'components/BrandIcon';

// Fetchers
import { API, CACHE_KEYS, FETCH_STATE } from 'api/index';

// Constants
import { URLS } from 'constants/URL';

// Styles
import { Wrapper } from './styled';

enum TranslationKey {
  AccountConfirmation = 'AccountConfirmation',
  ResetPasswordConfirm = 'ResetPasswordConfirm'
}

interface Props {
  translationKey: TranslationKey;
}

/**
 * @name ConfirmationError
 * @description Displays error message
 */
function ConfirmationError({ translationKey }: Props) {
  const { t } = useTranslation('pages', {
    keyPrefix: translationKey
  });
  return (
    <Wrapper>
      <Helmet title={t('error_confirming')} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('error_confirming')}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        <Trans
          i18nKey={`pages:${translationKey}.confirmation_error_information`}
          components={{ a: <a href="mailto:support@toaster.com" /> }}
        />
      </Typography>
    </Wrapper>
  );
}

/**
 * @name ConfirmationMessage
 * @description Displays information about successful account confirmation/reset password
 */
function ConfirmationMessage({ translationKey }: Props) {
  const { t } = useTranslation('pages', {
    keyPrefix: translationKey
  });

  return (
    <Wrapper>
      <Helmet title={t('confirmed')} />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        {t('all_set')}
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        {t('shortly_redirected')}
      </Typography>
    </Wrapper>
  );
}

/**
 * @name Confirmation
 * @description This page is used to check verify a new account, or reset a password. If
 * successful, the user is redirected to the login page after 5 seconds
 * @param isResetPassword
 */
function Confirmation({ isResetPassword }: { isResetPassword: boolean }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const verificationCode = searchParams.get('verification_code') ?? '';
  const verificationType = searchParams.get('verification_type') ?? '';
  const translationKey = isResetPassword
    ? TranslationKey.ResetPasswordConfirm
    : TranslationKey.AccountConfirmation;

  const { status: queryStatus } = useQuery(
    [CACHE_KEYS.USER_VERIFY_EMAIL],
    () => API.userVerifyEmail({ verificationCode, verificationType }),
    {
      refetchOnWindowFocus: false
    }
  );

  // Access query information
  const isAccountConfirmed = queryStatus === FETCH_STATE.SUCCESS;
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    // If account is confirmed, redirect user to the sign in page
    if (isAccountConfirmed) {
      timer = setTimeout(() => {
        navigate({ pathname: URLS.SIGN_IN, search: '?account_confirmed=true' }, { replace: true });
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountConfirmed]);

  if (queryStatus === FETCH_STATE.ERROR) {
    return (
      <React.Fragment>
        <BrandIcon isPhiture size="md" />
        <ConfirmationError translationKey={translationKey} />
      </React.Fragment>
    );
  }

  if (queryStatus === FETCH_STATE.LOADING) {
    return (
      <React.Fragment>
        <BrandIcon isPhiture={true} size="md" />
        <Loader />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <BrandIcon isPhiture size="md" />
      <ConfirmationMessage translationKey={translationKey} />
    </React.Fragment>
  );
}

export default Confirmation;
