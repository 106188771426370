import { StatusCodes, getReasonPhrase } from 'http-status-codes';
import {
  ApiConnectionAborted,
  ApiConnectionRefused,
  ApiConnectionTimedOut,
  ApiGenericError,
  ApiResponseBadRequest,
  ApiResponseForbidden,
  ApiResponseInternalServerError,
  ApiResponseMethodNotAllowed,
  ApiResponseNotFound,
  ApiResponseRequestTimeout,
  ApiResponseUnauthorized
} from './errors';

/**
 * This is a reusable function to create systematic error objects, depending on the response code or status.
 * @param  {Error} error
 * @return {Error}
 */
const apiError = (error) => {
  if (error.response) {
    if (error.response.status === StatusCodes.BAD_REQUEST) {
      const errorData = error.response?.data?.data || error.response?.data;
      Object.keys(errorData).forEach((key) => {
        if (Array.isArray(errorData[key])) {
          return (errorData[key] = errorData[key].join(', '));
        }
        return errorData[key];
      });

      throw new ApiResponseBadRequest(error.message, errorData);
    }

    if (error.response.status === StatusCodes.UNAUTHORIZED) {
      const errorData = error.response?.data?.data || error.response?.data;
      Object.keys(errorData).forEach((key) => {
        if (Array.isArray(errorData[key])) {
          return (errorData[key] = errorData[key].join(', '));
        }
        return errorData[key];
      });

      throw new ApiResponseUnauthorized(error.message, errorData);
    }

    if (error.response.status === StatusCodes.FORBIDDEN) {
      const errorData = error.response?.data;
      throw new ApiResponseForbidden(error.message, errorData);
    }

    if (error.response.status === StatusCodes.NOT_FOUND) {
      const errorData = error.response?.data;
      throw new ApiResponseNotFound(error.message, errorData);
    }

    if (error.response.status === StatusCodes.METHOD_NOT_ALLOWED) {
      throw new ApiResponseMethodNotAllowed(getReasonPhrase(StatusCodes.METHOD_NOT_ALLOWED));
    }

    if (error.response.status === StatusCodes.REQUEST_TIMEOUT) {
      throw new ApiResponseRequestTimeout(error.message);
    }

    if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
      throw new ApiResponseInternalServerError(error.message);
    }

    throw new ApiGenericError(error.message);
  } else if (error.request) {
    if (error.code === 'ECONNABORTED') {
      throw new ApiConnectionAborted(error.message);
    }
    if (error.code === 'ECONNREFUSED') {
      throw new ApiConnectionRefused(error.message);
    }
    if (error.code === 'ETIMEDOUT') {
      throw new ApiConnectionTimedOut(error.message);
    }
    throw new ApiGenericError(error.message);
  } else {
    throw new ApiGenericError(error.message);
  }
};

export default apiError;
