import styled from '@emotion/styled';
import MuiGrid from '@mui/material/Grid';
import MuiListItemText from '@mui/material/ListItemText';
import MuiCheckbox from '@mui/material/Checkbox';

export const Grid = styled(MuiGrid)`
  height: 12px;
`;
export const ListItemText = styled(MuiListItemText)`
  & .MuiTypography-root {
    font-size: 10px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }
`;

export const Checkbox = styled(MuiCheckbox)`
  padding: 0;
  & .MuiSvgIcon-root {
    height: 15px;
    width: 15px;
  }

  & .PrivateSwitchBase-input {
    height: 15px;
    width: 15px;
  }
`;

export const Image = styled.img`
  height: 15px;
  width: 15px;
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
